import CustomHelmet from 'Components/_common/CustomHelmet'
import MobileMainHeader from 'Components/_common/MobileMainHeader'
import { HomeScrollRow } from 'Components/HomeComponents/HomeScrollRow'
import React from 'react'
import { openGraphDescriptions } from 'Scenes/constants'

import ContestHomeHighlight from '../../Components/ContestComponents/ContestHomeHighlight'
import HomeHeroSection from '../../Components/HomeComponents/HomeHeroSection/HomeHeroSection'
import CustomContainer from '../../Theme/CustomContainer'
import { navigateToMapsListPage, navigateToPrefabsListPage } from '../navigators'
// import HomeRoadmap from '../Roadmap/HomeRoadmap'

const HomeScene = () => {
  const mapsPageLink = navigateToMapsListPage(true)
  const prefabPageLink = navigateToPrefabsListPage(true)

  return (
    <>
      <CustomHelmet description={openGraphDescriptions.homepage} />

      <MobileMainHeader />

      <HomeHeroSection />

      <CustomContainer>
        <HomeScrollRow
          title="New Releases"
          link={`${mapsPageLink}?type=new`}
          contentsKey={'new'}
          showMoreBtn
        />
      </CustomContainer>

      <CustomContainer>
        <HomeScrollRow
          title="Trending Maps"
          link={`${mapsPageLink}?type=trending`}
          contentsKey={'trending'}
          showMoreBtn
        />
      </CustomContainer>

      <CustomContainer>
        <HomeScrollRow
          title="Featured"
          link={`${mapsPageLink}?type=featured`}
          contentsKey={'featured'}
          showMoreBtn
        />
      </CustomContainer>

      <CustomContainer>
        <HomeScrollRow
          title="Trending Prefabs"
          link={`${prefabPageLink}?type=trending`}
          contentsKey={'prefab_trending'}
          type="prefab"
          showMoreBtn
        />
      </CustomContainer>

      <CustomContainer sxStyles={{ marginTop: '16px', marginBottom: '40px' }}>
        <ContestHomeHighlight />
      </CustomContainer>

      <CustomContainer>
        <HomeScrollRow
          title="Infection"
          contentsKey={'infection'}
        />
      </CustomContainer>

      <CustomContainer>
        <HomeScrollRow
          title="Halo Remakes"
          contentsKey={'remake'}
        />
      </CustomContainer>

      <CustomContainer sxStyles={{ paddingBottom: '32px' }}>
        <HomeScrollRow
          title="Mini Games"
          contentsKey={'miniGame'}
        />
      </CustomContainer>

      {/* <CustomContainer sxStyles={{ paddingTop: '40px', paddingBottom: '32px' }}>
        <HomeRoadmap />
      </CustomContainer> */}
    </>
  )
}

export default HomeScene
