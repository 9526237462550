import CollectionCard from 'Components/_common/CollectionCard/CollectiionCard'
import { useDeleteContentMutation } from 'Queries/Common'
import { usePrefabCategoryName } from 'Queries/Dictionaries'
import { CONTENT_TYPES } from 'Scenes/constants'
import { navigateToPrefabPage } from 'Scenes/navigators'
import { checkAwardBudge } from 'utils/common-utils'

import CollectionCardThin from './CollectionCardThin'
import { getPrefabImageBySize } from './utils'

export const PrefabCard = ({ prefab, onDelete, imageSize = 'medium', isThin = false, ...otherProps }) => {
  const { mutateAsync: deleteMap, isLoading } = useDeleteContentMutation(prefab.id, CONTENT_TYPES.PREFABS)

  const categoryName = usePrefabCategoryName(prefab.category_id)
  const image = getPrefabImageBySize(prefab, imageSize)
  const link = navigateToPrefabPage(prefab?.id, true)
  const hasAward = checkAwardBudge(prefab)

  const handleDelete = (event) => {
    event.preventDefault()
    deleteMap().then(() => {
      onDelete()
    })
  }

  return !isThin ? (
    <CollectionCard
      link={link}
      onDelete={handleDelete}
      isLoading={isLoading}
      collectionItem={prefab}
      categoryName={categoryName}
      image={image}
      {...otherProps}
    />
  ) : (
    <CollectionCardThin
      categoryName={categoryName}
      collection={prefab}
      hasAward={hasAward}
      image={image}
      link={link}
      {...otherProps}
    />
  )
}
