import { Box, Button, CircularProgress } from '@mui/material'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

export const MobileModalFooter = ({
  handleClose,
  disableCreation,
  handleCreate,
  isLoading,
  isWrongWaypointId,
  handleCreateWithUrl,
  waypointLink,
  sx,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    width="100%"
    gap={2}
    px={3}
    pb={3}
    sx={sx}
  >
    <Box
      display="flex"
      justifyContent="space-between"
    >
      <Button
        variant="contained"
        onClick={handleClose}
        sx={{
          background: 'rgba(209, 233, 255, 0.05)',
          '&:hover': {
            background: 'rgba(209, 233, 255, 0.1)',
          },
        }}
      >
        Cancel
      </Button>

      {isWrongWaypointId ? (
        <Button
          variant="contained"
          sx={(theme) => ({
            background: 'rgba(0, 174, 112, 0.1)',
            color: theme.palette.primary.main,
            '&:hover': {
              background: 'rgba(0, 174, 112, 0.3)',
            },
          })}
          onClick={handleCreateWithUrl}
          disabled={disableCreation}
        >
          Try again
          <FAIcon iconClassName={FA_ICONS.ROTATE_RIGHT} />
        </Button>
      ) : (
        <Button
          variant="contained"
          color={'primary'}
          sx={{ gap: '8px', height: '36px' }}
          onClick={() => handleCreateWithUrl(waypointLink)}
          disabled={disableCreation}
        >
          Create
          {isLoading ? (
            <CircularProgress
              color="inherit"
              size="16px"
            />
          ) : (
            <FAIcon iconClassName={FA_ICONS.CHECK} />
          )}
        </Button>
      )}
    </Box>
    {isWrongWaypointId && (
      <Button
        variant="contained"
        color={'error'}
        onClick={handleCreate}
        disabled={disableCreation}
        sx={{ height: '36px' }}
      >
        Continue without Transferring Data
        <FAIcon iconClassName={FA_ICONS.ARROW_RIGHT} />
      </Button>
    )}
  </Box>
)
