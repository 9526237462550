import { Box, useMediaQuery } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as LogoWithText } from 'Assets/img/LogoForgehub.svg'
import { ReactComponent as Logo } from 'Assets/img/SidebarLogo.svg'
import MainSidebarListItem from 'Components/Layout/MainSidebar/MainSidebarListItem'
import React, { useRef, useState } from 'react'
import { isTablet } from 'react-device-detect'
import { Else, If, Then } from 'react-if'
import { Link, useLocation } from 'react-router-dom'
import { BREAKPOINT_MIN_SM } from 'Theme/stylesUtil'

import { navigateToRootPage } from '../../../Scenes/navigators'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import { useAuth } from '../../Auth'
import CommunityVoteButton from './CommunityVoteButton'
import { sidebarListConfig } from './helpers'
import { MainSidebarFooter } from './MainSidebarFooter'
import MobileMainSidebar from './MobileMainSidebar/MobileMainSidebar'
import PopItem from './PopItem'
import popperWrapperHOC from './PopperWrapper'
import { SubmitButton } from './SubmitMapButton'

const WrappedListItem = popperWrapperHOC(MainSidebarListItem)
const WrappedMainSidebarFooter = popperWrapperHOC(MainSidebarFooter)
const WrappedSubmitMapButton = popperWrapperHOC(SubmitButton)
const WrapperCommunityVoteButton = popperWrapperHOC(CommunityVoteButton)

const MainSidebar = () => {
  const { isAuthorized } = useAuth()
  const location = useLocation()
  const drawerRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [isShowOpenArrow, setIsShowOpenArrow] = useState({ isOpenPop: false, anchorEl: null })
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)

  const handleDrawerToggle = (event) => {
    const width = drawerRef.current?.clientWidth > 74
    setIsShowOpenArrow(({ isOpenPop }) => {
      if (event.type === 'mouseover' && !isOpenPop) {
        return { isOpenPop: !width && !isOpen && !isOpenPop, anchorEl: drawerRef.current }
      }
      if (event.type === 'mouseleave' && isOpenPop) {
        return { ...isShowOpenArrow, isOpenPop: !isOpenPop }
      }
      return { isOpenPop: isOpenPop, anchorEl: drawerRef.current }
    })
  }

  const handleClickToggleDrawer = () => {
    setIsOpen(!isOpen)
    setIsShowOpenArrow({ ...isShowOpenArrow, isOpenPop: false })
  }

  return (
    <If condition={isBiggerThanSMScreen}>
      <Then>
        <Drawer
          variant="permanent"
          className={isOpen ? 'drawerOpen' : 'drawerClose'}
          sx={{
            flexShrink: 0,
            whiteSpace: 'no-wrap',
            width: isOpen ? '240px' : '74px',
            '& .MuiPaper-root': {
              width: isOpen ? '240px' : '74px',
              justifyContent: 'space-between',
              border: '0',
              boxShadow:
                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
              backgroundColor: '#25272D',
              overflowX: 'hidden',
              top: 'unset',
            },
          }}
          classes={{ paper: isOpen ? 'drawerOpenRoot' : 'drawerClose' }}
          onMouseOver={handleDrawerToggle}
          onMouseLeave={handleDrawerToggle}
          ref={drawerRef}
        >
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '24px',
                paddingBottom: '8px',
              }}
            >
              <Link to={navigateToRootPage(true)}>{isOpen ? <LogoWithText /> : <Logo />}</Link>
              <IconButton
                className={'drawerPinButton'}
                size="large"
                onClick={handleClickToggleDrawer}
              >
                <>
                  <FAIcon
                    iconClassName={FA_ICONS.CHEVRON_LEFT}
                    iconFontSize={16}
                  />
                </>
              </IconButton>
            </div>
            <ul style={{ padding: 0 }}>
              {sidebarListConfig.map(({ title, link, icon }) => (
                <Box key={title}>
                  <If condition={isTablet}>
                    <Then>
                      <MainSidebarListItem
                        key={title}
                        title={title}
                        to={link}
                        icon={<FAIcon iconClassName={icon} />}
                        selected={location.pathname === link}
                        isOpen={!isOpen}
                        isTablet={isTablet}
                      />
                    </Then>
                    <Else>
                      <WrappedListItem
                        key={title}
                        title={title}
                        to={link}
                        icon={<FAIcon iconClassName={icon} />}
                        selected={location.pathname === link}
                        isOpen={!isOpen}
                      />
                    </Else>
                  </If>
                </Box>
              ))}
            </ul>
            <If condition={isTablet}>
              <Then>
                <SubmitButton
                  isOpen={!isOpen}
                  isTablet={isTablet}
                />
              </Then>
              <Else>
                <WrappedSubmitMapButton isOpen={!isOpen} />
              </Else>
            </If>
            <If condition={isTablet}>
              <Then>
                <CommunityVoteButton
                  isOpen={!isOpen}
                  isTablet={isTablet}
                  isAuthorized={isAuthorized}
                />
              </Then>
              <Else>
                <WrapperCommunityVoteButton
                  isOpen={!isOpen}
                  isAuthorized={isAuthorized}
                />
              </Else>
            </If>
          </div>
          <WrappedMainSidebarFooter isOpen={!isOpen} />
        </Drawer>
        <PopItem
          open={isShowOpenArrow.isOpenPop}
          anchorEl={isShowOpenArrow.anchorEl}
          isMain={true}
          handleClickToggle={handleClickToggleDrawer}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Then>
      <Else>
        <MobileMainSidebar />
      </Else>
    </If>
  )
}

export default MainSidebar
