import { Route, Switch } from 'react-router-dom'

const RoutesFromConfig = ({ config }) => (
  <Switch>
    {config.map((r, index) => (
      <Route
        key={index}
        path={r.path}
        exact={r.exact}
        component={r.component}
      />
    ))}
  </Switch>
)

export default RoutesFromConfig
