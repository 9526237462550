import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import Button from '../../../Theme/Button'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'

const ButtonSignIn = ({ text = 'Login', type, ...rest }) => (
  <Button
    type={type}
    variant="contained"
    color="secondary"
    sx={{
      gap: '16px',
      padding: '12px 16px !important',
      whiteSpace: 'nowrap',
    }}
    {...rest}
  >
    <Typography variant="inherit">{text}</Typography>
    <FAIcon
      iconFontSize={16}
      iconClassName={FA_ICONS.LOGIN}
    />
  </Button>
)

ButtonSignIn.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
}

export default ButtonSignIn
