import { ElementByIdLayout } from 'constants'
import { useEffect, useState } from 'react'
import { useDebounce } from 'rooks'

export const useOpaque = (opaqueHeight) => {
  const [isOpaque, setOpaque] = useState(false)
  const debouncedSetOpaque = useDebounce(setOpaque, 300)

  useEffect(() => {
    const layout = ElementByIdLayout(document)
    if (layout) {
      layout.addEventListener('scroll', () => {
        debouncedSetOpaque(layout.scrollTop > opaqueHeight ?? 110)
      })
    }
  }, [debouncedSetOpaque, opaqueHeight])
  return isOpaque
}
