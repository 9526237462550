import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import AwardBadge from 'Components/_common/AwardBadge'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import React from 'react'
import { Else, If, Then, When } from 'react-if'
import { Link } from 'react-router-dom'
import { userImageSelector } from 'utils/user-images-utils'

import { transformTextWithThreeDots } from '../../../utils/text-transformation-utils'
import GameTypeIcon from '../GameTypeIcon'

const CollectionCardThin = ({
  collection,
  playersCountName,
  categoryName,
  showGameCategory,
  hasAward,
  image,
  link,
  hideAwards,
  onClick,
}) => (
  <Link
    to={link}
    data-collection-card-id={collection.id}
    className="MapCardThin"
    onClick={onClick}
  >
    <div className="MapCardThin__Bg">
      <If condition={image}>
        <Then>
          <img
            loading="lazy"
            src={image}
            alt={collection.title}
            className="MapCardThin__Bg-img"
          />
        </Then>
        <Else>
          <div
            style={{
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(180deg, rgba(32, 33, 38, 0) 0%, rgba(32, 33, 38, 0.5) 70%, rgba(32, 33, 38, 0.9) 100%)',
            }}
          />
        </Else>
      </If>
    </div>

    <When condition={hasAward && !hideAwards}>
      <AwardBadge
        settings={collection}
        containerStyles={{ position: 'absolute', right: '10px', top: '0px' }}
      />
    </When>

    <div className="MapCardThin__Content">
      <Avatar
        src={userImageSelector(collection.user?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'medium')}
        style={{ width: 32, height: 32, marginRight: 0 }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography
          variant="h6"
          color="text.primary"
        >
          {transformTextWithThreeDots(collection.title)}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="caption"
            color="text.tertiary"
          >
            {transformTextWithThreeDots(collection.user?.login)}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="caption"
              color="text.tertiary"
              whiteSpace="nowrap"
            >
              {showGameCategory ? categoryName : playersCountName}
            </Typography>
            <GameTypeIcon
              size="small"
              variant="caption"
              color="text.tertiary"
              gameType={categoryName}
            />
          </div>
        </div>
      </div>
    </div>
  </Link>
)

export default CollectionCardThin
