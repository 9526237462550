import { Box, Typography } from '@mui/material'
import { ReactComponent as LogoWithText } from 'Assets/img/LogoForgehub.svg'
import MobileMainHeader from 'Components/_common/MobileMainHeader'
import LoginForm from 'Components/Auth/LoginForm'
import { useLoginMutation } from 'Queries/Auth'
import { navigateToEmptyHash } from 'Scenes/navigators'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

const SceneMobileLogin = () => {
  const { mutate } = useLoginMutation()

  return (
    <>
      <MobileMainHeader
        children={
          <Box sx={{ height: '56px', display: 'flex', alignItems: 'center', padding: '0 24px 0 16px', gap: '24px' }}>
            <FAIcon
              iconClassName={FA_ICONS.ARROW_LEFT}
              color="text.tertiary"
              onClick={() => navigateToEmptyHash()}
            />
            <Typography variant="h5">Sign In</Typography>
          </Box>
        }
      />
      <Box
        sx={{
          width: '100%',
          marginTop: '100px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '55px',
        }}
      >
        <LogoWithText />

        <LoginForm mutate={mutate} />
      </Box>
    </>
  )
}

export default SceneMobileLogin
