import { generatePath } from 'react-router'

import historyInstance from '../history-instance'
import { CONTENT_TYPES, makeMediaWikiPath } from '../Queries/constants'
import {
  ROUTE_ARCHIVE_TOURNAMENTS_PATH,
  ROUTE_BLOG_CREATE_PATH,
  ROUTE_BLOG_ID_EDIT_PATH,
  ROUTE_BLOG_ID_PATH,
  ROUTE_BLOG_PATH,
  ROUTE_CHAT_PATH,
  ROUTE_COLLECTIONS_ID_PATH,
  ROUTE_COMMUNITY_FAVORITES_ID_PATH,
  ROUTE_COMMUNITY_FAVORITES_PATH,
  ROUTE_COMMUNITY_FAVORITES_VOTE_PATH,
  ROUTE_CONTEST_ID_INVITATION_PATH,
  ROUTE_CONTEST_ID_PATH,
  ROUTE_CONTEST_ID_PATH_PAYMENTS,
  ROUTE_CONTESTS_ARCHIVE_PATH,
  ROUTE_CONTESTS_PATH,
  ROUTE_CREATE_CONTEST_PATH,
  ROUTE_CREATE_MAP_PATH,
  ROUTE_CREATE_NEW_ACCOUNT_PATH,
  ROUTE_CREATE_PREFAB_PATH,
  ROUTE_CREATE_TOURNAMENTS_PATH,
  ROUTE_CREATE_WIKI_WITH_TITLE_PATH,
  ROUTE_DOWNLOAD_ENTITY,
  ROUTE_EDIT_CONTEST_PATH,
  ROUTE_EDIT_MAP_PATH,
  ROUTE_EDIT_PREFAB_PATH,
  ROUTE_EDIT_TOURNAMENTS_PATH,
  ROUTE_EDIT_WIKI_PATH,
  ROUTE_GUIDES_PATH,
  ROUTE_LOGIN_HASH,
  ROUTE_LOGIN_PATH,
  ROUTE_MAP_ID_PATH,
  ROUTE_MAPS_PATH,
  ROUTE_PREFAB_ID_PATH,
  ROUTE_PREFABS_PATH,
  ROUTE_PRIVACY_PATH,
  ROUTE_PROFILE_OWNER_ID_ACTIVITY_PATH,
  ROUTE_PROFILE_OWNER_ID_BOOKMARKS_PATH,
  ROUTE_PROFILE_OWNER_ID_COLLECTIONS_PATH,
  ROUTE_PROFILE_OWNER_ID_MAPS_PATH,
  ROUTE_PROFILE_OWNER_ID_PATH,
  ROUTE_PROFILE_OWNER_ID_PREFABS_PATH,
  ROUTE_PROFILE_OWNER_SETTINGS_ACCOUNTS_PATH,
  ROUTE_PROFILE_OWNER_SETTINGS_PATH,
  ROUTE_QUALIFY_MAP_HASH,
  ROUTE_RESET_PASSWORD_HASH,
  ROUTE_ROOT_PATH,
  ROUTE_SEARCH_PATH,
  ROUTE_SET_NEW_PASSWORD_HASH,
  ROUTE_SUBMIT_MAP_HASH,
  ROUTE_TERMS_AND_CONDITIONS_PATH,
  ROUTE_TOURNAMENT_ID_PATH,
  ROUTE_TOURNAMENT_TEAMS_PATH,
  ROUTE_TOURNAMENTS_PATH,
  ROUTE_USER_BLOCK_HASH,
  ROUTE_USER_FOLLOWERS_HASH,
  ROUTE_USER_FOLLOWING_HASH,
  ROUTE_USER_REPORT_HASH,
  ROUTE_WIKI_ID_PATH,
  ROUTE_WIKI_NOT_FOUND_PATH,
  ROUTER_TOURNAMENT_EDIT_TEAM_HASH,
  ROUTER_TOURNAMENT_SIGNUP_HASH,
} from './constants'

const { TYPE_ARTICLE, TYPE_CONTEST, TYPE_COMMUNITY_FAVORITE } = CONTENT_TYPES

export const navigateToEmptyHash = () => {
  window.location.hash = ''
}

export const navigateToLoginModal = () => {
  window.location.hash = ROUTE_LOGIN_HASH
}

export const navigateToResetPasswordModal = () => {
  window.location.hash = ROUTE_RESET_PASSWORD_HASH
}

export const navigateToSetNewPasswordModal = () => {
  window.location.hash = ROUTE_SET_NEW_PASSWORD_HASH
}

export const navigateToUserFollowersModal = () => {
  window.location.hash = ROUTE_USER_FOLLOWERS_HASH
}
export const navigateToUserFollowingModal = () => {
  window.location.hash = ROUTE_USER_FOLLOWING_HASH
}
export const navigateToUserBlockModal = () => {
  window.location.hash = ROUTE_USER_BLOCK_HASH
}
export const navigateToUserReportModal = () => {
  window.location.hash = ROUTE_USER_REPORT_HASH
}

export const navigateToSignUpTournamentModal = () => {
  window.location.hash = ROUTER_TOURNAMENT_SIGNUP_HASH
}

export const navigateToEditTeamModal = () => {
  window.location.hash = ROUTER_TOURNAMENT_EDIT_TEAM_HASH
}

export const navigateToSubmitMapModal = () => {
  window.location.hash = ROUTE_SUBMIT_MAP_HASH
}

export const navigateToQualifyMapModal = (mapId) => {
  window.location.hash = generatePath(ROUTE_QUALIFY_MAP_HASH, { mapId })
}

export const navigateToAny = (pathname) => historyInstance.push(pathname)

export const navigateToRootPage = (justPath = false) =>
  justPath ? ROUTE_ROOT_PATH : historyInstance.push(ROUTE_ROOT_PATH)

export const navigateToArchivePage = (type) => {
  if (type === TYPE_ARTICLE) return navigateToBlogsListPage()
  if (type === TYPE_CONTEST) return navigateToContestsListPage()
  if (type === TYPE_COMMUNITY_FAVORITE) return navigateToCommunityFavoritesListPage()
  return navigateToRootPage()
}

export const navigateToHomeReadMorePage = (type, id) => {
  if (type === TYPE_ARTICLE) return navigateToBlogPage(id)
  if (type === TYPE_CONTEST) return navigateToContestPage(id)
  if (type === TYPE_COMMUNITY_FAVORITE) return navigateToCommunityFavoritePage(id)
  return navigateToRootPage()
}

export const navigateToCreateNewAccountPage = () => {
  historyInstance.push(ROUTE_CREATE_NEW_ACCOUNT_PATH)
}

export const navigateToBlogsListPage = (justPath = false) =>
  justPath ? ROUTE_BLOG_PATH : historyInstance.push(ROUTE_BLOG_PATH)

export const navigateToBlogPage = (id, justPath = false) =>
  justPath ? generatePath(ROUTE_BLOG_ID_PATH, { id }) : historyInstance.push(generatePath(ROUTE_BLOG_ID_PATH, { id }))

export const navigateToEditBlogPage = (id) => {
  historyInstance.push(generatePath(ROUTE_BLOG_ID_EDIT_PATH, { id }))
}

export const navigateToCreateBlogPage = () => {
  historyInstance.push(ROUTE_BLOG_CREATE_PATH)
}

export const navigateToCommunityFavoritesListPage = (justPath = false) =>
  justPath ? ROUTE_COMMUNITY_FAVORITES_PATH : historyInstance.push(ROUTE_COMMUNITY_FAVORITES_PATH)

export const navigateToCommunityFavoriteVotePage = (justPath = false) =>
  justPath ? ROUTE_COMMUNITY_FAVORITES_VOTE_PATH : historyInstance.push(ROUTE_COMMUNITY_FAVORITES_VOTE_PATH)

export const navigateToCommunityFavoritePage = (id, justPath = false) =>
  justPath
    ? generatePath(ROUTE_COMMUNITY_FAVORITES_ID_PATH, { id })
    : historyInstance.push(generatePath(ROUTE_COMMUNITY_FAVORITES_ID_PATH, { id }))

export const navigateToContestsListPage = (justPath = false) =>
  justPath ? ROUTE_CONTESTS_PATH : historyInstance.push(ROUTE_CONTESTS_PATH)

export const navigateToCreateContestPage = () => historyInstance.push(ROUTE_CREATE_CONTEST_PATH)

export const navigateToContestArchivePage = () => historyInstance.push(ROUTE_CONTESTS_ARCHIVE_PATH)

export const navigateToContestPage = (id, justPath = false) =>
  justPath
    ? generatePath(ROUTE_CONTEST_ID_PATH, { id })
    : historyInstance.push(generatePath(ROUTE_CONTEST_ID_PATH, { id }))

export const navigateToTournamentsListPage = () => historyInstance.push(ROUTE_TOURNAMENTS_PATH)

export const navigateToTournamentArchivePage = () => historyInstance.push(ROUTE_ARCHIVE_TOURNAMENTS_PATH)

export const navigateToCreateTournamentPage = () => historyInstance.push(ROUTE_CREATE_TOURNAMENTS_PATH)

export const navigateToTournamentPage = (id, justPath = false) =>
  justPath
    ? generatePath(ROUTE_TOURNAMENT_ID_PATH, { id })
    : historyInstance.push(generatePath(ROUTE_TOURNAMENT_ID_PATH, { id }))

export const navigateToMapsListPage = (justPath = false) =>
  justPath ? ROUTE_MAPS_PATH : historyInstance.push(ROUTE_MAPS_PATH)

export const setMapListLocationState = (mapId) => () => {
  historyInstance.replace(ROUTE_MAPS_PATH, { mapId })
}

export const setPrefabListLocationState = (prefabId) => () => {
  historyInstance.replace(ROUTE_PREFABS_PATH, { prefabId })
}

export const navigateToMapPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_MAP_ID_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToForgeMapPage = (mapId) => {
  const params = mapId ? `?mapId=${mapId}` : ''
  historyInstance.push(`${ROUTE_CREATE_MAP_PATH}${params}`)
}

export const navigateToForgePrefabPage = (prefabId) => {
  const params = prefabId ? `?prefabId=${prefabId}` : ''
  historyInstance.push(`${ROUTE_CREATE_PREFAB_PATH}${params}`)
}

export const navigateToEditMapPage = (mapId) => {
  const params = mapId ? `?mapId=${mapId}` : ''
  historyInstance.push(`${ROUTE_EDIT_MAP_PATH}${params}`)
}

export const navigateToEditContestPage = (contestId) => {
  const params = contestId ? `?contestId=${contestId}` : ''
  historyInstance.push(`${ROUTE_EDIT_CONTEST_PATH}${params}`)
}

export const navigateToEditTournamentPage = (tournamentId) => {
  const params = tournamentId ? `?tournamentId=${tournamentId}` : ''
  historyInstance.push(`${ROUTE_EDIT_TOURNAMENTS_PATH}${params}`)
}

export const navigateToEditTournamentTeamsPage = (tournamentId) => {
  historyInstance.push(generatePath(ROUTE_TOURNAMENT_TEAMS_PATH, { id: tournamentId }))
}

export const navigateToContestPaymentsPage = (id) => {
  historyInstance.push(generatePath(ROUTE_CONTEST_ID_PATH_PAYMENTS, { id }))
}

export const navigateToEditPrefabPage = (prefabId) => {
  const params = prefabId ? `?prefabId=${prefabId}` : ''
  historyInstance.push(`${ROUTE_EDIT_PREFAB_PATH}${params}`)
}

export const navigateToForgeContentPage = (id, type) => {
  const params = id ? `?${type.splice(-1)}Id=${id}` : ''
  historyInstance.push(`${ROUTE_ROOT_PATH}${type}/create${params}`)
}

export const navigateToPrefabsListPage = (justPath = false) =>
  justPath ? ROUTE_PREFABS_PATH : historyInstance.push(ROUTE_PREFABS_PATH)

export const navigateToPrefabPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PREFAB_ID_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToDownloadPage = (entity, id) => {
  const link = document.createElement('a')
  link.setAttribute('href', generatePath(ROUTE_DOWNLOAD_ENTITY, { entity, id }))
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
  return link
}

export const navigateToProfilePage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PROFILE_OWNER_ID_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToProfileSettingsPage = (justPath = false) =>
  justPath ? ROUTE_PROFILE_OWNER_SETTINGS_PATH : historyInstance.push(ROUTE_PROFILE_OWNER_SETTINGS_PATH)

export const navigateToProfileSettingsAccountsPage = () => {
  historyInstance.push(ROUTE_PROFILE_OWNER_SETTINGS_ACCOUNTS_PATH)
}

export const navigateToProfileMapsPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PROFILE_OWNER_ID_MAPS_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToProfilePrefabsPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PROFILE_OWNER_ID_PREFABS_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToProfileContentPage = (id, type, justPath = false) => {
  const path = generatePath(`${ROUTE_PROFILE_OWNER_ID_PATH}/${type}`, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToProfileCollectionsPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PROFILE_OWNER_ID_COLLECTIONS_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToProfileBookmarksPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PROFILE_OWNER_ID_BOOKMARKS_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToProfileActivitiesPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_PROFILE_OWNER_ID_ACTIVITY_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToCollectionPage = (id, justPath = false) => {
  const path = generatePath(ROUTE_COLLECTIONS_ID_PATH, { id })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToSearchPage = (state = {}) => historyInstance.push(ROUTE_SEARCH_PATH, state)

export const navigateToChatPage = (justPath = false) =>
  justPath ? ROUTE_CHAT_PATH : historyInstance.push(ROUTE_CHAT_PATH)

export const navigateToGuidesPage = (justPath = false) =>
  justPath ? ROUTE_GUIDES_PATH : historyInstance.push(ROUTE_GUIDES_PATH)

export const navigateToGuidesViewPage = (game, id, justPath = false) => {
  const path = generatePath(ROUTE_WIKI_ID_PATH, { game, id })
  return justPath ? path : historyInstance.push(path)
}
export const navigateToGuidesEditPage = (game, id, justPath = false) => {
  const path = generatePath(ROUTE_EDIT_WIKI_PATH, { game, id })
  return justPath ? path : historyInstance.push(path)
}
export const navigateToMediaWikiArticle = (id, justPath = false) => {
  const path = makeMediaWikiPath(id)
  return justPath ? path : window.open(path, '_blank')
}
export const navigateToMediaWiki = (id, justPath = false) => {
  const path = makeMediaWikiPath()
  return justPath ? path : window.open(path, '_blank')
}
export const navigateToGuides404Page = (game, id) => {
  const path = generatePath(ROUTE_WIKI_NOT_FOUND_PATH, { game, id })
  return historyInstance.push(path)
}
export const navigateToCreatePage = (game, title, justPath = false) => {
  const path = generatePath(ROUTE_CREATE_WIKI_WITH_TITLE_PATH, { game, title })
  return justPath ? path : historyInstance.push(path)
}

export const navigateToTermsAndConditionsPage = (justPath = false) =>
  justPath ? ROUTE_TERMS_AND_CONDITIONS_PATH : historyInstance.push(ROUTE_TERMS_AND_CONDITIONS_PATH)

export const navigateToPrivacyPage = (justPath = false) =>
  justPath ? ROUTE_PRIVACY_PATH : historyInstance.push(ROUTE_PRIVACY_PATH)

export const navigateToBack = () => historyInstance.goBack()

export const navigateToMobileLogin = () => historyInstance.push(ROUTE_LOGIN_PATH)

export const navigateToContestInvitation = (id) =>
  historyInstance.push(generatePath(ROUTE_CONTEST_ID_INVITATION_PATH, { id }))
