import { useAuth } from 'Components/Auth'

import { usePersistedLoggedFromId } from './usePersistedData'

export const useShowLeaveUser = () => {
  const { id: userId } = useAuth()
  const [loggedFromId] = usePersistedLoggedFromId()

  return !!userId && !!loggedFromId && loggedFromId !== userId
}
