import Box from '@mui/material/Box'
import ExternalLink from 'Components/_common/ExternalLink'
import { HREF_FORGE_HUB_DISCORD, HREF_FORGE_HUB_TWITTER, HREF_FORGE_HUB_YOUTUBE } from 'external-links'
import React from 'react'

import FAIcon from '../../../Theme/FAIcon'

const SocialLinks = () => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      '& a': {
        marginRight: '16px',
      },
      '& svg': {
        color: theme.palette.text.tertiary,
      },
    })}
  >
    <ExternalLink to={HREF_FORGE_HUB_YOUTUBE}>
      <FAIcon iconClassName="fa-brands fa-youtube" />
    </ExternalLink>

    <ExternalLink to={HREF_FORGE_HUB_TWITTER}>
      <FAIcon iconClassName="fa-brands fa-twitter" />
    </ExternalLink>

    <ExternalLink to={HREF_FORGE_HUB_DISCORD}>
      <FAIcon
        iconClassName="fa-brands fa-discord"
        style={{ marginBottom: '2px' }}
      />
    </ExternalLink>
  </Box>
)

export default SocialLinks
