import PropTypes from 'prop-types'
import React from 'react'
import { Else, If, Then } from 'react-if'
import { Link } from 'react-router-dom'

import Button from '../../../Theme/Button'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'

const ButtonViewAll = ({ href, isLink = true, ...rest }) => (
  <If condition={isLink}>
    <Then>
      <Button
        to={href}
        component={Link}
        variant="text"
        color="secondary"
        sx={{ gap: '16px' }}
        {...rest}
      >
        View all
        <FAIcon
          iconFontSize={16}
          iconClassName={FA_ICONS.ARROW_RIGHT}
        />
      </Button>
    </Then>
    <Else>
      <Button
        variant="text"
        color="secondary"
        sx={{ gap: '16px' }}
        {...rest}
      >
        View all
        <FAIcon
          iconFontSize={16}
          iconClassName={FA_ICONS.ARROW_RIGHT}
        />
      </Button>
    </Else>
  </If>
)

ButtonViewAll.propTypes = {
  href: PropTypes.string,
  isLink: PropTypes.bool,
}

export default ButtonViewAll
