import { Redirect, Switch } from 'react-router-dom'
import { ROUTE_CONTESTS_PATH, ROUTE_EVENTS_PATH, ROUTE_TOURNAMENTS_PATH } from 'Scenes/constants'

import RoutesFromConfig from '../RoutesFromConfig'
import ContestsScene from './Contests'
import TournamentsScene from './Tournaments'

const config = [
  {
    path: ROUTE_CONTESTS_PATH,
    exact: false,
    lazy: false,
    component: ContestsScene,
  },
  {
    path: ROUTE_TOURNAMENTS_PATH,
    exact: false,
    lazy: false,
    component: TournamentsScene,
  },
]

const EventsScene = () => (
  <Switch>
    <Redirect
      exact
      from={ROUTE_EVENTS_PATH}
      to={ROUTE_CONTESTS_PATH}
    />
    <RoutesFromConfig config={config} />
  </Switch>
)

export default EventsScene
