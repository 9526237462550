import { TextField } from '@mui/material'

export const WaypointLinkInput = ({ waypointLink, handleChange, isIvalidLink }) => (
  <TextField
    value={waypointLink}
    onChange={handleChange}
    variant="outlined"
    placeholder="Enter the Halo Waypoint link"
    sx={(theme) => ({
      height: '40px',
      width: '100%',
      '& .MuiInputBase-root, .MuiOutlinedInput-root, .MuiInputBase-colorPrimary, .MuiInputBase-fullWidth, .MuiInputBase-formControl, .MuiInputBase-sizeSmall, .MuiInputBase-adornedStart, .Mui-focused':
        {
          boxSizing: 'border-box',
          border: isIvalidLink && waypointLink ? `2px solid ${theme.palette.error.main}` : 'inhriet',
          height: '40px',
          '& fieldset': {
            '&.MuiOutlinedInput-notchedOutline': {
              border: isIvalidLink && waypointLink ? 'none !important' : 'inhriet',
            },
          },
        },
    })}
  />
)
