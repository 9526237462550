import { lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import {
  ROUTE_CREATE_PREFAB_PATH,
  ROUTE_DOWNLOAD_ENTITY,
  ROUTE_EDIT_PREFAB_PATH,
  ROUTE_PREFAB_ID_PATH,
  ROUTE_PREFABS_PATH,
  ROUTE_ROOT_PATH,
} from 'Scenes/constants'

import RoutesFromConfig from '../RoutesFromConfig'

const config = [
  {
    path: ROUTE_CREATE_PREFAB_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreatePrefab" */ './SceneCreatePrefab')),
  },
  {
    path: ROUTE_EDIT_PREFAB_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreatePrefab" */ './SceneCreatePrefab')),
  },
  {
    path: ROUTE_PREFABS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "ScenePrefabsList" */ './ScenePrefabs')),
  },
  {
    path: ROUTE_PREFAB_ID_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "ScenePrefabs" */ './SceneSinglePrefab')),
  },
  {
    path: ROUTE_DOWNLOAD_ENTITY,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneDownloadEntity" */ '../SceneDownloadEntity')),
  },
]

const PrefabScene = () => (
  <Switch>
    <RoutesFromConfig config={config} />
    <Redirect to={ROUTE_ROOT_PATH} />
  </Switch>
)

export default PrefabScene
