import { Box } from '@mui/material'
import { Else, If, Then } from 'react-if'
import { CardMedia } from 'Theme/Card'

export const CollectionMedia = ({ image, title }) => (
  <CardMedia>
    <If condition={image}>
      <Then>
        <img
          loading="lazy"
          src={image}
          alt={title}
        />
      </Then>
      <Else>
        <Box
          style={{
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(32, 33, 38, 0) 0%, rgba(32, 33, 38, 0.5) 70%, rgba(32, 33, 38, 0.9) 100%)',
          }}
        />
      </Else>
    </If>
  </CardMedia>
)
