import TextField from '@mui/material/TextField'
import withStyles from '@mui/styles/withStyles'

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .Mui-error': {
      marginLeft: 0,
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.error.main} !important`,
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: theme.spacing(2),
    },
  },
}))(TextField)

export default StyledTextField
