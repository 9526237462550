import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
    width: '183px',
    height: '100%',
    background: 'var(--action-active, rgba(209, 233, 255, 0.05))',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
}))

const MoreCard = ({ link, type }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box
      className={clsx(classes.root)}
      onClick={() => history.push(link)}
    >
      <Box className={classes.body}>
        <FAIcon
          iconClassName={FA_ICONS[type.toUpperCase()]}
          iconFontSize={68}
          color="text.disabled"
        />
        <Box
          display="flex"
          alignItems="center"
          gap={4}
        >
          <Typography
            variant="h6"
            color="text.disabled"
            whiteSpace="nowrap"
          >
            More {type}s
          </Typography>
          <FAIcon
            iconClassName={FA_ICONS.ANGLE_RIGHT}
            color="text.disabled"
            iconFontSize={24}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MoreCard
