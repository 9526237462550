import { Button } from '@mui/material'
import { useLeaveUser } from 'Queries/Auth'

export const LeaveUserButton = () => {
  const { mutate: handleLeaveUser } = useLeaveUser()
  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        padding: '8px 16px',
      }}
      onClick={handleLeaveUser}
    >
      Leave User
    </Button>
  )
}
