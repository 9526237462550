import React, { useState } from 'react'

import PopItem from './PopItem'

const popperWrapperHOC = (WrappedComponent) => {
  const PopperWrapper = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [popupText, setPopupText] = useState('')

    const handleOpenInfoPop = (event, title) => {
      if (!open) {
        setAnchorEl(event.currentTarget)
        setPopupText(title)
        setOpen(true)
      }
    }

    const handleCloseInfoPop = () => {
      setOpen(false)
    }
    return (
      <>
        <WrappedComponent
          {...props}
          onMouseEnter={handleOpenInfoPop}
          onMouseLeave={handleCloseInfoPop}
        />
        {props.isOpen && (
          <PopItem
            open={open}
            anchorEl={anchorEl}
            popupText={popupText}
          />
        )}
      </>
    )
  }
  return PopperWrapper
}

export default popperWrapperHOC
