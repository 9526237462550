import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'
import React, { useCallback, useEffect, useState } from 'react'

export const HeaderSearchField = ({
  classes,
  id,
  placeholder,
  value: rawValue,
  onChange,
  endAdornment,
  allowClear,
  isMobile,
  ...props
}) => {
  const [value, setValue] = useState(rawValue)

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value)
      onChange && onChange(event.target.value)
    },
    [onChange],
  )

  useEffect(() => {
    setValue(rawValue)
  }, [rawValue])

  return (
    <TextField
      id={id}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      classes={classes}
      variant="outlined"
      sx={{ borderRadius: '8px' }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon htmlColor="#9298AA" />
          </InputAdornment>
        ),
        classes: classes,
      }}
      {...props}
    />
  )
}
