import { Box, Button } from '@mui/material'
import FAIcon from 'Theme/FAIcon'

export const NavButton = ({
  isOpen,
  btnColor = '',
  btnText = '',
  btnIcon = '',
  onClick,
  showIconOnOpened = false,
  ...rest
}) => (
  <Button
    variant="contained"
    color={btnColor}
    sx={(theme) => ({
      height: theme.spacing(5),
      minWidth: 'unset',
      width: isOpen ? '100%' : theme.spacing(6),
      marginLeft: 0,
      padding: 0,
    })}
    onClick={onClick}
    {...rest}
  >
    {isOpen ? (
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        {btnText}
        {showIconOnOpened && (
          <FAIcon
            iconClassName={btnIcon}
            iconFontSize={18}
          />
        )}
      </Box>
    ) : (
      <FAIcon
        iconClassName={btnIcon}
        iconFontSize={18}
      />
    )}
  </Button>
)
