import { alpha, Box, Button, Typography } from '@mui/material'
import { NotificationContext } from 'Components/Notification/context'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'
import CustomModal from 'Theme/Modal'

const RestoreButton = ({ onClick }) => (
  <Button
    sx={(theme) => ({
      padding: theme.spacing(1, 2),
      background: '#00ae7014',
      flexGrow: 1,
      height: '40px',
      '&:hover': {
        background: alpha('#00ae7014', 0.2),
      },
    })}
    onClick={onClick}
    startIcon={<FAIcon iconClassName={FA_ICONS.SOLID_ROTATE_LEFT} />}
  >
    Restore
  </Button>
)

export const ArchiveRestoreEventModal = ({
  isOpen,
  eventTypeName,
  withEdit = false,
  navigateToEdit,
  handleConfirm,
  handleCloseModal,
}) => {
  const { notification } = useContext(NotificationContext)

  const handleRestore = () => {
    handleConfirm(
      {},
      {
        onSuccess: () => {
          handleCloseModal()
          const capitalizedEventName = `${eventTypeName.charAt(0).toUpperCase()}${eventTypeName.slice(1)}`
          notification.success(`${capitalizedEventName} has been restored`, 'Restored')
        },
      },
    )
  }

  const handleEditClick = () => {
    navigateToEdit()
    handleCloseModal()
  }

  return (
    <CustomModal
      open={isOpen}
      onClose={handleCloseModal}
      title={`Restore ${eventTypeName}?`}
      description={`Are you sure you want to restore the ${eventTypeName}? This action will reactivate the ${eventTypeName} and make it available for participation`}
      footer={
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {withEdit && <RestoreButton onClick={handleRestore} />}
          <Box
            display="flex"
            gap={1}
            width="100%"
          >
            <Button
              color="inherit"
              sx={(theme) => ({
                width: 'calc(50% - 4px)',
                height: '40px',
                padding: theme.spacing(1, 2),
                background: theme.palette.background.pale,
              })}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            {withEdit ? (
              <Button
                color="secondary"
                startIcon={
                  <FAIcon
                    iconClassName={FA_ICONS.PEN}
                    iconFontSize={14}
                  />
                }
                sx={(theme) => ({
                  background: '#01a3d214',
                  width: 'calc(50% - 4px)',
                  padding: theme.spacing(1, 2),
                  height: '40px',
                  '&:hover': {
                    background: alpha('#01a3d214', 0.2),
                  },
                })}
                onClick={handleEditClick}
              >
                Edit
              </Button>
            ) : (
              <RestoreButton onClick={handleRestore} />
            )}
          </Box>
        </Box>
      }
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          border: `1px solid ${theme.palette.text.secondary}`,
          padding: '12px 16px',
          borderRadius: theme.spacing(1),
        })}
      >
        <FAIcon
          iconClassName={FA_ICONS.INFO}
          color="secondary.main"
        />
        <Box>
          <Typography
            variant="h6"
            color="text.primary"
          >
            Important!
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
          >
            All participants will receive a notification
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  )
}

ArchiveRestoreEventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  eventTypeName: PropTypes.string.isRequired,
  withEdit: PropTypes.bool,
  navigateToEdit: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.bool.isRequired,
}
