import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

const StyledBox = ({ gap = 0, children, ...props }) => {
  const theme = useTheme()

  return (
    <Box
      {...props}
      gap={theme.spacing(gap)}
    >
      {children}
    </Box>
  )
}

export default StyledBox
