import { Box, Button, Snackbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { When } from 'react-if'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import Alert from './Alert'

const useStyles = makeStyles((theme) => ({
  alert: {
    minWidth: '320px',
    minHeight: '64px',

    '& .MuiAlert-message': {
      display: 'flex',
    },
    '& .MuiAlert-action': {
      paddingLeft: 0,
    },
  },
  error: {
    background: 'rgba(252, 90, 90, 0.24)',
    '& .MuiAlert-action': {
      color: theme.palette.error.dark,
    },
  },
  success: {
    background: theme.palette.primary.main,
    '& .MuiAlert-action': {
      color: theme.palette.text.primary,
    },
  },
  info: {
    background: 'rgba(1, 163, 210, 0.8)',
    '& .MuiAlert-action': {
      color: theme.palette.text.primary,
    },
  },
  warning: {
    background: 'rgba(243, 152, 44, 0.1)',
    '& .MuiAlert-action': {
      color: theme.palette.warning.main,
    },
  },
}))

const stylesByState = {
  error: { icon: FA_ICONS.EXCLAMATION, color: 'error.dark' },
  success: { icon: FA_ICONS.INFO, color: 'text.primary' },
  info: { icon: FA_ICONS.INFO, color: 'text.primary' },
  warning: { icon: FA_ICONS.WARNING, color: 'warning.main' },
}

const CustomSnackbar = ({
  open,
  onClose,
  autoHideDuration = 6000,
  text = 'Something went wrong',
  title,
  severity = 'error',
  onDelete,
  onUndo,
}) => {
  const classes = useStyles()
  const { icon, color } = stylesByState[severity]

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        className={clsx(classes[severity], classes.alert)}
        icon={
          <FAIcon
            iconFontSize={16}
            iconClassName={icon}
            color={color}
          />
        }
      >
        <Box
          marginRight="15px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <When condition={!!title}>
            <Typography
              variant="h5"
              lineHeight="20px"
            >
              {title}
            </Typography>
          </When>
          <Typography variant="h6">{text}</Typography>
          <When condition={!!onUndo}>
            <Button
              variant="button"
              color={color}
              endIcon={
                <FAIcon
                  iconClassName={FA_ICONS.SOLID_ROTATE_LEFT}
                  iconFontSize={14}
                  fontWeight={700}
                />
              }
              onClick={() => {
                onUndo()
                onClose()
              }}
              sx={{ cursor: 'pointer', width: '80px', marginTop: '8px', padding: '6px 8px' }}
            >
              Undo
            </Button>
          </When>
        </Box>
        <When condition={!!onDelete}>
          <Typography
            variant="button"
            paddingTop="4px"
            color={color}
            onClick={onDelete}
            sx={{ cursor: 'pointer' }}
          >
            Delete
          </Typography>
        </When>
      </Alert>
    </Snackbar>
  )
}

CustomSnackbar.propTypes = {
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
  severity: PropTypes.oneOf(['error', 'success', 'info', 'warning']),
}

export default CustomSnackbar
