import { ClickAwayListener } from '@mui/base'
import { Backdrop, CircularProgress, Dialog, DialogContent, useMediaQuery } from '@mui/material'
import { useLoginMutation } from 'Queries/Auth'
import { Else, If, Then } from 'react-if'
import { navigateToEmptyHash } from 'Scenes/navigators'

import SceneMobileLogin from '../../../Scenes/Auth/SceneMobileLogin'
import { BREAKPOINT_MIN_SM } from '../../../Theme/stylesUtil'
import ButtonDialogClose from '../../_common/Buttons/ButtonDialogClose'
import LoginForm from '.'

const LoginModal = () => {
  const { mutate, isLoading } = useLoginMutation()
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)
  return (
    <Dialog
      maxWidth={false}
      scroll="body"
      fullScreen={!isBiggerThanSMScreen}
      open
    >
      <If condition={isBiggerThanSMScreen}>
        <Then>
          <ClickAwayListener onClickAway={() => navigateToEmptyHash()}>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row', gap: 5, padding: 3 }}>
              <ButtonDialogClose onClick={() => navigateToEmptyHash()} />

              <LoginForm mutate={mutate} />

              <Backdrop
                style={{ zIndex: 2 }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </DialogContent>
          </ClickAwayListener>
        </Then>
        <Else>
          <SceneMobileLogin />
        </Else>
      </If>
    </Dialog>
  )
}

export default LoginModal
