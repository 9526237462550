import './index.css'

import { useTheme } from '@mui/styles'
import { ReactComponent as LoaderImage } from 'Assets/img/preloader.svg'

const getWidthSize = (size) => {
  const width = window.innerWidth * 0.4
  const height = window.innerHeight * 0.4
  return width > height ? height : width
}

const AnimatedLoader = ({ size }) => {
  const theme = useTheme()
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <LoaderImage style={{ width: size || getWidthSize() }} />
    </div>
  )
}

export default AnimatedLoader
