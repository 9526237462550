import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const AccauntCardContainer = ({ name, value, descriptionTimer = null, timer = null, bgColor, icon, control }) => {
  const theme = useTheme()

  const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'justify',
    backgroundColor: '#F3982C26',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '0px 6px',
  }))

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      padding={3}
      bgcolor={bgColor || theme.palette.background.paper}
      borderRadius={0.75}
    >
      {icon}
      <Box
        display="flex"
        flexDirection="column"
        marginLeft={3}
        marginRight="16px"
      >
        <Typography
          variant="h6"
          color="text.secondary"
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color="text.tertiary"
        >
          {value}
        </Typography>
        {descriptionTimer && (
          <Stack
            spacing={{ xs: 1 }}
            direction="row"
            useFlexGap
            flexWrap="nowrap"
          >
            <Typography
              variant="body2"
              color="orange"
            >
              {descriptionTimer}
            </Typography>
            <Item>
              <Typography
                variant="body2"
                color="orange"
              >
                {timer}
              </Typography>
            </Item>
          </Stack>
        )}
      </Box>
      <Box marginLeft="auto">{control}</Box>
    </Box>
  )
}

export default AccauntCardContainer
