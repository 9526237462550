import React from 'react'

import { ReactComponent as BitcoinLogo } from '../Assets/img/Bitcoin.svg'
import { ReactComponent as EthereumLogo } from '../Assets/img/Ethereum.svg'
import { ReactComponent as PaypalLogo } from '../Assets/img/Paypal.svg'
import { ReactComponent as TetherLogo } from '../Assets/img/Tether.svg'
import FAIcon from '../Theme/FAIcon'
import { FA_ICONS } from '../Theme/font-awesome-icon-constants'

export const PAYMENTS_ITEMS = [
  {
    id: 1,
    paymentMethod: 'Paypal',
    icon: <PaypalLogo style={{ width: '24px' }} />,
  },
  {
    id: 2,
    paymentMethod: 'Bitcoin',
    icon: <BitcoinLogo style={{ width: '24px' }} />,
  },
  {
    id: 3,
    paymentMethod: 'Ethereum',
    icon: <EthereumLogo style={{ width: '24px' }} />,
  },
  {
    id: 4,
    paymentMethod: 'Tether',
    icon: <TetherLogo style={{ width: '24px' }} />,
  },
  {
    id: 5,
    paymentMethod: 'Bank USD',
    icon: (
      <FAIcon
        iconClassName={FA_ICONS.DOLLAR_SIGN}
        color="text.tertiary"
      />
    ),
  },
  {
    id: 6,
    paymentMethod: 'Bank EUR',
    icon: (
      <FAIcon
        iconClassName={FA_ICONS.EURO_SIGN}
        color="text.tertiary"
      />
    ),
  },
]

export const PAYMENTS_STATES = ['waiting_for_info', 'info_received', 'rewarded']
