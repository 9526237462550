import { useRestoreContestMutation } from '../../Queries/Contests'
import { navigateToEditContestPage } from '../../Scenes/navigators'
import { ArchiveRestoreEventModal } from '../_common/ArchiveEvent/ArchiveRestoreEventModal'

export const ArchiveRestoreContestModal = (props) => {
  const { eventId, ...rest } = props
  const { mutate: restoreContest } = useRestoreContestMutation(eventId)
  const navigateToEdit = () => navigateToEditContestPage(eventId)

  return (
    <ArchiveRestoreEventModal
      handleConfirm={restoreContest}
      navigateToEdit={navigateToEdit}
      {...rest}
    />
  )
}
