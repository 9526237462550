import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

const CustomHelmet = ({ title, description, image, noIndex = false }) => (
  <Helmet>
    <title>{title ? `${title} - Forgehub` : 'Forgehub'}</title>
    <meta
      name="description"
      content={description}
    />
    {noIndex && (
      <meta
        name="robots"
        content="noindex, follow"
      />
    )}
    {title && (
      <meta
        property="og:title"
        content={title || 'Forgehub'}
      />
    )}
    {description && (
      <meta
        property="og:description"
        content={description}
      />
    )}
    {image && (
      <meta
        property="og:image"
        content={image}
      />
    )}
    <meta
      property="og:url"
      content={window.location.href}
    />
    <meta
      property="og:type"
      content="website"
    />
  </Helmet>
)

CustomHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  noIndex: PropTypes.bool,
}

export default CustomHelmet
