import { Avatar, Box, Typography } from '@mui/material'
import AwardBadge from 'Components/_common/AwardBadge'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import { Else, If, Then, When } from 'react-if'
import { CardActions } from 'Theme/Card'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'
import { userImageSelector } from 'utils/user-images-utils'

import { transformTextWithThreeDots } from '../../../utils/text-transformation-utils'
import GameTypeIcon from '../GameTypeIcon'
import TrophyIcon from '../TrophyIcon'

const getInfoStyle = (theme) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.text.tertiary,
  ...theme.typography.caption,

  '& > *:first-of-type".': {
    marginRight: theme.spacing(0.5),
  },
})

export const CollectionActions = ({
  isShowUser,
  variant,
  gameTypeName,
  playersCountName,
  showGameCategory,
  collectionItem,
  isHaloWaypointUser,
  communityFavItem,
  showGameTypeAndCategory = true,
  isShowCommunityIcon,
  showCategoryText = true,
  isShowUserName = isShowUser,
  showContentInfo = false,
  hideAwards,
}) => (
  <CardActions style={{ display: 'block' }}>
    <Box
      display="flex"
      gap="8px"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <When condition={isShowUser}>
        <If condition={isHaloWaypointUser}>
          <Then>
            <FAIcon
              iconClassName={FA_ICONS.XBOX}
              iconFontSize={32}
            />
          </Then>
          <Else>
            <Avatar
              src={userImageSelector(collectionItem.user?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'medium')}
              style={{ width: 32, height: 32, marginRight: 0 }}
            />
          </Else>
        </If>
      </When>
      <Box width="calc(100% - 38px)">
        <Typography
          variant="h6"
          color="text.primary"
        >
          {collectionItem.title}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          gap="8px"
        >
          <Typography
            variant="caption"
            color="text.tertiary"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            display={variant === 'primary' ? 'flex' : 'block'}
            gap="10px"
          >
            <When condition={isShowUserName}>
              <If condition={isHaloWaypointUser}>
                <Then>{collectionItem?.haloWaypointUser?.display_name}</Then>
                <Else>{transformTextWithThreeDots(collectionItem.user?.login)}</Else>
              </If>
            </When>

            <If condition={showContentInfo}>
              <Then>
                <Box
                  display="flex"
                  gap="10px"
                >
                  <Box sx={getInfoStyle}>
                    <FAIcon iconClassName={FA_ICONS.HEART} />
                    <Typography variant="caption">{collectionItem.likes_total}</Typography>
                  </Box>

                  <Box sx={getInfoStyle}>
                    <FAIcon iconClassName={FA_ICONS.MESSAGE_LINES} />
                    <Typography variant="caption">{collectionItem.comments_total}</Typography>
                  </Box>

                  <Box sx={getInfoStyle}>
                    <FAIcon iconClassName={FA_ICONS.BOOKMARK} />
                    <Typography variant="caption">{collectionItem.bookmarks_total}</Typography>
                  </Box>
                </Box>
              </Then>
            </If>
          </Typography>
          <When condition={showGameTypeAndCategory}>
            <Box display="flex">
              <When condition={showCategoryText}>
                <Typography
                  variant="caption"
                  color="text.tertiary"
                  whiteSpace="nowrap"
                >
                  {showGameCategory ? gameTypeName : playersCountName}
                </Typography>
              </When>
              <GameTypeIcon
                size="small"
                variant="caption"
                color="text.tertiary"
                gameType={gameTypeName}
              />
            </Box>
          </When>
        </Box>
      </Box>
    </Box>
    <When condition={!hideAwards && collectionItem.state !== 'draft'}>
      <AwardBadge
        settings={collectionItem}
        containerStyles={{ position: 'absolute', right: '10px', top: '0px' }}
        isShowCommunityIcon={isShowCommunityIcon}
      />
    </When>
    <When condition={communityFavItem}>
      <TrophyIcon
        place={'liked'}
        withNumberInGoblet={true}
        number={communityFavItem}
        containerStyles={{ position: 'absolute', top: '15px', left: '11px', width: '38px' }}
      />
    </When>
  </CardActions>
)
