import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import SocialLinks from 'Components/Auth/SocialLinks'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from 'Theme/Button'

import SignUpHeroImage from '../../../Assets/img/SignUpHeroBackground.png'
import { navigateToRootPage } from '../../../Scenes/navigators'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import { createYearTime } from '../../../utils/date-utils'
import { useAuth } from '../hooks'

const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${SignUpHeroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'pointer',
  },
  icon: {
    fontSize: 30,
    overflow: 'visible',
    color: theme.palette.text.tertiary,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#2E3036',
  },
  layoutFooterText: {
    lineHeight: theme.spacing(2.5),
  },
  listItem: {
    marginTop: theme.spacing(1),
  },
  back: {
    position: 'absolute',
    top: theme.spacing(6),
    left: theme.spacing(6),
  },
}))

const listContent = [
  {
    title: 'Maps',
    description: 'Explore & follow your favorite creators and maps',
    iconClassName: FA_ICONS.MAP,
  },
  {
    title: 'Contests',
    description: 'Start earning money for your time in the games',
    iconClassName: FA_ICONS.TROPHY,
  },
  {
    title: 'Community',
    description: 'Choose the best maps together with the community',
    iconClassName: FA_ICONS.HEART,
  },
]

const SignUpDrawer = () => {
  const classes = useStyles()
  const { isAuthorized } = useAuth()

  useEffect(() => {
    isAuthorized && navigateToRootPage()
  }, [isAuthorized])

  return (
    <Box className={classes.root}>
      <Box>
        <Box
          component={Link}
          to={navigateToRootPage(true)}
          display="block"
          position="relative"
          height="320px"
          className={classes.hero}
        />
        <Button
          variant="text"
          color="inherit"
          sx={{ gap: 2 }}
          startIcon={
            <FAIcon
              iconFontSize={16}
              iconClassName={FA_ICONS.ARROW_LEFT}
            />
          }
          onClick={() => navigateToRootPage()}
          className={classes.back}
        >
          Back
        </Button>
        <Box
          px={7}
          py={7}
        >
          <List
            disablePadding
            sx={{
              '& li + li': {
                marginTop: '32px',
              },
            }}
          >
            {listContent.map(({ title, description, iconClassName }) => (
              <ListItem
                key={title}
                alignItems="flex-start"
                disableGutters
              >
                <ListItemAvatar>
                  <FAIcon
                    iconClassName={iconClassName}
                    boxSize={30}
                    className={classes.icon}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 900,
                      }}
                      color="text.primary"
                    >
                      {title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        px={7}
        py={7}
      >
        <SocialLinks />
        <Box
          display={'flex'}
          alignItems="center"
          mt={2}
        >
          <Typography
            className={classes.layoutFooterText}
            variant="body2"
          >
            ForgeHub © {createYearTime()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SignUpDrawer
