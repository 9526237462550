import { yupResolver } from '@hookform/resolvers/yup'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { useSetNewPasswordMutation } from 'Queries/Auth'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { navigateToEmptyHash, navigateToResetPasswordModal } from 'Scenes/navigators'
import Button from 'Theme/Button'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'
import TextField from 'Theme/TextField'
import * as yup from 'yup'

import ButtonDialogClose from '../../_common/Buttons/ButtonDialogClose'
import { NotificationContext } from '../../Notification/context'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
  },
  textField: {
    transition: 'all 0.2s ease 0s',

    '& input::placeholder': {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
  },
  inputGroup: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  adornmentIcon: {
    overflow: 'visible',
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  control: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
}))

const SetPasswordForm = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const code = useMemo(() => new URLSearchParams(search).get('code'), [search])
  const { notification } = useContext(NotificationContext)

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().required('Please enter your password'),
        confirmPassword: yup
          .string('As a string please')
          .required('Please confirm your password')
          .oneOf([yup.ref('password'), null], 'Passwords must match'),
      }),
    [],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  })
  const { mutate: setNewPassword, isLoading } = useSetNewPasswordMutation()

  useEffect(() => {
    if (!code) {
      navigateToResetPasswordModal()
    }
  }, [code])

  const onSubmit = useCallback(
    (data) => {
      setNewPassword(
        {
          code,
          password: data.password,
        },
        {
          onSuccess: () => {
            window.location.hash = ''
            window.location.search = ''
            window.location.pathname = '/'
            notification.success('Try to sign-in with a new password')
          },
        },
      )
    },
    [notification, setNewPassword, code],
  )

  return (
    <Dialog
      maxWidth={false}
      open
    >
      <DialogContent className={classes.root}>
        <ButtonDialogClose onClick={() => navigateToEmptyHash()} />

        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ mr: 2 }}
          >
            Set new password
          </Typography>
          <Box className={classes.inputGroup}>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.password}
                  placeholder="New Password"
                  variant="outlined"
                  type="password"
                  helperText={
                    errors.password?.message ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: 'none' }}
                      >
                        {errors.password.message}
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FAIcon
                          className={classes.adornmentIcon}
                          iconClassName={FA_ICONS.KEY}
                          boxSize={12}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.confirmPassword}
                  placeholder="Repeat password"
                  variant="outlined"
                  type="password"
                  helperText={
                    errors.confirmPassword?.message ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: 'none' }}
                      >
                        {errors.confirmPassword.message}
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FAIcon
                          className={classes.adornmentIcon}
                          iconClassName={FA_ICONS.KEY}
                          boxSize={12}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitControl}
            >
              Save password
              <FAIcon iconClassName={FA_ICONS.CHECK} />
            </Button>
          </Box>
        </form>
        <Backdrop
          style={{ zIndex: 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}

export default SetPasswordForm
