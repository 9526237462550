import { NotificationContext } from 'Components/Notification/context'
import { useLogoutMutation } from 'Queries/Auth'
import { useUserQuery } from 'Queries/Users'
import { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ROUTE_PROFILE_OWNER_SETTINGS_PATH } from 'Scenes/constants'
import { navigateToProfilePage } from 'Scenes/navigators'

import { AuthContext } from './context'

export const useAuth = () => useContext(AuthContext)

export const useIsAdmin = () => {
  const { id: userId } = useAuth()
  const { data: user } = useUserQuery(userId)
  return user?.statuses?.includes('admin')
}

export const useLogout = (callback) => {
  const { id, forceAuth } = useAuth()
  const { notification } = useContext(NotificationContext)
  const match = useRouteMatch(ROUTE_PROFILE_OWNER_SETTINGS_PATH)

  const handleLogoutProfileSettings = () => {
    if (match?.isExact) navigateToProfilePage(id)
  }

  const { mutate: logout, isLoading } = useLogoutMutation({
    onSuccess: () => {
      forceAuth()
      notification.success('Logged out successfully!', 'Success')
    },
  })

  const handleLogout = () => {
    logout()
    handleLogoutProfileSettings()
    callback()
  }

  return { logout: handleLogout, isLoading }
}
