export const FIRST_PLACE = 'first'
export const SECOND_PLACE = 'second'
export const THIRD_PLACE = 'third'
export const FOURTH_PLACE = 'fourth'
export const DEFAULT_PLACE = 'default'
export const LIKED_PLACE = 'liked'

export const PLACE_DICT_WITH_COME_VOTE = {
  [FIRST_PLACE]: [1],
  [SECOND_PLACE]: [2],
  [THIRD_PLACE]: [3],
  [DEFAULT_PLACE]: [4, 10],
  [LIKED_PLACE]: [],
}
export const PLACE_DICT_NO_COME_VOTE = {
  [FIRST_PLACE]: [1],
  [SECOND_PLACE]: [2],
  [THIRD_PLACE]: [3],
  [FOURTH_PLACE]: [4],
  [DEFAULT_PLACE]: [5, 10],
  [LIKED_PLACE]: [],
}

export const PLACE_TITLE_WITH_COME_VOTE = {
  [FIRST_PLACE]: '1 Place',
  [SECOND_PLACE]: '2 Place',
  [THIRD_PLACE]: '3 Place',
  [DEFAULT_PLACE]: '4 - 10 Places',
  [LIKED_PLACE]: 'Community vote',
}

export const PLACE_TITLE_NO_COME_VOTE = {
  [FIRST_PLACE]: '1 Place',
  [SECOND_PLACE]: '2 Place',
  [THIRD_PLACE]: '3 Place',
  [FOURTH_PLACE]: '4 Place',
  [DEFAULT_PLACE]: '5 - 10 Places',
  [LIKED_PLACE]: 'Community vote',
}

export const getPlaceTrophyIconName = (place) =>
  (place === 1 && FIRST_PLACE) ||
  (place === 2 && SECOND_PLACE) ||
  (place === 3 && THIRD_PLACE) ||
  (place === 4 && FOURTH_PLACE) ||
  (place > 3 && DEFAULT_PLACE) ||
  (!!!place && LIKED_PLACE)

export const getPlaceName = (place) =>
  (place === 1 && 'first') ||
  (place === 2 && 'second') ||
  (place === 3 && 'third') ||
  (place === 4 && 'fourth') ||
  (place === 5 && 'fifth') ||
  (place === 6 && 'sixth') ||
  (place === 7 && 'seventh') ||
  (place === 8 && 'eighth') ||
  (place === 9 && 'ninth') ||
  (place === 10 && 'tenth') ||
  (!!!place && 'community')
