import CardActions from '@mui/material/CardActions'
import withStyles from '@mui/styles/withStyles'

const StyledCardActions = withStyles(() => ({
  root: {
    zIndex: 1,
    padding: 0,
  },
}))(CardActions)

export default StyledCardActions
