import { Box, Typography, useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { ReactComponent as Flag } from 'Assets/img/SidebarFlag.svg'
import ExternalLink from 'Components/_common/ExternalLink'
import { Link } from 'react-router-dom'
import {
  navigateToBlogsListPage,
  navigateToPrivacyPage,
  navigateToTermsAndConditionsPage,
} from 'Scenes/navigators'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import { HREF_FORGE_HUB_DISCORD, HREF_FORGE_HUB_TWITTER, HREF_FORGE_HUB_YOUTUBE } from '../../../external-links'

export const MainSidebarFooter = ({ isOpen, onMouseEnter, onMouseLeave }) => {
  const theme = useTheme()
  const link = {
    textDecoration: 'none',
    color: theme.palette.text.tertiary,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  }
  const socialLink = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.tertiary,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  }
  const flag = {
    height: 24,
    borderRadius: theme.shape.borderRadius * 0.375,
  }
  return (
    <Box>
      <Box
        display="flex"
        flexDirection={!isOpen ? 'row' : 'column'}
        alignItems={!isOpen ? 'flex-start' : 'center'}
        gap={2}
        paddingX={!isOpen ? 3 : 1.75}
      >
        <Box
          onMouseEnter={(e) => onMouseEnter(e, 'Youtube')}
          onMouseLeave={onMouseLeave}
        >
          <ExternalLink
            to={HREF_FORGE_HUB_YOUTUBE}
            style={socialLink}
          >
            <FAIcon iconClassName={FA_ICONS.YOUTUBE} />
          </ExternalLink>
        </Box>
        <Box
          onMouseEnter={(e) => onMouseEnter(e, 'Twitter')}
          onMouseLeave={onMouseLeave}
        >
          <ExternalLink
            to={HREF_FORGE_HUB_TWITTER}
            style={socialLink}
          >
            <FAIcon iconClassName={FA_ICONS.TWITTER} />
          </ExternalLink>
        </Box>
        <Box
          onMouseEnter={(e) => onMouseEnter(e, 'Discord')}
          onMouseLeave={onMouseLeave}
        >
          <ExternalLink
            to={HREF_FORGE_HUB_DISCORD}
            style={socialLink}
          >
            <FAIcon iconClassName={FA_ICONS.DISCORD} />
          </ExternalLink>
        </Box>
      </Box>
      {!isOpen ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginX={3}
          marginY={2}
          borderRadius={1}
          style={{
            padding: '16px',
            backgroundColor: alpha('#FFFFFF', 0.03),
          }}
        >
          <Box
            width={100}
            display="flex"
            flexWrap="wrap"
            style={{ whiteSpace: 'break-spaces' }}
          >
            <Typography
              variant="overline"
              color="text.tertiary"
            >
              MADE BY UKRAINIANS
            </Typography>
          </Box>
          <div style={flag}>
            <Flag />
          </div>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          marginY={2}
          onMouseEnter={(e) => onMouseEnter(e, 'MADE BY UKRAINIANS')}
          onMouseLeave={onMouseLeave}
        >
          <div style={flag}>
            <Flag />
          </div>
        </Box>
      )}
      <Box
        paddingX={!isOpen ? 3 : 1.75}
        paddingBottom={3}
      >
        <Box
          display={!isOpen ? 'flex' : 'none'}
          paddingBottom={2}
          gap={3}
        >
          <Link
            to={navigateToBlogsListPage(true)}
            style={link}
          >
            <Typography
              variant="body2"
              color="inherit"
            >
              Blog
            </Typography>
          </Link>
        </Box>
        <Box
          display="flex"
          justifyContent={!isOpen ? 'flex-start' : 'center'}
          onMouseEnter={(e) => onMouseEnter(e, '© 2022 ForgeHub')}
          onMouseLeave={onMouseLeave}
        >
          <Typography
            variant="body2"
            color="text.tertiary"
          >
            ©
          </Typography>
          <Box
            display={!isOpen ? 'flex' : 'none'}
            marginLeft={0.5}
            gap={0.5}
          >
            <Typography
              variant="body2"
              color="text.tertiary"
            >
              ForgeHub
            </Typography>
            <Typography
              variant="body2"
              color="text.tertiary"
            >
              |
            </Typography>
            <Link
              to={navigateToTermsAndConditionsPage(true)}
              style={link}
            >
              <Typography
                variant="body2"
                color="inherit"
              >
                Terms
              </Typography>
            </Link>
            <Typography
              variant="body2"
              color="text.tertiary"
            >
              |
            </Typography>
            <Link
              style={link}
              to={navigateToPrivacyPage(true)}
            >
              <Typography
                variant="body2"
                color="inherit"
              >
                Privacy
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
