import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import ButtonDialogClose from '../../Components/_common/Buttons/ButtonDialogClose'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(5)}`,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: theme.spacing(5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  description: {
    textAlign: 'left',
    marginRight: theme.spacing(3),
  },
}))

function Dialogue({ image, title, description, button, open, onClose }) {
  const classes = useStyles()

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <DialogContent className={classes.root}>
        <ButtonDialogClose onClick={onClose} />

        <Box
          position="relative"
          height="200px"
          sx={{ '& > *': { width: '100%' } }}
        >
          {image}
        </Box>
        <Box className={classes.content}>
          <Typography
            variant="h4"
            color="text.primary"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className={classes.description}
          >
            {description}
          </Typography>
        </Box>
        <Box>{button}</Box>
      </DialogContent>
    </Dialog>
  )
}

export default Dialogue
