import { useAuth } from 'Components/Auth'
import { stringify } from 'query-string'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { CONTENT_TYPES } from 'Scenes/constants'
import { navigateToForgeContentPage } from 'Scenes/navigators'

import QUERY, {
  makeContentApiFavoritePath,
  makeContentApiPath,
  makeContentBookmarkApiPath,
  makeContentIdApiPath,
  makeContentLatestLikesApiPath,
  makeContentLikesApiPath,
  makeContentNotesIdApiPath,
  makeContentRatingsPath,
  makeContestContentApiPath,
  makeContestSingleContentApiPath,
  makeContestSubmissionContentCandidatesApiPath,
  makeImportPath,
  makeInfoPath,
  makeMapsBookmarkedQueryPath,
  makeMapsQueryPath,
  makeMapsUnbookmarkedQueryPath,
  makeUserContentPath,
} from './constants'

export const useContentQuery = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const query = makeContentApiPath(type)

  return useQuery(
    [`${query}/${id}`, `${id}`],
    async () => {
      const { data } = await axios.get(`${query}/${id}`)
      return data
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  )
}

export const useUploadImages = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()

  const queryClient = useQueryClient()
  const { data: oldData } = useContentQuery(id, type)
  const query = makeContentApiPath(type)

  return useMutation(
    (files) => {
      let formData = new FormData()
      files.forEach((i) => formData.append('file', i))

      return axios.patch(`${query}/${id}`, formData)
    },
    {
      enabled: !!id,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onSuccess: (data) => {
        queryClient.setQueryData([`${query}/${id}`, `${id}`], {
          ...oldData,
          ...(type === CONTENT_TYPES.MAPS
            ? { map_images: data.data.map_images }
            : { prefab_images: data.data.prefab_images }),
        })
      },
    },
  )
}

export const useDeleteImages = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()

  const queryClient = useQueryClient()
  const { data: oldData } = useContentQuery(id, type)

  const query = makeContentApiPath(type)

  return useMutation((deleteId) => axios.patch(`${query}/${id}`, { image_ids_to_remove: [deleteId] }), {
    enabled: !!id,
    onSuccess: (data) => {
      queryClient.setQueryData([`${query}/${id}`, `${id}`], {
        ...oldData,
        ...(type === CONTENT_TYPES.MAPS
          ? { map_images: data.data.map_images }
          : { prefab_images: data.data.prefab_images }),
      })
    },
  })
}

export const useSortImages = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()

  const queryClient = useQueryClient()
  const { data: oldData } = useContentQuery(id, type)

  const query = makeContentApiPath(type)

  return useMutation((sortedImages) => axios.patch(`${query}/${id}/images`, { image_ids_to_sort: sortedImages }), {
    enabled: !!id,
    onSuccess: (data) => {
      queryClient.setQueryData([`${query}/${id}`, `${id}`], {
        ...oldData,
        ...(type === CONTENT_TYPES.MAPS
          ? { map_images: data.data.map_images }
          : { prefab_images: data.data.prefab_images }),
      })
    },
  })
}

export const useSaveContentMutation = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const query = makeContentApiPath(type)

  return useMutation(
    [`${query}/userDraft`],
    (payload) => axios[id ? 'patch' : 'post'](`${query}${id ? `/${id}` : ''}`, payload),
    {
      onSettled: (content) => {
        queryClient.setQueryData([`${query}/${id}`, `${id}`], content.data)
        type === CONTENT_TYPES.MAPS && queryClient.invalidateQueries([makeMapsQueryPath()])
      },
      enabled: !!id,
    },
  )
}

export const useAddContentToFav = (type, id) => {
  const { axios } = useAuth()

  const queryClient = useQueryClient()

  const URL = makeContentApiFavoritePath(type, id)
  return useMutation(() => axios.post(URL), {
    onSettled: () => {
      queryClient.invalidateQueries([makeContentIdApiPath(id, type)])
    },
  })
}

export const useDeleteContentFromFav = (type, id) => {
  const { axios } = useAuth()

  const queryClient = useQueryClient()

  const URL = makeContentApiFavoritePath(type, id)

  return useMutation(() => axios.delete(URL), {
    onSettled: () => {
      queryClient.invalidateQueries([makeContentIdApiPath(id, type)])
    },
  })
}

export const useDeleteContentMutation = (id, type = CONTENT_TYPES.MAPS) => {
  const { id: loggedUserId, axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(() => axios.delete(makeContentIdApiPath(id, type)), {
    onSettled: () => {
      queryClient.invalidateQueries([makeUserContentPath(type), `${id}`])
      queryClient.invalidateQueries([makeContentApiPath(type), loggedUserId, '-time_created'])
    },
  })
}

export const useToggleBookmark = (type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(({ id, queryType }) => axios[queryType](makeContentBookmarkApiPath(id, type)), {
    onSuccess: (_, args) => {
      queryClient.invalidateQueries([makeContentIdApiPath(args.id, type)])
      // TODO when such functional will be in prefabs, use proper url for each type
      if (type === CONTENT_TYPES.MAPS) {
        queryClient.invalidateQueries([makeMapsBookmarkedQueryPath(), '-bookmarked_time_created'])
        queryClient.invalidateQueries([makeMapsBookmarkedQueryPath(), 'title'])
        queryClient.invalidateQueries([makeMapsUnbookmarkedQueryPath()])
      }
    },
  })
}

export const useLikeMutation = (type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation((id) => axios.post(makeContentLikesApiPath(id, type)), {
    onMutate: async (id) => {
      await queryClient.cancelQueries([makeContentIdApiPath(id, type), `${id}`])

      const oldContent = queryClient.getQueryData([makeContentIdApiPath(id, type), `${id}`])
      const newContent = {
        ...oldContent,
        is_like: true,
        likes_total: +oldContent.likes_total + 1,
      }

      queryClient.setQueryData([makeContentIdApiPath(id, type), `${id}`], newContent)
      return newContent
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        [makeContentIdApiPath(context.oldContent.id, type), `${context.oldContent.id}`],
        context.oldContent,
      )
    },
    onSettled: (_data, _error, _variables, context) => {
      queryClient.invalidateQueries([makeContentIdApiPath(context.oldContent?.id, type), `${context.oldContent?.id}`])
    },
    onSuccess: (_data, _error, _variables) => {
      queryClient.invalidateQueries([`${makeContentLatestLikesApiPath(type, _variables?.id)}`])
    },
  })
}

export const useUnlikeMutation = (type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation((id) => axios.delete(makeContentLikesApiPath(id, type)), {
    onMutate: async (id) => {
      await queryClient.cancelQueries([makeContentIdApiPath(id, type), `${id}`])

      const oldContent = queryClient.getQueryData([makeContentIdApiPath(id, type), `${id}`])
      const newContent = {
        ...oldContent,
        is_like: false,
        likes_total: +oldContent.likes_total - 1,
      }

      queryClient.setQueryData([makeContentIdApiPath(id, type), `${id}`], newContent)
      return newContent
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        [makeContentIdApiPath(context.oldContent.id, type), `${context.oldContent.id}`],
        context.oldContent,
      )
    },
    onSettled: (_data, _error, _variables, context) => {
      queryClient.invalidateQueries([makeContentIdApiPath(context.oldContent?.id, type), `${context.oldContent?.id}`])
    },
    onSuccess: (_data, _error, _variables) => {
      queryClient.invalidateQueries([`${makeContentLatestLikesApiPath(type, _variables?.id)}`])
    },
  })
}

export const useShowLatestLikes = (type = CONTENT_TYPES.MAPS, id, limit, search) => {
  const { axios } = useAuth()
  const URL = makeContentLatestLikesApiPath(type, id)

  const paramsObject = {
    _limit: limit || 999,
    _sort: '-timeCreated',
    _search: search || '',
  }

  const queryString = stringify(paramsObject, { skipNull: true })

  return useQuery(
    [URL, paramsObject],
    async () => {
      const { data } = await axios.get(`${URL}?${queryString}`)
      return data
    },
    {
      enabled: !!id,
    },
  )
}

export const useCreateContentByWaypointMutation = () => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(({ payload, type }) => axios.post(makeContentApiPath(type), payload), {
    onSuccess: (_, args) => {
      navigateToForgeContentPage(0, args.type)
    },
    onSettled: (_, _error, args) => {
      queryClient.invalidateQueries(makeContentApiPath(args.type))
    },
  })
}

export const useImportContent = (type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.IMPORT, type],
    async () => {
      const { data } = await axios.get(`${makeImportPath()}/${type}`)
      return data
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useInfoTime = (type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()

  return useQuery(
    [makeInfoPath(), type],
    async () => {
      const { data } = await axios.get(`${makeInfoPath()}`)
      return data
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useContentListQuery = (params = {}, contentType = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const query = makeContentApiPath(contentType)
  return useInfiniteQuery(
    [query, params],
    async ({ pageParam = 1 }) => {
      const { categoryId, type, themeId, limit, skip = 0, searchValue, _sort, ...filters } = params

      const paramsObject = {
        category_id: categoryId,
        theme_id: themeId,
        _page: pageParam,
        'title~': searchValue ? `%${searchValue}%` : null,
        _limit: limit || (pageParam === 1 ? 24 : 32),
        _skip: skip,
        state: 'published',
        ...filters,
      }

      switch (type) {
        case 'trending':
          paramsObject['_sort'] = '-trends_week'
          break
        default:
          paramsObject['_sort'] = _sort ?? null
      }

      const queryString = stringify(paramsObject, { skipNull: true })

      const { data } = await axios.get(`${query}?${queryString}`)
      return {
        data: data.data.map((map) => ({
          ...map,
          user: data.relations?.users?.[map.user_id],
        })),
        total: data.total,
      }
    },
    {
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      refetchOnWindowFocus: false,
      staleTime: 50000,
    },
  )
}

export const useSearchContentQuery = (
  params = {},
  contentType = CONTENT_TYPES.MAPS,
  isShowAll = false,
  enabled = true,
) => {
  const { axios } = useAuth()

  return useInfiniteQuery(
    [`${QUERY.SEARCH}/${contentType}`, params],
    async ({ pageParam = 1 }) => {
      const { categoryId, type, limit, skip = 0, searchValue, ...filters } = params
      const query = makeContentApiPath(contentType)
      const paramsObject =
        searchValue === '' && isShowAll
          ? {
              category_id: categoryId,
              _page: pageParam,
              _limit: limit || (pageParam === 1 ? 24 : 32),
              _skip: skip,
              state: 'published',
              ...filters,
            }
          : {
              category_id: categoryId,
              _page: pageParam,
              _limit: limit || (pageParam === 1 ? 24 : 32),
              _skip: skip,
              _search: searchValue,
              state: 'published',
              ...filters,
            }

      switch (type) {
        case 'trending':
          paramsObject['_sort'] = '-trends_week'
          break
        case 'featured':
          paramsObject['_sort'] = '-time_published'
          paramsObject['is_featured'] = 'true'
          break
        default:
          paramsObject['_sort'] = null
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${query}?${queryString}`)

      return {
        data: data.data?.map((map) => ({
          ...map,
          user: data.relations.users && data.relations.users[map.user_id],
        })),
        total: data.total,
        pages: data.pages,
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.data.length === 32,
    },
    {
      retry: false,
      cacheTime: 10000,
      retryDelay: 5000,
      refetchOnWindowFocus: false,
      staleTime: 50000,
      enabled,
    },
  )
}

export const useDeleteSubmittedContentMutation = (contestId, type = CONTENT_TYPES.MAPS) => {
  const { axios, id } = useAuth()
  const queryClient = useQueryClient()
  const modalContestParam = `?user_id=${id}`
  const CONTEST_PREFABS_URL = `${makeContestContentApiPath(contestId, type)}${modalContestParam}`
  const CONTEST_CANDIDATES_URL = makeContestSubmissionContentCandidatesApiPath(contestId, type)
  return useMutation(({ id }) => axios.delete(makeContestSingleContentApiPath(contestId, id, type)), {
    onSettled: () => {
      queryClient.invalidateQueries(CONTEST_PREFABS_URL)
      queryClient.invalidateQueries(CONTEST_CANDIDATES_URL)
      queryClient.invalidateQueries(id)
    },
  })
}

export const useSavePrefabRatingMutation = (prefabId) => {
  const { axios } = useAuth()
  const URL = makeContentRatingsPath(prefabId, CONTENT_TYPES.PREFABS)
  return useMutation((rating) => axios.post(URL, rating))
}

export const useGetNotesQuery = (id, type = CONTENT_TYPES.MAPS, params) => {
  const { axios, isAuthorized } = useAuth()
  const query = makeContentNotesIdApiPath(id, type)
  const { limit, page } = params

  return useQuery(
    [query, page],
    async () => {
      const paramsObject = {
        _page: page,
        _limit: limit || 8,
        _sort: '-time_created',
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${query}?${queryString}`)

      return {
        data: data.data?.map((note) => ({
          ...note,
          user: data.relations.users && data.relations.users[note.user_id],
        })),
        total: data.total,
        pages: data.pages,
      }
    },
    {
      enabled: isAuthorized && !!id,
    },
  )
}

export const useCreateNoteMutation = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const query = makeContentNotesIdApiPath(id, type)
  const queryClient = useQueryClient()

  return useMutation((note) => axios.post(query, note), {
    onSettled: () => {
      queryClient.invalidateQueries([query])
    },
  })
}

export const useEditNoteMutation = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const query = makeContentNotesIdApiPath(id, type)
  const queryClient = useQueryClient()

  return useMutation(({ note, id }) => axios.put(`${query}/${id}`, { note }), {
    onSettled: () => {
      queryClient.invalidateQueries([query])
    },
  })
}

export const useDeleteNoteMutation = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const query = makeContentNotesIdApiPath(id, type)

  return useMutation(({ id }) => axios.delete(`${query}/${id}`), {
    onSettled: () => {
      queryClient.invalidateQueries(query)
    },
  })
}

export const useGetNotesInfiniteQuery = (id, type = CONTENT_TYPES.MAPS) => {
  const { axios } = useAuth()
  const query = makeContentNotesIdApiPath(id, type)

  return useInfiniteQuery([query], async ({ pageParam = 1 }) => {
    const paramsObject = {
      _page: pageParam,
      _limit: 24,
      _sort: '-time_created',
    }

    const queryString = stringify(paramsObject, { skipNull: true })
    const { data } = await axios.get(`${query}?${queryString}`)

    return {
      data: data.data?.map((note) => ({
        ...note,
        user: data.relations.users && data.relations.users[note.user_id],
      })),
      total: data.total,
      pages: data.pages,
    }

  }, {
    enabled: !!id,
  })
}
