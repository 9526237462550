import { motion, useAnimation } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import { MapCard } from '../CollectionCard/MapCard'
import { PrefabCard } from '../CollectionCard/PrefabCard'
import MoreCard from './MoreCard'

const RowCollectionsDesktop = ({
  collection,
  wrapperContainerRef,
  isDragging,
  handleDrag,
  type,
  moreLink,
  showMoreBtn,
}) => {
  const [sliderWidth, setSliderWidth] = useState(0)
  const controls = useAnimation()
  const scrollContainer = useRef(null)

  useEffect(() => {
    scrollContainer.current &&
      setSliderWidth(-(scrollContainer.current.scrollWidth - wrapperContainerRef.current.clientWidth))
  }, [wrapperContainerRef])

  return (
    <motion.div
      ref={scrollContainer}
      drag="x"
      onDragStart={() => handleDrag(true)}
      onDragEnd={() => handleDrag(false)}
      dragConstraints={{
        right: 0,
        left: sliderWidth,
      }}
      animate={controls}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        columnGap: '24px',
        pointerEvents: isDragging ? 'none' : 'auto',
      }}
    >
      {collection?.map((item) => {
        switch (type) {
          case 'map':
            return (
              <MapCard
                map={item}
                key={item.id}
                style={{ width: 285, height: 192 }}
              />
            )
          case 'prefab':
            return (
              <PrefabCard
                prefab={item}
                key={item.id}
                style={{ width: 285, height: 192 }}
              />
            )
          default:
            return null
        }
      })}
      {showMoreBtn && (
        <MoreCard
          link={moreLink}
          type={type}
        />
      )}
    </motion.div>
  )
}

export default RowCollectionsDesktop
