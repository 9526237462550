import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useSetNewEmailMutation } from '../../../Queries/Auth'
import { navigateToRootPage } from '../../../Scenes/navigators'
import { NotificationContext } from '../../Notification/context'

const SetNewEmail = () => {
  const { search } = useLocation()
  const { notification } = useContext(NotificationContext)
  const { mutate: setNewEmail } = useSetNewEmailMutation()

  useEffect(() => {
    const codeValue = new URLSearchParams(search).get('code')
    const resultObject = { code: codeValue }
    if (resultObject.code !== null) {
      setNewEmail(resultObject, {
        onSuccess: () => {
          notification.success('You have successfully changed your email')
          navigateToRootPage()
        },
        onError: () => {
          notification.error('Something went wrong')
          navigateToRootPage()
        },
      })
    }
  }, [notification, search, setNewEmail])
}

export default SetNewEmail
