import { Button } from '@mui/material'
import { useState } from 'react'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

export const ArchiveRestoreEvent = ({ renderRestoreModal }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (event) => {
    event?.preventDefault()
    setIsOpen(true)
  }
  const handleCloseModal = (event) => {
    event?.preventDefault()
    setIsOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FAIcon iconClassName={FA_ICONS.SOLID_ROTATE_LEFT} />}
        sx={{ alignSelf: 'center' }}
        onClick={handleClick}
      >
        Restore
      </Button>

      {renderRestoreModal({ isOpen, handleCloseModal })}
    </>
  )
}
