import { Box } from '@mui/material'
import { useAuth } from 'Components/Auth'
import { useCallback, useState } from 'react'
import { navigateToLoginModal } from 'Scenes/navigators'

import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import { NavButton } from './NavButton'
import PublishContentModal from './PublishModal/PublishContentModal'

export const SubmitButton = ({ isOpen, onMouseEnter, onMouseLeave, isTablet, isMobile }) => {
  const { isAuthorized } = useAuth()

  const [dialogOpened, setDialogOpened] = useState(false)

  const handleButtonClick = () => {
    if (!isAuthorized) {
      navigateToLoginModal()
    } else {
      setDialogOpened(true)
    }
  }

  const handleMouseEnter = (e) => {
    if (isTablet) return
    onMouseEnter(e, 'Publish content')
  }
  const handleMouseLeave = () => {
    if (isTablet) return
    onMouseLeave()
  }

  const handleModalClose = useCallback(() => setDialogOpened(false), [])

  return (
    <>
      <Box
        paddingX={isMobile ? 1.5 : !isOpen ? 3 : 1.625}
        paddingY={isMobile ? 0 : '16px'}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={handleMouseLeave}
      >
        <NavButton
          isOpen={!isOpen}
          btnText={isMobile ? 'Publish Content' : 'Publish'}
          btnColor="secondary"
          btnIcon={FA_ICONS.SQUARE_PLUS}
          onClick={handleButtonClick}
          showIconOnOpened
        />
      </Box>
      {dialogOpened && (
        <PublishContentModal
          dialogOpened={dialogOpened}
          handleClose={handleModalClose}
        />
      )}
    </>
  )
}
