import { Box, Typography } from '@mui/material'

export const ContentCard = ({ image, title, handleClick, sx }) => (
  <Box
    sx={{
      height: '110px',
      width: 'calc(25% - 8px)',
      borderRadius: '8px',
      overflow: 'hidden',
      position: 'relative',
      cursor: 'pointer',
      ...sx,
    }}
    onClick={handleClick}
  >
    {image && (
      <img
        src={image}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        alt="img"
      />
    )}
    <Typography
      variant="h6"
      sx={{ position: 'absolute', left: '20px', bottom: '12px' }}
    >
      {title}
    </Typography>
  </Box>
)
