import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

export default function PlateContainer({
  min = '250px',
  max = '1fr',
  gap = 3, // #TODO Change Gap for team
  rowHeight = 'inherit',
  scroll = false,
  gridColsRepeat = 'auto-fit',
  children,
  display = 'grid',
  ...props
}) {
  const theme = useTheme()

  return (
    <Box
      display={display}
      flexWrap={'wrap'}
      gridTemplateColumns={`repeat(${gridColsRepeat}, minmax(${min}, ${max}))`}
      gap={theme.spacing(gap)}
      gridAutoFlow={scroll ? 'column' : 'dense'}
      gridAutoColumns={`minmax(${min},${max})`}
      gridTemplateRows={rowHeight}
      {...props}
    >
      {children}
    </Box>
  )
}
