import React from 'react'

const ExternalLink = ({ to, children, style = {}, className }) => {
  const href = to.includes('://') ? to : `https://${to}`

  return (
    <a
      className={className}
      href={href}
      style={style}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}
export default ExternalLink
