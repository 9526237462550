import React from 'react'

import Chip from '../../Theme/Chip'
import FAIcon from '../../Theme/FAIcon'
import { FA_ICONS } from '../../Theme/font-awesome-icon-constants'
import { getTimeLeftFromMinutes } from '../ContestComponents/utils'

const ChipTimeLeft = ({ timeLeft = 0, isMobile }) => (
  <Chip
    color={timeLeft < 60 ? 'error' : 'secondary'}
    size="small"
    icon={
      <FAIcon
        iconClassName={FA_ICONS.HOURGLASS}
        iconFontSize={13}
      />
    }
    label={getTimeLeftFromMinutes(timeLeft)}
    style={{ marginLeft: isMobile ? 0 : '16px' }}
  />
)

export default ChipTimeLeft
