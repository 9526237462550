import { useAuth } from '../Components/Auth'
import { SOCIAL_ACCOUNTS_NAME_ID, useConnectedAccountsQuery, useSocialLogin } from '../Queries/Auth'
import { usePersistedSocialRedirectState } from './usePersistedData'

export const useConnected3rdAccounts = () => {
  const { isAuthorized } = useAuth()
  const { data: connectedAccounts = [], isLoading: isConnectedAccountsLoading } =
    useConnectedAccountsQuery(isAuthorized)
  const isXboxLiveConnected = connectedAccounts.includes(SOCIAL_ACCOUNTS_NAME_ID.LIVE)

  return { connectedAccounts, isConnectedAccountsLoading, isXboxLiveConnected }
}

export const useConnect3rdAccount = () => {
  const [, setRedirectPathname] = usePersistedSocialRedirectState()
  const { login, isLoading: isAccountConnectLoading } = useSocialLogin()

  const handleAccountConnect = (socialId = SOCIAL_ACCOUNTS_NAME_ID.LIVE) => {
    setRedirectPathname(window.location.pathname)
    return login(socialId)
  }

  return {
    handleAccountConnect,
    isAccountConnectLoading,
  }
}
