import { Skeleton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import ChipTimeLeft from 'Components/_common/ChipTimeLeft'
import { VOTE_DESCRIPTION_TEXT } from 'Components/CommunityFavoritesComponents/constants'
import { plainTextTransformation } from 'Components/ContestComponents/utils'
import { CONTENT_TYPES } from 'Queries/constants'
import React from 'react'
import { Else, If, Then, When } from 'react-if'
import { navigateToCommunityFavoriteVotePage, navigateToHomeReadMorePage } from 'Scenes/navigators'
import Box from 'Theme/Box'
import Button from 'Theme/Button'
import { getMonthFromMs, getMonthFromYearMonth } from 'utils/date-utils'

import { transformTextWithThreeDots } from '../../../utils/text-transformation-utils'

const { TYPE_ARTICLE } = CONTENT_TYPES
const HomeHeroDescription = ({ showSkeletons, selectedItem, isCommVoteActive, timeLeft }) => {
  const { year_month, published, time_begin, title, description, contentType, id } = selectedItem || {}
  const descriptionBody = selectedItem.contentType === TYPE_ARTICLE ? selectedItem.body : description
  const dateInfo =
    (!!year_month && getMonthFromYearMonth(year_month)) ||
    (!!published && getMonthFromMs(published)) ||
    (!!time_begin && getMonthFromMs(time_begin)) ||
    ''
  const articleDateInfo = selectedItem.contentType === TYPE_ARTICLE && getMonthFromMs(selectedItem.time_published)

  return (
    <Grid
      container
      mt={16}
    >
      <Grid
        item
        xs={12}
        sm={4}
      >
        <If condition={showSkeletons}>
          <Then>
            <Skeleton
              variant="rounded"
              width={300}
              height={216}
            />
          </Then>
          <Else>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="subtitle1"
                color="secondary"
              >
                {articleDateInfo || dateInfo}
              </Typography>
              <When condition={isCommVoteActive}>
                <ChipTimeLeft timeLeft={timeLeft} />
              </When>
            </Box>
            <Box py={2}>
              <Typography
                variant="h2"
                color="text.primary"
                sx={{ wordBreak: 'break-word' }}
              >
                {transformTextWithThreeDots(title)}
              </Typography>
            </Box>
            <Box minHeight="72px">
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {plainTextTransformation(isCommVoteActive ? VOTE_DESCRIPTION_TEXT : descriptionBody)}
              </Typography>
            </Box>
            <Box pt={2}>
              <If condition={isCommVoteActive}>
                <Then>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigateToCommunityFavoriteVotePage()}
                  >
                    Vote now!
                  </Button>
                </Then>
                <Else>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigateToHomeReadMorePage(contentType, id)}
                  >
                    Read more
                  </Button>
                </Else>
              </If>
            </Box>
          </Else>
        </If>
      </Grid>
    </Grid>
  )
}

export default HomeHeroDescription
