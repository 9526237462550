import { yupResolver } from '@hookform/resolvers/yup'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'
import { ReactComponent as LogoWithText } from 'Assets/img/LogoForgehub.svg'
import SocialLogin from 'Components/Auth/SocialLogin'
import { NotificationContext } from 'Components/Notification/context'
import { useRegisterMutation } from 'Queries/Auth'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Else, If, Then, When } from 'react-if'
import Button from 'Theme/Button'
import CustomMuiCheckbox from 'Theme/Checkbox'
import Dialogue from 'Theme/Dialogue'
import { BREAKPOINT_MIN_SM } from 'Theme/stylesUtil'
import TextField from 'Theme/TextField'
import * as yup from 'yup'

import { ReactComponent as DialogueImage } from '../../../Assets/img/EmailModalImage.svg'
import { HREF_GOOGLE } from '../../../external-links'
import { navigateToLoginModal } from '../../../Scenes/navigators'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'

const useStyles = makeStyles((theme) => ({
  form: {
    minWidth: '450px',
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: '100%',
    },

    '& > *': {
      marginTop: theme.spacing(5),
    },
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    gap: theme.spacing(1.5),

    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  textField: {
    '& input::placeholder': {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
    '& svg': {
      color: theme.palette.text.disabled,
      fontSize: '14px',
      marginRight: '8px',
    },
  },
  submitControl: {
    color: theme.palette.secondary.contrastText,
  },
  control: {
    '& span > :first-child': {
      color: theme.palette.secondary.light,
      marginRight: theme.spacing(1.5),
    },
  },
  privacyControl: {
    marginTop: theme.spacing(3),
  },
}))

const SignUpForm = () => {
  const classes = useStyles()
  const [isDialogueOpen, setIsDialogueOpen] = useState(false)
  const { notification } = useContext(NotificationContext)
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)

  const schema = useMemo(
    () =>
      yup.object().shape({
        login: yup.string('As a string please').required('Please enter your username'),
        email: yup.string('As a string please').email('Invalid email format').required('Please enter your email'),
        password: yup.string('As a string please').required('Please enter your password'),
        privacy: yup.boolean().oneOf([true], 'Please confirm that you read Privacy Policy'),
      }),
    [],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: '',
      email: '',
      password: '',
      privacy: false,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const { mutate: register, isLoading, error } = useRegisterMutation()

  useEffect(() => {
    if (error) {
      notification.error(error?.response.data.description)
    }
  }, [error, notification])

  const onSubmit = useCallback(
    (data) => {
      register(data, {
        onSuccess: () => setIsDialogueOpen(true),
      })
    },
    [register],
  )

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <When condition={!isBiggerThanSMScreen}>
        <Box margin="100px auto 0 auto">
          <LogoWithText />
        </Box>
      </When>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <When condition={isBiggerThanSMScreen}>
          <Typography
            variant="h4"
            className={classes.formLabel}
          >
            <FAIcon iconClassName={FA_ICONS.USER_PLUS} />
            <span>Sign Up</span>
          </Typography>
        </When>

        <SocialLogin />

        <Box className={classes.inputGroup}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.email}
                placeholder="Email"
                variant="outlined"
                type="email"
                helperText={
                  errors.email?.message ? (
                    <Typography
                      variant="body2bold"
                      color="error"
                      style={{ textTransform: 'none' }}
                    >
                      {errors.email.message}
                    </Typography>
                  ) : null
                }
                InputProps={{
                  className: classes.textField,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FAIcon iconClassName={FA_ICONS.ENVELOPE} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="login"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.login}
                placeholder="Username"
                variant="outlined"
                helperText={
                  errors.login?.message ? (
                    <Typography
                      variant="body2bold"
                      color="error"
                      style={{ textTransform: 'none' }}
                    >
                      {errors.login.message}
                    </Typography>
                  ) : null
                }
                InputProps={{
                  className: classes.textField,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FAIcon iconClassName={FA_ICONS.EMAIL_AT} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.password}
                placeholder="Password"
                variant="outlined"
                type="password"
                helperText={
                  errors.password?.message ? (
                    <Typography
                      variant="body2bold"
                      color="error"
                      style={{ textTransform: 'none' }}
                    >
                      {errors.password.message}
                    </Typography>
                  ) : null
                }
                InputProps={{
                  className: classes.textField,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FAIcon iconClassName={FA_ICONS.KEY} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>

        <FormControl className={classes.privacyControl}>
          <Controller
            name="privacy"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<CustomMuiCheckbox />}
                label={
                  <Typography
                    variant="body2"
                    color={errors.privacy ? 'error' : 'text.tertiary'}
                  >
                    I agree to the <strong>Terms&#38;Conditions</strong> and <strong>ForgeHub Privacy</strong>
                  </Typography>
                }
              />
            )}
          />
        </FormControl>
        <If condition={isBiggerThanSMScreen}>
          <Then>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography
                variant="body2"
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '17px',
                  color: theme.palette.text.secondary,
                })}
              >
                <span>Have an account?</span>
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ marginLeft: '8px' }}
                  onClick={() => navigateToLoginModal()}
                >
                  Login
                </Button>
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  gap: 2,
                }}
              >
                <Typography
                  variant="inherit"
                  className={classes.submitControl}
                >
                  Sign Up
                </Typography>
                <FAIcon iconClassName={FA_ICONS.CHECK} />
              </Button>
            </Box>
          </Then>
          <Else>
            <Box
              sx={{
                width: '100%',
                height: '56px',
                position: 'fixed',
                left: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                backgroundColor: 'background.paper',
                padding: '0 16px 0 24px',
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{ gap: 2 }}
              >
                <Typography
                  variant="inherit"
                  className={classes.submitControl}
                >
                  Sign Up
                </Typography>
                <FAIcon iconClassName={FA_ICONS.USER_PLUS} />
              </Button>
            </Box>
          </Else>
        </If>
      </form>
      <Dialogue
        image={<DialogueImage />}
        title="Verify your Account"
        description="Thanks for Joining ForgeHub. We just sent a verification link to your email"
        open={isDialogueOpen}
        onClose={() => setIsDialogueOpen(false)}
        button={
          control._formValues.email.includes('@gmail.com') ? (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.control}
              target="_blank"
              href={HREF_GOOGLE}
            >
              <Typography
                variant="h6"
                sx={{ mr: 2 }}
              >
                Go to your <strong>gmail.com</strong> inbox
              </Typography>
              <FAIcon
                iconClassName={FA_ICONS.ENVELOPE}
                iconFontSize={20}
              />
            </Button>
          ) : (
            ''
          )
        }
      />
      <Backdrop
        style={{ zIndex: 2 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default SignUpForm
