import Container from '@mui/material/Container'

const CustomContainer = ({ children, sxStyles, ...rest }) => (
  <Container
    maxWidth={false}
    disableGutters
    sx={(theme) => ({
      padding: '0 20px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 40px',
      },
      ...sxStyles,
    })}
    {...rest}
  >
    {children}
  </Container>
)

export default CustomContainer
