import { WAYPOINT_URL_REGEXP } from 'Scenes/constants'

export const PUBLISH_ITEMS = {
  MAPS: 'Maps',
  PREFABS: 'Prefabs',
  URL: 'By URL',
}

export const generateContentUrl = (content, id) => `https://www.halowaypoint.com/halo-infinite/ugc/${content}/${id}`

export const testWaypointLink = (link) => WAYPOINT_URL_REGEXP.test(link)
