export const getUserFromRelations = (data, userId) => data.relations.users[userId]

export const getMapFromRelations = (data, mapId) => data.relations.maps[mapId]

export const getCategoryFromRelations = (data, categoryId) => data.relations.categories[categoryId]

export const PROFILE_IMAGE_TYPE = {
  AVATAR: 'avatar',
  BACKGROUND: 'background',
  IMAGE: 'image',
  META_IMAGE: 'metaImage',
}

export const getCodeNameFromResponseError = (error) => error?.response?.data?.developerMessage?.codeName

export const getRemakeImage = (remake) => remake?.images[0]?.urls?.original
