import Card from '@mui/material/Card'
import withStyles from '@mui/styles/withStyles'

const StyledCard = withStyles((theme) => ({
  root: {
    position: 'relative',
  },
}))(Card)

export default StyledCard
