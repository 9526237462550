import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { CONTENT_TYPES } from 'Queries/constants'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import React, { useEffect, useState } from 'react'
import { Else, If, Then, When } from 'react-if'
import { useIntervalWhen } from 'rooks'
import { navigateToArchivePage } from 'Scenes/navigators'
import Button from 'Theme/Button'
import { BREAKPOINT_MIN_MD, BREAKPOINT_MIN_SM } from 'Theme/stylesUtil'
import { getMonthFromYearMonth } from 'utils/date-utils'
import { userImageSelector } from 'utils/user-images-utils'

import { SliderTabsIndicator } from './SliderTabsIndicator'

const SLIDER_DELAY = 10000

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    display: 'flex',
    borderRadius: theme.spacing(1),
    width: 'fit-content',
    alignSelf: 'end',
    backgroundColor: alpha(theme.palette.background.paper, 0.5),
    backdropFilter: 'blur(16px)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[6],
    width: '64px',
    height: '64px',
    objectFit: 'cover',
  },
  item: {
    display: 'flex',
    cursor: 'pointer',
    opacity: '0.8',
    alignItems: 'stretch',
    '&:hover': {
      opacity: 1,
    },
  },
  selected: {
    opacity: 1,
  },
  selectedBorder: {
    borderTop: '3px solid #35bde4',
  },
}))

const WidgetSkeleton = () => (
  <Box
    gap={1}
    display="flex"
  >
    <Skeleton
      width="200px"
      height="100px"
    />
    <Skeleton
      width="200px"
      height="100px"
    />
    <Skeleton
      width="200px"
      height="100px"
    />
  </Box>
)

const { TYPE_ARTICLE, TYPE_COMMUNITY_FAVORITE } = CONTENT_TYPES

const HomeHeroSliderControls = ({ showSkeletons, items, selectedItem, handleSelect, handleNext }) => {
  const classes = useStyles()
  const [changeSlide, setChangeSlide] = useState(false)
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)
  const isBiggerThanMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)

  const { id: selectedItemId, contentType } = selectedItem

  // run new interval when slide changes
  useEffect(() => setChangeSlide(false), [selectedItemId])
  useIntervalWhen(() => setChangeSlide(true), 300, !changeSlide)
  useIntervalWhen(() => handleNext(), SLIDER_DELAY, changeSlide)

  const tabImageFilterStyle = (item) => (item.id !== selectedItemId ? 'brightness(40%)' : 'none')
  const nameColor = (item, theme) =>
    item.id !== selectedItemId ? theme.palette.text.tertiary : theme.palette.text.primary
  const titleColor = (item, theme) =>
    item.id !== selectedItemId ? theme.palette.text.tertiary : theme.palette.secondary.main

  return (
    <Grid
      mt={showSkeletons ? 2 : 0}
      mb={5}
      container
      justifyContent="flex-end"
    >
      <Grid
        item
        xs={7}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignfavorites="flex-end"
        >
          <Box className={classes.carouselContainer}>
            <When condition={!showSkeletons}>
              <Button
                variant="text"
                color="secondary"
                sx={{ position: 'absolute', top: '-55px', right: 0 }}
                disabled={showSkeletons}
                onClick={() => navigateToArchivePage(contentType)}
              >
                Archive
              </Button>
            </When>
            <If condition={showSkeletons && isBiggerThanSMScreen}>
              <Then>
                <WidgetSkeleton />
              </Then>
              <Else>
                <When condition={isBiggerThanSMScreen}>
                  <Box
                    display="flex"
                    flexDirection="column"
                  >
                    <Box display="flex">
                      {items.map(
                        (item, index) =>
                          item.id && (
                            <Box
                              key={index + item}
                              className={clsx(classes.item, item.id === selectedItemId && classes.selected)}
                              onClick={() => item.id && handleSelect(item.id)}
                              sx={{
                                width: isBiggerThanMDScreen ? '250px' : '200px',
                              }}
                            >
                              <Box
                                className={classes.imageContainer}
                                sx={{ filter: tabImageFilterStyle(item) }}
                              >
                                <If condition={item.contentType === TYPE_ARTICLE}>
                                  <Then>
                                    <img
                                      className={classes.image}
                                      src={userImageSelector(item.news_images, PROFILE_IMAGE_TYPE.IMAGE, 'small')}
                                      alt=""
                                    />
                                  </Then>
                                  <Else>
                                    <img
                                      className={classes.image}
                                      src={item.image}
                                      alt=""
                                    />
                                  </Else>
                                </If>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginY={2}
                                paddingX={2}
                                width="calc(100% - 64px)"
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={(theme) => ({
                                    marginBottom: theme.spacing(0.5),
                                    transition: theme.transitions.create('color'),
                                    color: titleColor(item, theme),
                                    overflow: 'hidedn',
                                    textOverflow: 'ellipsis',
                                  })}
                                >
                                  {item.contentType !== TYPE_COMMUNITY_FAVORITE
                                    ? item.contentType
                                    : getMonthFromYearMonth(item.year_month)}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={(theme) => ({
                                    color: nameColor(item, theme),
                                    transition: theme.transitions.create('color'),
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  })}
                                >
                                  {item.title}
                                </Typography>
                              </Box>
                            </Box>
                          ),
                      )}
                    </Box>
                    <SliderTabsIndicator
                      items={items}
                      selectedItem={selectedItemId}
                    />
                  </Box>
                </When>
              </Else>
            </If>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default HomeHeroSliderControls
