import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { useResetPasswordMutation } from 'Queries/Auth'
import React, { useCallback, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Button from 'Theme/Button'
import Dialogue from 'Theme/Dialogue'
import TextField from 'Theme/TextField'

import { ReactComponent as DialogueImage } from '../../../Assets/img/EmailModalImage.svg'
import { HREF_GOOGLE } from '../../../external-links'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import ButtonDialogClose from '../../_common/Buttons/ButtonDialogClose'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    minWidth: '320px',
    transition: 'all 0.2s ease 0s',
    [theme.breakpoints.down('sm')]: {
      minWidth: '0px',
    },

    '& input::placeholder': {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
  },
  adornmentIcon: {
    overflow: 'visible',
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  control: {
    marginRight: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
}))

const LostPasswordForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const [isDialogueOpen, setIsDialogueOpen] = useState(false)
  const { mutate: resetPassword, isLoading } = useResetPasswordMutation()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  })
  const captchaRef = useRef(null)

  const onSubmit = useCallback(() => {
    const data = getValues()
    // const token = captchaRef.current.getValue()
    // if (!token) {
    //   captchaRef.current.reset()
    //   return
    // }
    resetPassword(data, {
      onSuccess: () => {
        // captchaRef.current.reset()
        setIsDialogueOpen(true)
      },
    })
  }, [resetPassword, getValues])

  return isDialogueOpen ? (
    <Dialogue
      image={<DialogueImage />}
      title="Check your Account"
      description="A password reset request has been emailed to you. Please follow the instruction in that email."
      open={isDialogueOpen}
      onClose={() => history.push('')}
      button={
        control._formValues.email.includes('@gmail.com') ? (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.control}
            target="_blank"
            href={HREF_GOOGLE}
          >
            <Typography
              color="textSecondaryLight"
              variant="h6"
              sx={{ mr: 2 }}
            >
              Go to your <strong>gmail.com</strong> inbox
            </Typography>
            <FAIcon
              iconClassName={FA_ICONS.ENVELOPE}
              iconFontSize={20}
            />
          </Button>
        ) : (
          ''
        )
      }
    />
  ) : (
    <Dialog
      maxWidth={false}
      id="id"
      open
    >
      <DialogContent className={classes.root}>
        <ButtonDialogClose onClick={() => history.push('')} />

        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            variant="h4"
            color="text.primary"
          >
            Lost Password
          </Typography>
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              We'll email you a link to reset your password
            </Typography>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Email"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={
                    errors.email ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: 'none' }}
                      >
                        Email is required
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FAIcon
                          iconClassName={FA_ICONS.ENVELOPE}
                          iconFontSize={14}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="start"
          >
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{ gap: 2 }}
            >
              Reset Password
              <FAIcon
                iconClassName={FA_ICONS.KEY}
                iconFontSize={14}
              />
            </Button>
          </Box>
          {process.env.SITE_KEY && (
            <ReCAPTCHA
              sitekey={process.env.SITE_KEY}
              ref={captchaRef}
              theme="dark"
            />
          )}
        </form>
        <Backdrop
          style={{ zIndex: 2 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </DialogContent>
    </Dialog>
  )
}

export default LostPasswordForm
