import { lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import {
  ROUTE_CREATE_MAP_PATH,
  ROUTE_DOWNLOAD_ENTITY,
  ROUTE_EDIT_MAP_PATH,
  ROUTE_MAP_ID_PATH,
  ROUTE_MAPS_PATH,
} from 'Scenes/constants'

import RoutesFromConfig from '../RoutesFromConfig'

const config = [
  {
    path: ROUTE_MAPS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneMaps" */ './SceneMaps')),
  },
  {
    path: ROUTE_CREATE_MAP_PATH,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateMap" */ './SceneCreateMap')),
  },
  {
    path: ROUTE_EDIT_MAP_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneEditeMap" */ './SceneCreateMap')),
  },
  {
    path: ROUTE_MAP_ID_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneMaps" */ './SceneSingleMap')),
  },
  {
    path: ROUTE_DOWNLOAD_ENTITY,
    exact: false,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneDownloadEntity" */ '../SceneDownloadEntity')),
  },
]

const MapsScene = () => (
  <Switch>
    <RoutesFromConfig config={config} />
    <Redirect to={ROUTE_MAPS_PATH} />
  </Switch>
)

export default MapsScene
