import { lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import {
  ROUTE_ARCHIVE_TOURNAMENTS_PATH,
  ROUTE_CREATE_TOURNAMENTS_PATH,
  ROUTE_EDIT_TOURNAMENTS_PATH,
  ROUTE_TOURNAMENT_ID_PATH,
  ROUTE_TOURNAMENT_TEAMS_PATH,
  ROUTE_TOURNAMENTS_PATH,
} from 'Scenes/constants'

import RoutesFromConfig from '../../RoutesFromConfig'

const config = [
  {
    path: ROUTE_TOURNAMENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneTournament" */ './SceneTournamentList')),
  },
  {
    path: ROUTE_ARCHIVE_TOURNAMENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneTournamentsArchive')),
  },
  {
    path: ROUTE_CREATE_TOURNAMENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneCreateTournament')),
  },
  {
    path: ROUTE_EDIT_TOURNAMENTS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneEditTournament')),
  },
  {
    path: ROUTE_TOURNAMENT_TEAMS_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneCreateTournament" */ './SceneEditTournamentTeams')),
  },
  {
    path: ROUTE_TOURNAMENT_ID_PATH,
    exact: true,
    lazy: true,
    component: lazy(() => import(/* webpackChunkName: "SceneTournament" */ './SceneTournament')),
  },
]

const TournamentsScene = () => (
  <Switch>
    <RoutesFromConfig config={config} />
    <Redirect to={ROUTE_TOURNAMENTS_PATH} />
  </Switch>
)

export default TournamentsScene
