import { Avatar as MuiAvatar } from '@mui/material'
import { withStyles } from '@mui/styles'
import clsx from 'clsx'

const Styles = withStyles((theme) => ({
  micro: {
    height: 24,
    width: 24,
  },
  small: {
    height: 32,
    width: 32,
  },
  normal: {
    height: 40,
    width: 40,
  },
  medium: {
    height: 60,
    width: 60,
  },
  mobileLarge: {
    height: 96,
    width: 96,
  },
  large: {
    height: 128,
    width: 128,
  },
}))

const Avatar = ({ classes, size = 'medium', className, ...props }) => (
  <MuiAvatar
    {...props}
    className={clsx(classes[size], className)}
  />
)

export default Styles(Avatar)
