import { Tabs } from '@mui/material'
import { MapCard } from 'Components/_common/CollectionCard/MapCard'
import { PrefabCard } from 'Components/_common/CollectionCard/PrefabCard'
import { memo } from 'react'

import MoreCard from './MoreCard'

const RowCollectionMobile = ({ collection, type, moreLink, showMoreBtn }) => (
  <Tabs
    value={0}
    variant="scrollable"
    scrollButtons={false}
    indicatorColor="transparent"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      '& .MuiTabs-flexContainer': {
        gap: '24px',
      },
    }}
  >
    {collection?.map((item) => {
      switch (type) {
        case 'map':
          return (
            <MapCard
              map={item}
              key={item.id}
              style={{ width: 285, height: 192 }}
              isThin
            />
          )
        case 'prefab':
          return (
            <PrefabCard
              prefab={item}
              key={item.id}
              style={{ width: 285, height: 192 }}
              isThin
            />
          )
        default:
          return null
      }
    })}
    {showMoreBtn && (
      <MoreCard
        link={moreLink}
        type={type}
      />
    )}
  </Tabs>
)

export default memo(RowCollectionMobile)
