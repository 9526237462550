import { useAuth } from 'Components/Auth'
import { stringify } from 'query-string'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'
import { CONTENT_TYPES } from 'Scenes/constants'

import QUERY, {
  makeContentApiPath,
  makeContestApiPath,
  makePaymentsTypePath,
  makeSubscriptionApiPath,
  makeUserChangeFollowApiPath,
  makeUserContentApiPath,
  makeUserFollowApiPath,
  makeUserPaymentSettingsPath,
} from './constants'
import { PROFILE_IMAGE_TYPE } from './utils'

export const useUserQuery = (id, requestOptions = {}) => {
  const { axios } = useAuth()

  return useQuery(
    [`${QUERY.USERS}/${id}`],
    async () => {
      const { data } = await axios.get(`${QUERY.USERS}/${id}`)
      return data
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      ...requestOptions,
    },
  )
}

export const useFollowingsQuery = (options) => {
  const { axios, id } = useAuth()

  return useQuery(
    makeUserFollowApiPath(id),
    async () => {
      const { data } = await axios.get(makeUserFollowApiPath(id))
      return data.data
    },
    {
      enabled: !!id,
      ...options,
    },
  )
}

export const useFollowMutation = () => {
  const { axios, id } = useAuth()
  const queryClient = useQueryClient()

  return useMutation((followedId) => axios.post(makeUserChangeFollowApiPath(id, followedId)), {
    onMutate: async (followId) => {
      await queryClient.cancelQueries(makeUserFollowApiPath(id))

      const previousFolowings = queryClient.getQueryData(makeUserFollowApiPath(id))

      queryClient.setQueryData(makeUserFollowApiPath(id), (old) => [...old, { user_id: followId }])

      return { previousFolowings }
    },
    onError: (_err, _new, context) => {
      queryClient.setQueryData(makeUserFollowApiPath(id), context.previousFolowings)
    },
    onSettled: () => {
      queryClient.invalidateQueries(makeUserFollowApiPath(id))
      queryClient.invalidateQueries(`${QUERY.USERS}/${id}`)
    },
  })
}

export const useBlockMutation = () => {
  const { axios, id } = useAuth()

  return useMutation((blockId) => axios.post(`${QUERY.USERS}/${id}${QUERY.IGNORE}/${blockId}`))
}

export const useReportMutation = () => {
  const { axios } = useAuth()

  return useMutation(({ id, message }) => axios.post(`${QUERY.USERS}/${id}${QUERY.REPORT}`, { message }))
}

export const useUnfollowMutation = () => {
  const { axios, id } = useAuth()
  const queryClient = useQueryClient()

  return useMutation((followedId) => axios.delete(makeUserChangeFollowApiPath(id, followedId)), {
    onMutate: async (followId) => {
      await queryClient.cancelQueries(makeUserFollowApiPath(id))

      const previousFolowings = queryClient.getQueryData(makeUserFollowApiPath(id))

      queryClient.setQueryData(makeUserFollowApiPath(id), (old) =>
        old.filter(({ user_id: currentId }) => followId !== currentId),
      )

      return { previousFolowings }
    },
    onError: (_err, _new, context) => {
      queryClient.setQueryData(makeUserFollowApiPath(id), context.previousFolowings)
    },
    onSettled: () => {
      queryClient.invalidateQueries(makeUserFollowApiPath(id))
      queryClient.invalidateQueries(`${QUERY.USERS}/${id}`)
    },
  })
}

export const useUserCollectionsQuery = (params = {}) => {
  const { axios } = useAuth()
  const { id, limit, type } = params
  return useInfiniteQuery(
    [QUERY.COLLECTIONS, params],
    async ({ pageParam = 1 }) => {
      const paramsObject = {
        user_id: id,
        _limit: limit,
        type,
        _page: pageParam,
      }
      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${QUERY.COLLECTIONS}?${queryString}`)
      return { collections: data.data, content: data.relations[type], total: data.total }
    },
    {
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      refetchOnWindowFocus: false,
      staleTime: 5000,
    },
  )
}

export const useUserActivitiesQuery = (params) => {
  const { axios } = useAuth()
  const { id, limit } = params

  return useQuery(
    `${QUERY.USERS}/${id}/activities`,
    async () => {
      const paramsObject = {
        _limit: limit,
        _sort: '-time_created',
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${QUERY.USERS}/${id}/activities?${queryString}`)
      return data.data
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      staleTime: 5000,
    },
  )
}

export const useActivitiesInfiniteQuery = (params = {}) => {
  const { axios } = useAuth()
  const { id } = params

  return useInfiniteQuery(
    [QUERY.ACTIVITIES, params],
    async ({ pageParam = 1 }) => {
      const paramsObject = {
        _page: pageParam,
        _limit: 32,
        _sort: '-time_created',
        type: 'create',
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${QUERY.USERS}/${id}/activities?${queryString}`)

      return data
    },
    {
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: !!id,
    },
  )
}

export const useUserActivityQuery = (activityId) => {
  const { id, axios } = useAuth()

  return useQuery(
    `${QUERY.USERS}/${id}${QUERY.ACTIVITIES}/${activityId}`,
    async () => {
      const { data } = await axios.get(`${QUERY.USERS}/${id}${QUERY.ACTIVITIES}/${activityId}`)
      return data
    },
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useUserDraftContentQuery = (type = CONTENT_TYPES.MAPS) => {
  const { axios, id } = useAuth()
  const path = makeContentApiPath(type)

  return useInfiniteQuery(
    [`${path}/userDraft`],
    async ({ pageParam = 1 }) => {
      const paramsObject = {
        user_id: id,
        state: 'draft',
        _page: pageParam,
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${path}?${queryString}`)

      return { data: data.data }
    },
    {
      enabled: !!id,
      retry: false,
      cacheTime: 0,
      retryDelay: 2000,
      refetchOnWindowFocus: false,
      staleTime: 50000,
      retryOnMount: false,
    },
  )
}

export const useUserContentQuery = (params = {}, type = CONTENT_TYPES.MAPS, is_hidden) => {
  const { id: loggedUserId, axios } = useAuth()
  const { id, sort, state, _limit = 999 } = params
  const CONTENT_URL = type === CONTENT_TYPES.MAPS ? makeUserContentApiPath(id, type) : makeContentApiPath(type)

  return useQuery(
    [CONTENT_URL, id, sort],
    async () => {
      const paramsObject = {
        _limit,
        _sort: sort,
        state: state,
        is_hidden,
      }

      if (type !== CONTENT_TYPES.MAPS) {
        paramsObject.user_id = id
      }

      if (loggedUserId !== id) {
        paramsObject.state = 'published'
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${CONTENT_URL}?${queryString}`)

      return data.data.map((content) => ({
        ...content,
        categoryName: content.categories ? data.relations.categories[content.categories[0].category_id].name : '',
        user: data.relations.users[content.user_id],
      }))
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  )
}

export const useUserFollowersQuery = (enabled = true, id) => {
  const { axios } = useAuth()

  return useQuery(
    `${QUERY.USERS}/${id}${QUERY.FOLLOWERS}`,
    async () => {
      const { data } = await axios.get(`${QUERY.USERS}/${id}${QUERY.FOLLOWERS}`)
      return data.data.map(({ user_id }) => data.relations.users[user_id])
    },
    {
      enabled,
    },
  )
}

export const useUserFollowingQuery = (enabled = true, id) => {
  const { axios } = useAuth()

  return useQuery(
    [`${QUERY.USERS}/${id}${QUERY.FOLLOW}`, 'user'],
    async () => {
      const { data } = await axios.get(`${QUERY.USERS}/${id}${QUERY.FOLLOW}`)
      return data.data.map(({ user_id }) => data.relations.users[user_id])
    },
    {
      enabled,
    },
  )
}

export const useSearchUsersQuery = (params = {}) => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.SEARCH, params, 'users'],
    async () => {
      const { data } = await axios.get(`${QUERY.USERS}?_search=${params.search}`)
      return data.data
    },
    {
      enabled: !!params.search,
    },
  )
}

export const useUserStatisticsQuery = (id) => {
  const { axios } = useAuth()

  return useQuery(
    `${QUERY.USERS}/${id}${QUERY.STATISTICS}`,
    async () => {
      const { data } = await axios.get(`${QUERY.USERS}/${id}/${QUERY.STATISTICS}`)
      return data
    },
    {
      enabled: !!id,
    },
  )
}

export const useDeleteAccountMutation = () => {
  const { id, axios } = useAuth()

  return useMutation(() => axios.delete(`${QUERY.USERS}/${id}`))
}

export const useUpdateUserProfileMutation = () => {
  const { id, axios } = useAuth()

  return useMutation((payload) => axios.put(`${QUERY.USERS}/${id}${QUERY.PROFILES}`, payload))
}

export const useUpdateUserMutation = () => {
  const { id, axios } = useAuth()

  return useMutation((payload) => axios.put(`${QUERY.USERS}/${id}`, payload))
}

export const useUploadUserAvatarImageMutation = () => {
  const { id, axios } = useAuth()

  const queryClient = useQueryClient()

  return useMutation(
    async ({ file, type }) => {
      let formData = new FormData()

      formData.append(PROFILE_IMAGE_TYPE.AVATAR, file)

      const { data } = await axios.put(`${QUERY.USERS}/${id}${QUERY.PROFILES}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return data
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([`${QUERY.USERS}/${id}`])
      },
    },
  )
}

export const useUploadUserBackgroundImageMutation = () => {
  const { id, axios } = useAuth()

  const queryClient = useQueryClient()

  return useMutation(
    async ({ file }) => {
      let formData = new FormData()

      formData.append(PROFILE_IMAGE_TYPE.BACKGROUND, file)

      const { data } = await axios.put(`${QUERY.USERS}/${id}${QUERY.PROFILES}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return data
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([`${QUERY.USERS}/${id}`])
      },
    },
  )
}

export const useUserUnsubscribe = () => {
  const { axios } = useAuth()
  const URL = makeSubscriptionApiPath()

  return useMutation(() => axios.delete(URL))
}

export const useUserSubscribe = () => {
  const { axios } = useAuth()
  const URL = makeSubscriptionApiPath()

  return useMutation(() => axios.post(URL))
}

export const useUserPaymentsTypesQuery = () => {
  const { axios } = useAuth()
  const URL = makePaymentsTypePath()

  return useQuery(URL, async () => {
    const { data } = await axios.get(URL)
    return data
  })
}

export const useUserPaymentsSettingsQuery = (userId) => {
  const { axios } = useAuth()
  const URL = makeUserPaymentSettingsPath(userId)

  return useQuery(
    URL,
    async () => {
      const { data } = await axios.get(URL)
      return data.data
    },
    {
      enabled: !!userId,
    },
  )
}

export const useUserAddPayment = (userId, contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeUserPaymentSettingsPath(userId)
  const CONTEST_URL = contestId && makeContestApiPath(contestId)

  return useMutation((payload) => axios.post(URL, payload), {
    onSettled: () => {
      contestId && queryClient.invalidateQueries(CONTEST_URL)
    },
  })
}
