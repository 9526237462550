import { Box, Button, CircularProgress } from '@mui/material'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import { WrongIdButtons } from '../WrongIdButtons'

export const DesktopModalFooter = ({
  handleClose,
  disableCreation,
  handleCreate,
  isLoading,
  isWrongWaypointId,
  handleCreateWithUrl,
  waypointLink,
  sx,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    width="100%"
    height={'64px'}
    px={3}
    pb={3}
    sx={sx}
  >
    <Button
      variant="contained"
      onClick={handleClose}
      sx={{
        background: 'rgba(209, 233, 255, 0.05)',
        '&:hover': {
          background: 'rgba(209, 233, 255, 0.1)',
        },
      }}
    >
      Cancel
    </Button>

    {isWrongWaypointId ? (
      <WrongIdButtons
        disableCreation={disableCreation}
        handleCreateWithUrl={() => handleCreateWithUrl(waypointLink)}
        handleCreate={handleCreate}
      />
    ) : (
      <Button
        variant="contained"
        color={'primary'}
        sx={{ gap: '8px' }}
        onClick={() => handleCreateWithUrl(waypointLink)}
        disabled={disableCreation}
      >
        Create
        {isLoading ? (
          <CircularProgress
            color="inherit"
            size="16px"
          />
        ) : (
          <FAIcon iconClassName={FA_ICONS.CHECK} />
        )}
      </Button>
    )}
  </Box>
)
