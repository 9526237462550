import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { alpha } from '@mui/system'
import { useAuth, useLogout } from 'Components/Auth'
import { useUserQuery } from 'Queries/Users'
import React, { useState } from 'react'
import Avatar from 'Theme/Avatar'
import CustomMuiMenu from 'Theme/Menu'

import { PROFILE_IMAGE_TYPE } from '../../../../Queries/utils'
import {
  navigateToProfileBookmarksPage,
  navigateToProfileMapsPage,
  navigateToProfilePage,
  navigateToProfilePrefabsPage,
  navigateToProfileSettingsPage,
} from '../../../../Scenes/navigators'
import { FA_ICONS, FA_KIT_ICONS } from '../../../../Theme/font-awesome-icon-constants'
import { userImageSelector } from '../../../../utils/user-images-utils'
import { AvatarMenuItem } from './AvatarMenuItem'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '280px',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.BG4,
  },
  counter: {
    padding: theme.spacing(0.75, 1.5),
    height: '24px',
    backgroundColor: alpha('#000000', 0.25),
    borderRadius: theme.shape.borderRadius * 2.25,
  },
}))

const AvatarMenu = () => {
  const classes = useStyles()
  const { id } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const { data: user = {} } = useUserQuery(id)
  const open = Boolean(anchorEl)

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const { logout, isLoading } = useLogout(handleMenuClose)

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenProfile = () => {
    navigateToProfilePage(id)
    handleMenuClose()
  }

  const handleOpenMaps = () => {
    navigateToProfileMapsPage(id)
    handleMenuClose()
  }

  const handleOpenPrefabs = () => {
    navigateToProfilePrefabsPage(id)
    handleMenuClose()
  }

  const handleOpenBookmarks = () => {
    navigateToProfileBookmarksPage(id)
    handleMenuClose()
  }

  const handleOpenSettings = () => {
    navigateToProfileSettingsPage()
    handleMenuClose()
  }

  return (
    <>
      <IconButton
        onClick={handleAvatarClick}
        disabled={isLoading}
        size="large"
      >
        <Avatar
          size="small"
          src={userImageSelector(user.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'medium')}
        />
      </IconButton>
      <CustomMuiMenu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        keepMounted
        onClose={handleMenuClose}
        classes={{ list: classes.list }}
      >
        <AvatarMenuItem
          icon={FA_ICONS.USER_PROFILE}
          onClick={handleOpenProfile}
          title="My Profile"
        />
        <AvatarMenuItem
          icon={FA_ICONS.MAP}
          onClick={handleOpenMaps}
          title="My Maps"
          secondaryAction={
            <Box
              component={Typography}
              variant="overline"
              className={classes.counter}
            >
              {user.maps_uploaded}
            </Box>
          }
        />
        <AvatarMenuItem
          icon={FA_KIT_ICONS.PREFAB}
          onClick={handleOpenPrefabs}
          title="My Prefabs"
          secondaryAction={
            <Box
              component={Typography}
              variant="overline"
              className={classes.counter}
            >
              {user.prefabs_uploaded}
            </Box>
          }
        />
        <AvatarMenuItem
          icon={FA_ICONS.BOOKMARK}
          onClick={handleOpenBookmarks}
          title="My Bookmarks"
          secondaryAction={
            <Box
              component={Typography}
              variant="overline"
              className={classes.counter}
            >
              {user.bookmarks_count}
            </Box>
          }
        />
        <AvatarMenuItem
          icon={FA_ICONS.GEAR}
          onClick={handleOpenSettings}
          title="Settings"
        />
        <AvatarMenuItem
          icon={FA_ICONS.LOGOUT}
          onClick={logout}
          title="Logout"
          darkTheme
        />
      </CustomMuiMenu>
    </>
  )
}

export default AvatarMenu
