import { theme } from 'Theme'

export const hideScrollbarControl = {
  /* Hide scrollbar for Chrome, Safari and Opera */
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide scrollbar for IE, Edge and Firefox */
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
}

export const getStylesForStubContainer = (theme) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingRight: '40px',
  gap: '16px',
  minWidth: '340px',

  [theme.breakpoints.down('lg')]: {
    padding: '48px 24px 64px 24px',
  },
})

export const BREAKPOINT_MIN_XSM = `(min-width:${theme.breakpoints.values.xsm}px)`
export const BREAKPOINT_MIN_SM = `(min-width:${theme.breakpoints.values.sm}px)`
export const BREAKPOINT_MIN_SMD = `(min-width:${theme.breakpoints.values.smd}px)`
export const BREAKPOINT_MIN_MD = `(min-width:${theme.breakpoints.values.md}px)`
export const BREAKPOINT_MIN_LG = `(min-width:${theme.breakpoints.values.lg}px)`
export const BREAKPOINT_MIN_XL = `(min-width:${theme.breakpoints.values.xl}px)`

function isTouchEventsEnabled() {
  // Bug in FireFox+Windows 10, navigator.maxTouchPoints is incorrect when script is running inside frame.
  // TBD: report to bugzilla.
  const navigator = (window.top || window).navigator
  const maxTouchPoints = Number.isFinite(navigator.maxTouchPoints)
    ? navigator.maxTouchPoints
    : navigator.msMaxTouchPoints
  if (Number.isFinite(maxTouchPoints)) {
    // Windows 10 system reports that it supports touch, even though it acutally doesn't (ignore msMaxTouchPoints === 256).
    return maxTouchPoints > 0 && maxTouchPoints !== 256
  }
  return 'ontouchstart' in window
}

export const isDeviceHasTouch = isTouchEventsEnabled()
