function removeInvertFilterFromElement(body) {
  body.querySelectorAll('[style*="background"]').forEach((el) => {
    if ((el.style.backgroundImage || '').startsWith('url')) {
      el.style.webkitFilter = 'none'
    }
  })
}

if (typeof window !== 'undefined' && typeof window.__firefox__ === 'object') {
  const partOfStyleText = '-webkit-filter: hue-rotate(180deg) invert(100%) !important;'
  const body = document.body

  removeInvertFilterFromElement(body)

  document.documentElement.querySelectorAll('style').forEach((styleTag) => {
    const styleContent = styleTag.textContent || styleTag.innerText
    if (styleContent.includes(partOfStyleText)) {
      styleTag.parentNode?.removeChild(styleTag)
    }
  })

  const handleMutations = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((addedNode) => {
          if (addedNode instanceof HTMLElement && addedNode.tagName.toLowerCase() === 'style') {
            const styleContent = addedNode.textContent || addedNode.innerText
            if (styleContent.includes(partOfStyleText)) {
              addedNode.parentNode?.removeChild(addedNode)
              removeInvertFilterFromElement(body)
            }
          }
        })
      }
    })
  }

  const observer = new MutationObserver(handleMutations)

  observer.observe(document.documentElement, {
    childList: true,
    subtree: false,
  })
}
