import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useAuth } from 'Components/Auth'
import { INITIAL_LOCAL_STORAGE_DATA, usePersistedSocialRedirectState } from 'hooks/usePersistedData'
import { useAddExternalAccountToProfileMutation, useAuthorizeViaSocialAccountMutation } from 'Queries/Auth'
import { useContext, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  navigateToAny,
  navigateToCreateNewAccountPage,
  navigateToProfileSettingsAccountsPage,
  navigateToRootPage,
} from 'Scenes/navigators'

import { NotificationContext } from '../../Notification/context'

const redirectIfRedirectUrlExist = (redirectPathname, clearRedirect, cbRedirect) => {
  if (!!redirectPathname) {
    navigateToAny(redirectPathname)
    clearRedirect(INITIAL_LOCAL_STORAGE_DATA)
  } else {
    cbRedirect()
  }
}

const SocialLoginRedirect = () => {
  const { notification } = useContext(NotificationContext)
  const location = useLocation()
  const { id: accountId } = useParams()
  const { isAuthorized, setAuth } = useAuth()
  const [redirectPathname, setRedirectPathname] = usePersistedSocialRedirectState()
  const { mutate: addExternalAccount } = useAddExternalAccountToProfileMutation()
  const { mutate: authorizeViaExternalAccount } = useAuthorizeViaSocialAccountMutation()
  const parameters = location.search

  useEffect(() => {
    if (isAuthorized) {
      addExternalAccount(
        { accountId, parameters },
        {
          onSettled: () => {
            redirectIfRedirectUrlExist(redirectPathname, setRedirectPathname, navigateToProfileSettingsAccountsPage)
          },
        },
      )
    } else {
      authorizeViaExternalAccount(
        { accountId, parameters },
        {
          onSuccess: (data) => {
            setAuth(data.data)
            redirectIfRedirectUrlExist(redirectPathname, setRedirectPathname, navigateToRootPage)
          },
          onError: (error) => {
            notification.error(error?.response?.data?.description || 'Unknown error')
            navigateToCreateNewAccountPage()
          },
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Backdrop
      style={{ zIndex: 2 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default SocialLoginRedirect
