import { useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import ButtonViewAll from 'Components/_common/Buttons/ButtonViewAll'
import PlateContainer from 'Components/_common/Plate/PlateContainer'
import PlateItem from 'Components/_common/Plate/PlateItem'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ROUTE_CONTESTS_PATH } from 'Scenes/constants'
import Box from 'Theme/Box'

import { useContestsQuery } from '../../Queries/Contests'
import { getFlatPagesDataFromQueryData } from '../../Queries/QueryDataUtils'
import { BREAKPOINT_MIN_MD } from '../../Theme/stylesUtil'
import { ContestEventCard } from './ContestEventCard'
import { CONTEST_LIFE_STATES } from './utils'

const ContestHomeHighlight = () => {
  const { data: contestsData } = useContestsQuery({ 'state!': CONTEST_LIFE_STATES.PUBLISHED })
  const contests = useMemo(() => getFlatPagesDataFromQueryData(contestsData), [contestsData])
  const isBiggerThanMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)

  return (
    !!contests.length && (
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Link to={ROUTE_CONTESTS_PATH}>
            <Typography
              variant="h3"
              color="text.primary"
            >
              Latest Events
            </Typography>
          </Link>
          <ButtonViewAll href={ROUTE_CONTESTS_PATH} />
        </Box>

        <PlateContainer
          min="160px"
          max="500px"
          rowHeight={isBiggerThanMDScreen ? '300px' : 'inherit'}
        >
          {contests[0] && (
            <PlateItem
              x={isBiggerThanMDScreen ? 4 : 2}
              y={2}
            >
              <ContestEventCard
                event={contests[0]}
                disableStepper={!isBiggerThanMDScreen}
              />
            </PlateItem>
          )}
          {contests[1] && (
            <PlateItem
              x={2}
              y={1}
            >
              <ContestEventCard
                event={contests[1]}
                disableStepper
              />
            </PlateItem>
          )}
          {contests[2] && (
            <PlateItem
              x={2}
              y={1}
            >
              <ContestEventCard
                event={contests[2]}
                disableStepper
              />
            </PlateItem>
          )}
        </PlateContainer>
      </Box>
    )
  )
}

export default ContestHomeHighlight
