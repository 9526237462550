import ScrollRow from 'Components/_common/ScrollRow'
import { usePresetHomePage } from 'Queries/Maps'

export const HomeScrollRow = ({ title, link, contentsKey, type = 'map', showMoreBtn = false }) => {
  const { data: preset, isFetching } = usePresetHomePage()
  const collection = preset?.[contentsKey]?.data || []
  return (
    <ScrollRow
      collection={collection}
      isFetching={isFetching}
      link={link}
      title={title}
      type={type}
      showMoreBtn={showMoreBtn}
    />
  )
}
