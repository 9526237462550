import './index.css'
import './utils/brave-night-mode-ios'

import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Auth from 'Components/Auth'
import Notification from 'Components/Notification/index'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Router } from 'react-router-dom'
import { SlotProvider } from 'react-view-slot'
import { App } from 'Scenes/App'
import { theme } from 'Theme'

import packageJson from '../package.json'
import { IS_DEV_ENV } from './constants'
import historyInstance from './history-instance'

const container = document.getElementById('root')
container.dataset.appVersion = `v${packageJson.version}`
const root = createRoot(container)
const queryClient = new QueryClient()

if (IS_DEV_ENV) {
  console.log('env: ', process.env.NODE_ENV)
  console.log('version: ', packageJson.version)
  console.log('🎨', theme)
}

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SlotProvider>
        <Router history={historyInstance}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <QueryClientProvider client={queryClient}>
              <Auth>
                <Notification>
                  <App />
                </Notification>
                {IS_DEV_ENV && <ReactQueryDevtools initialIsOpen={false} />}
              </Auth>
            </QueryClientProvider>
          </LocalizationProvider>
        </Router>
      </SlotProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
)
