import { Button, Portal } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { alpha } from '@mui/system'
import { useAuth, useLogout } from 'Components/Auth'
import popperWrapperHOC from 'Components/Layout/MainSidebar/PopperWrapper'
import { SubmitButton } from 'Components/Layout/MainSidebar/SubmitMapButton'
import { useUserQuery } from 'Queries/Users'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import React from 'react'
import Avatar from 'Theme/Avatar'
import FAIcon from 'Theme/FAIcon'
import { userImageSelector } from 'utils/user-images-utils'

import {
  navigateToProfileBookmarksPage,
  navigateToProfileMapsPage,
  navigateToProfilePage,
  navigateToProfilePrefabsPage,
  navigateToProfileSettingsPage,
} from '../../../../Scenes/navigators'
import { FA_ICONS, FA_KIT_ICONS } from '../../../../Theme/font-awesome-icon-constants'
import { AvatarMenuItem } from './AvatarMenuItem'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '280px',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.BG4,
  },
  counter: {
    padding: theme.spacing(0.75, 1.5),
    height: '24px',
    backgroundColor: alpha('#000000', 0.25),
    borderRadius: theme.shape.borderRadius * 2.25,
  },
}))
const WrappedSubmitMapButton = popperWrapperHOC(SubmitButton)

export const MobileAvatarMenu = ({ handleMenuClose }) => {
  const classes = useStyles()
  const { id } = useAuth()
  const { data: user = {} } = useUserQuery(id)

  const { logout } = useLogout(handleMenuClose)

  const handleOpenProfile = () => {
    navigateToProfilePage(id)
    handleMenuClose()
  }

  const handleOpenMaps = () => {
    navigateToProfileMapsPage(id)
    handleMenuClose()
  }

  const handleOpenPrefabs = () => {
    navigateToProfilePrefabsPage(id)
    handleMenuClose()
  }

  const handleOpenBookmarks = () => {
    navigateToProfileBookmarksPage(id)
    handleMenuClose()
  }

  const handleOpenSettings = (e) => {
    e.stopPropagation()
    navigateToProfileSettingsPage()
    handleMenuClose()
  }

  return (
    <Portal
      children={
        <Box
          sx={{
            width: '100vw',
            padding: '16px',
            position: 'fixed',
            zIndex: 1200,
            bottom: 55,
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
          backgroundColor="background.BG4"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            gap={2}
            sx={(theme) => ({
              background: theme.palette.background.pale,
              borderRadius: '8px',
            })}
            onClick={handleOpenProfile}
          >
            <Box sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}>
              <Avatar
                src={userImageSelector(user?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'medium')}
                size="normal"
              />

              <Box
                display="flex"
                flexDirection="column"
              >
                <Typography
                  variant="h6"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '170px',
                  }}
                >
                  {user?.login}
                </Typography>
                <Typography
                  color="secondary.main"
                  sx={{ fontSize: '12px', lineHeight: '24px' }}
                >
                  Profile
                </Typography>
              </Box>
            </Box>
            <Button
              sx={{
                background: 'rgba(209, 233, 255, 0.05)',
                minWidth: 'unset',
                padding: '8px 16px',
                '&:hover': { background: 'rgba(209, 233, 255, 0.05)' },
              }}
              onClick={handleOpenSettings}
            >
              <FAIcon
                iconFontSize={16}
                iconClassName={FA_ICONS.GEAR}
                color={'text.secondary'}
              />
            </Button>
          </Box>
          <AvatarMenuItem
            icon={FA_ICONS.MAP}
            onClick={handleOpenMaps}
            title="Maps"
            secondaryAction={
              <Box
                component={Typography}
                variant="overline"
                className={classes.counter}
              >
                {user.maps_uploaded}
              </Box>
            }
          />
          <AvatarMenuItem
            icon={FA_KIT_ICONS.PREFAB}
            onClick={handleOpenPrefabs}
            title="Prefabs"
            secondaryAction={
              <Box
                component={Typography}
                variant="overline"
                className={classes.counter}
              >
                {user.prefabs_uploaded}
              </Box>
            }
          />
          <AvatarMenuItem
            icon={FA_ICONS.BOOKMARK}
            onClick={handleOpenBookmarks}
            title="Bookmarks"
            secondaryAction={
              <Box
                component={Typography}
                variant="overline"
                className={classes.counter}
              >
                {user.bookmarks_count}
              </Box>
            }
          />
          <AvatarMenuItem
            icon={FA_ICONS.LOGOUT}
            onClick={logout}
            title="Logout"
          />
          <WrappedSubmitMapButton isMobile />
        </Box>
      }
    />
  )
}
