import Typography from '@mui/material/Typography'
import { ReactComponent as EmptyIcon } from 'Assets/img/empty.svg'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import Box from 'Theme/Box'
import Button from 'Theme/Button'

import { navigateToRootPage } from '../../Scenes/navigators'
import FAIcon from '../../Theme/FAIcon'
import { FA_ICONS } from '../../Theme/font-awesome-icon-constants'

const defaultText = 'You’ll find lots to explore on the home page'

const NotFound = ({ error, resetErrorBoundary, title, text, isReload = true, sx = {} }) => {
  const { listen } = useHistory()

  useEffect(
    () => listen(() => !!error && !!resetErrorBoundary && resetErrorBoundary()),
    [listen, error, resetErrorBoundary],
  )

  return (
    <Box
      sx={{
        padding: '0 20px',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Typography
        variant="h1"
        color="text.primary"
        sx={{ textAlign: 'center' }}
      >
        {title ?? (error ? error.message : 'Oops Something went wrong')}
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        mt={4}
        gap={5}
      >
        <EmptyIcon />

        <Typography
          variant="body2"
          color="text.primary"
        >
          {text ?? defaultText}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '24px' }}>
          {isReload && (
            <Button
              variant="contained"
              color="inherit"
              sx={{ gap: '16px' }}
              onClick={() => window.location.reload()}
            >
              <FAIcon iconClassName={FA_ICONS.LOADER} />
              Reload
            </Button>
          )}
          <Button
            variant="contained"
            color="inherit"
            sx={{ gap: '16px' }}
            onClick={() => navigateToRootPage()}
          >
            <FAIcon
              iconClassName={FA_ICONS.HOUSE}
              color="text.tertiary"
            />
            Homepage
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default NotFound
