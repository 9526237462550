import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'

const absoluteStyles = {
  position: 'absolute',
  top: '40px',
  right: '40px',
  zIndex: 1,
}

const ButtonDialogClose = ({ isRelative = false, ...rest }) => (
  <FAIcon
    iconClassName={FA_ICONS.CLOSE}
    sx={(theme) => ({
      cursor: 'pointer',
      width: '24px',
      height: '24px',
      fontSize: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.tertiary,
      ...(isRelative ? {} : absoluteStyles),
    })}
    {...rest}
  />
)

export default ButtonDialogClose
