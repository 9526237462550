import { Box, Divider } from '@mui/material'
import { ReactComponent as EmptyIcon } from 'Assets/img/master_chief_hwp.svg'
import { ReactComponent as ArrowIcon } from 'Assets/img/Vectoricon.svg'
import ConnectedAccountCard from 'Components/ProfileComponents/ConnectedAccountCard'
import { SOCIAL_ACCOUNTS_NAME_ID, useSocialLogin } from 'Queries/Auth'
import { CONTENT_TYPES } from 'Scenes/constants'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import { PUBLISH_ITEMS } from '../utils'
import { ModalCardContainer } from './ModalCardContainer'
import { PublishContentCard } from './PublishContentCard'
import { WaypointLinkInput } from './WaypointLinkInput'

export const ModalBody = ({
  isConectedToXbox,
  handleChange,
  isIvalidLink,
  waypointLink,
  contentTab,
  isAccountsLoading,
  handleCreateWithURL,
  handleCreate,
}) => {
  const { login } = useSocialLogin()

  const handleConnect = (id) => {
    login(id)
  }

  const getCurrentStep = (step) => {
    switch (step) {
      case PUBLISH_ITEMS.MAPS:
        return (
          <ModalCardContainer
            type={CONTENT_TYPES.MAPS}
            handleCreateWithURL={handleCreateWithURL}
            handleCreate={handleCreate}
          />
        )
      case PUBLISH_ITEMS.PREFABS:
        return (
          <ModalCardContainer
            type={CONTENT_TYPES.PREFABS}
            handleCreateWithURL={handleCreateWithURL}
            handleCreate={handleCreate}
          />
        )

      case PUBLISH_ITEMS.URL:
        return (
          <Box>
            <EmptyIcon
              width="100%"
              height="200px"
              style={{ marginBottom: '24px' }}
            />
            <WaypointLinkInput
              handleChange={handleChange}
              isIvalidLink={isIvalidLink}
              waypointLink={waypointLink}
            />
          </Box>
        )

      default:
        return null
    }
  }

  return (
    <Box minHeight={contentTab !== PUBLISH_ITEMS.URL ? '365px' : '277px'}>
      {isAccountsLoading && contentTab !== PUBLISH_ITEMS.URL ? (
        <Box
          display="flex"
          flexWrap="wrap"
          gap={1}
          p={3}
        >
          {[...Array(4).keys()].map((value) => (
            <Box
              key={value}
              sx={{
                height: '110px',
                width: 'calc(25% - 8px)',
                background: '#00000026',
                borderRadius: '8px',
              }}
            />
          ))}
        </Box>
      ) : (
        <Box
          gap={3}
          display="flex"
          flexDirection="column"
          px={isConectedToXbox || contentTab === PUBLISH_ITEMS.URL ? 3 : 4}
        >
          {!isConectedToXbox && contentTab !== PUBLISH_ITEMS.URL ? (
            <>
              <ConnectedAccountCard
                bgColor="#D1E9FF0D"
                id={SOCIAL_ACCOUNTS_NAME_ID.LIVE}
                iconClass={FA_ICONS.XBOX}
                name="Xbox Live"
                value="Connect your Xbox account to publish your content from HaloWaypoint"
                connected={isConectedToXbox}
                onConnect={handleConnect}
              />
              <Box
                display="flex"
                gap={2}
              >
                <PublishContentCard
                  contentType="map"
                  handleClick={() => handleCreate(CONTENT_TYPES.MAPS)}
                />
                <PublishContentCard
                  contentType="prefab"
                  handleClick={() => handleCreate(CONTENT_TYPES.PREFABS)}
                />
                {[...Array(2).keys()].map((value) => (
                  <Box
                    key={value}
                    sx={{
                      height: '110px',
                      width: 'calc(25% - 8px)',
                      background: '#00000026',
                      borderRadius: '8px',
                    }}
                  />
                ))}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Divider
                  orientation="horizontal"
                  sx={(theme) => ({
                    width: 'calc(50% - 30px)',
                    height: '4px',
                    background: theme.palette.background.pale,
                    border: 'none',
                  })}
                />
                <ArrowIcon />

                <Divider
                  orientation="horizontal"
                  sx={(theme) => ({
                    width: 'calc(50% - 30px)',
                    height: '4px',
                    background: theme.palette.background.pale,
                    border: 'none',
                  })}
                />
              </Box>
              <WaypointLinkInput
                handleChange={handleChange}
                isIvalidLink={isIvalidLink}
                waypointLink={waypointLink}
              />
            </>
          ) : (
            getCurrentStep(contentTab)
          )}
        </Box>
      )}
    </Box>
  )
}
