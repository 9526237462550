import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Else, If, Then } from 'react-if'

import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'

const PopItem = ({ open, anchorEl, popupText, isMain, handleDrawerToggle, handleClickToggle, ...otherProps }) => {
  const [hover, setHover] = useState(false)
  const colorOnHover = hover ? 'rgba(77, 81, 93, 1)' : 'rgba(46, 48, 54, 1)'

  const handleOpenHoverAndDrawer = (e) => {
    if (isMain) {
      handleDrawerToggle(e)
      setHover(true)
    }
  }
  const handleCloseHoverAndDrawer = (e) => {
    if (isMain) {
      handleDrawerToggle(e)
      setHover(false)
    }
  }
  if (!anchorEl) return
  return (
    <Popper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorOnHover,
        color: '#fff',
        borderRadius: '6px',
        zIndex: 1300,
        transition: 'background-color 0.2s ease-in-out',
        cursor: 'pointer',
        visibility: isMain && !open ? 'hidden' : 'visible',
      }}
      open={isMain ? isMain : open}
      anchorEl={anchorEl}
      placement={isMain ? 'right-start' : 'right'}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: isMain ? [25, -10] : [0, 0],
          },
        },
      ]}
      onMouseOver={handleOpenHoverAndDrawer}
      onMouseLeave={handleCloseHoverAndDrawer}
      {...otherProps}
    >
      <If condition={isMain}>
        <Then>
          <Box onClick={handleClickToggle}>
            <FAIcon
              iconClassName={FA_ICONS.CHEVRON_RIGHT}
              iconFontSize={16}
            />
          </Box>
        </Then>
        <Else>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '-4px',
              borderLeft: '4px solid rgba(54, 56, 63, 1)',
              borderTop: '4px solid transparent',
              borderBottom: '4px solid transparent',
              transform: 'translateY(-50%) rotate(180deg)',
              height: '10px',
            }}
          />
          <Typography
            variant="caption"
            color="text.tertiary"
            sx={{ padding: '4px 12px' }}
          >
            {popupText}
          </Typography>
        </Else>
      </If>
    </Popper>
  )
}

export default PopItem
