import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { When } from 'react-if'
import { AWARD_BUDGES } from 'utils/common-utils'

import { ReactComponent as ContestWinner } from '../../Assets/img/contest.svg'
import { ReactComponent as Featured } from '../../Assets/img/featured.svg'
import { ReactComponent as Liked } from '../../Assets/img/liked.svg'
import { ReactComponent as Matchmaking } from '../../Assets/img/matchmaking.svg'
import { ReactComponent as Reccomended } from '../../Assets/img/reccomended.svg'
import { ReactComponent as Trending } from '../../Assets/img/trending.svg'
import TrophyIcon from './TrophyIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    margin: '0 6px',

    '&::before': {
      content: "''",
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      height: '1px',
      width: '51%',
      transform: 'skew(0deg, -20deg)',
    },

    '&::after': {
      content: "''",
      position: 'absolute',
      bottom: '-2px',
      right: 0,
      height: '1px',
      width: '50%',
      transform: 'skew(0deg, 20deg)',
    },
  },
  body: {
    position: 'relative',
    padding: theme.spacing(1, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  icon: {},
  yellow: {
    borderTopColor: '#FF9D00',

    '&:before, &:after': {
      background: '#FFD85C',
    },

    '& $icon': {
      color: '#FFFBD7',
      // filter: `drop-shadow(0px 0px 2px rgba(255, 158, 1, 0.65))
      //   drop-shadow(0px 0px 12px #FF9E01)
      //   drop-shadow(0px 0px 8px #FF9E01)
      //   drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12))`,
    },
  },
  green: {
    borderTopColor: '#3CC48E',

    '&:before, &:after': {
      background: '#B6ED7C',
    },

    '& $icon': {
      color: '#DAFFF0',
      // filter: `drop-shadow(0px 0px 15px #3EC48D)
      //   drop-shadow(0px 0px 8px #429A8B)
      //   drop-shadow(0px 0px 26px #3EC48D)
      //   drop-shadow(0px 0px 24px #46C288)
      //   drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))`,
    },
  },
  red: {
    borderTopColor: '#EF3E88',

    '&:before, &:after': {
      background: '#EF7743',
    },

    '& $icon': {
      color: '#FFE1ED',
      // filter: `drop-shadow(0px 0px 8px #F2497B)
      //   drop-shadow(0px 0px 12px #FF4989)
      //   drop-shadow(0px 0px 12px #F04088)
      //   drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08))`,
    },
  },
  pink: {
    borderTopColor: '#F25FFF',

    '&:before, &:after': {
      background: '#FFBAFC',
    },

    '& $icon': {
      color: '#FFBAFC',
      // filter: `drop-shadow(0px 0px 15px #FF2FF7)
      //   drop-shadow(0px 0px 8px #FF2FF7)
      //   drop-shadow(0px 0px 26px #FF2FF7)
      //   drop-shadow(0px 0px 24px #FF2FF7)
      //   drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))`,
    },
  },
  amber: {
    borderTopColor: '#EFB178',

    '&:before, &:after': {
      background: '#FFDBA5',
    },

    '& $icon': {
      color: '#FFFBD7',
      // filter: `drop-shadow(0px 0px 2px rgba(255, 79, 3, 0.65))
      //   drop-shadow(0px 0px 24px #FF4F03)
      //   drop-shadow(0px 0px 16px #FF4F03)
      //   drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))`,
    },
  },
  blue: {
    border: 'none',

    '&:before, &:after': {
      background: 'none',
    },

    '& $icon': {
      color: '#B6EFFF',
      // filter: `drop-shadow(0px 0px 2px rgba(1, 163, 210, 0.65))
      //   drop-shadow(0px 0px 24px #01A3D2)
      //   drop-shadow(0px 0px 16px #01A3D2)
      //   drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))`,
    },
  },
  map: {
    color: '#FFFFFF',
    // filter: `drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5))
    //   drop-shadow(0px 0px 9px rgba(255, 255, 255, 0.7))
    //   drop-shadow(0px 0px 16px #01A3D2)
    //   drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))`,
  },
}))

const states = [
  // TODO waiting for new icon from design
  // { name: AWARD_BUDGES.FAVORITE, color: 'green', icon: FA_ICONS.FILLED_STAR },
  { name: AWARD_BUDGES.LIKED, color: 'red', icon: <Liked /> },
  { name: AWARD_BUDGES.FEATURED, color: 'green', icon: <Featured /> },
  { name: AWARD_BUDGES.MATCHMAKING, color: 'pink', icon: <Matchmaking /> },
  { name: AWARD_BUDGES.TRENDING, color: 'amber', icon: <Trending /> },
  { name: AWARD_BUDGES.RECOMMENDED, color: 'blue', icon: <Reccomended /> },
  { name: AWARD_BUDGES.CONTEST_WINNER, color: 'blue', icon: <ContestWinner /> },
]

const AwardBadge = ({ settings, containerStyles, isShowCommunityIcon = false, sx }) => {
  const classes = useStyles()

  return (
    <Box sx={containerStyles}>
      {states.map((item) => (
        <When
          condition={settings[item.name]}
          key={item.name}
        >
          <Box
            className={`${classes.root}`}
            sx={sx}
          >
            {item.icon}
          </Box>
        </When>
      ))}
      <When condition={!!settings?.community_favorite_place && isShowCommunityIcon}>
        <Box className={`${classes.root}`}>
          <TrophyIcon
            place={'liked'}
            number={settings?.community_favorite_place}
            withNumberInGoblet
          />
        </Box>
      </When>
    </Box>
  )
}

export default AwardBadge
