import AlertMUI from '@mui/material/Alert'
import React from 'react'

const Alert = React.forwardRef(({ onClose, severity = 'success', children, ...props }, ref) => (
  <AlertMUI
    onClose={onClose}
    severity={severity}
    ref={ref}
    {...props}
  >
    {children}
  </AlertMUI>
))

export default Alert
