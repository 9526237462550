import CustomSnackbar from 'Components/_common/CustomSnackbar'
import React, { useMemo, useState } from 'react'

import { NotificationContext } from './context'

const methods = ['error', 'success', 'warning', 'info']

const Notification = ({ children }) => {
  const [alert, setAlert] = useState({})

  const notification = useMemo(
    () =>
      methods.reduce((acc, method) => {
        acc[method] = (data, title, onDelete, onUndo) => {
          setAlert({ message: data, severity: method, title: title, onDelete: onDelete, onUndo: onUndo })
        }

        return acc
      }, {}),
    [],
  )

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert({})
  }

  return (
    <NotificationContext.Provider
      value={{
        notification,
      }}
    >
      {children}
      {!!alert.message && (
        <CustomSnackbar
          open={!!alert.message}
          onClose={handleClose}
          severity={alert.severity}
          text={alert.message}
          title={alert.title}
          onDelete={alert.onDelete}
          onUndo={alert.onUndo}
        />
      )}
    </NotificationContext.Provider>
  )
}

export default Notification
