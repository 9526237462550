import { Box, useMediaQuery } from '@mui/material'
import { useShowLeaveUser } from 'hooks/useShowLeaveUser'
import React, { useEffect, useState } from 'react'
import { Else, If, Then, When } from 'react-if'
import { useLocation } from 'react-router-dom'

import { ROUTE_CREATE_NEW_ACCOUNT_PATH, ROUTE_SEARCH_PATH } from '../../../Scenes/constants'
import { BREAKPOINT_MIN_MD, hideScrollbarControl } from '../../../Theme/stylesUtil'
import SignUpDrawer from '../../Auth/SignUpDrawer'
import MainSidebar from '../MainSidebar'
import { LeaveUser } from './LeaveUser'
import { SearchContext } from './SearchContext'
import { BackgroundSlot } from './slots'

const MainLayout = ({ children }) => {
  const [search, setSearch] = useState('')
  const location = useLocation()
  const isBiggerThanMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)
  const showLeaveUserBlock = useShowLeaveUser()
  const withShowLeaveUserBlock = showLeaveUserBlock && isBiggerThanMDScreen
  const shiftHeight = withShowLeaveUserBlock ? '64px' : '0px'


  useEffect(() => {
    location.pathname !== ROUTE_SEARCH_PATH && setSearch('')
    const element = document.getElementById('scroll-layout')
    element.scrollIntoView({
      behavior: 'smooth',
    })
  }, [location.pathname])

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
      })}
    >
      {withShowLeaveUserBlock && <LeaveUser height={shiftHeight} />}

      <Box display="flex">
        <If condition={!location.pathname.includes(ROUTE_CREATE_NEW_ACCOUNT_PATH)}>
          <Then>
            <MainSidebar />
          </Then>
          <Else>
            <When condition={isBiggerThanMDScreen}>
              <SignUpDrawer />
            </When>
          </Else>
        </If>

        <SearchContext.Provider value={{ search, setSearch }}>
          <Box
            id="layout"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1',
              overflowX: 'hidden',
              maxHeight: `calc(100vh - ${shiftHeight})`,
              '& > *': {
                zIndex: '2',
              },
              ...hideScrollbarControl,
            }}
          >
            <BackgroundSlot />

            <Box
              id="scroll-layout"
              component={'main'}
              sx={{ flexGrow: 1 }}
            >
              {children}
            </Box>
          </Box>
        </SearchContext.Provider>
      </Box>
    </Box>
  )
}

export * from './slots'
export default MainLayout
