import { Button, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ReactComponent as EmptyIcon } from 'Assets/img/empty.svg'
import { ReactComponent as WaypointLogo } from 'Assets/img/WaypointLogo.svg'
import ScrollContainer from 'Components/_common/ScrollContainer'
import AccauntCardContainer from 'Components/ProfileComponents/AccauntCardContainer'
import { useImportContent, useInfoTime } from 'Queries/Common'
import { memo, useCallback, useEffect, useState } from 'react'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import { generateContentUrl } from '../utils'
import { ContentCard } from './ContentCard'
import { PublishContentCard } from './PublishContentCard'

export const ModalCardContainer = memo(({ type, handleCreateWithURL, handleCreate }) => {
  const { data: collection = [], refetch, isFetching } = useImportContent(type)
  const { data, refetch: refetchTimer } = useInfoTime()

  const secondsToNextRefresh = Math.round(600 - ((+new Date(data?.currentTime || 0) / 1000 - 60) % 600))

  const [seconds, setSeconds] = useState(data?.currentTime ? secondsToNextRefresh : 0)

  const handleTimerEnd = useCallback(() => {
    refetch()
    refetchTimer()
    setSeconds(secondsToNextRefresh)
  }, [refetch, refetchTimer, secondsToNextRefresh])

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          handleTimerEnd()
          return prevSeconds
        }
        return prevSeconds - 1
      })
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [data, collection, handleTimerEnd])

  const handleCardClick = (halowaypoint_id) => () => {
    handleCreateWithURL(generateContentUrl(type, halowaypoint_id))
  }

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  return (
    <>
      <AccauntCardContainer
        bgColor="#D1E9FF0D"
        name="Halo Waypoint"
        value="If your map or prefab isn\'t visible, ensure it\'s published on HaloWaypoint first"
        descriptionTimer="Halo Waypoint maps will be synced with ForgeHub in "
        timer={formatTime()}
        icon={<WaypointLogo />}
        control={
          <Button
            onClick={() => refetch()}
            sx={(theme) => ({
              background: theme.palette.background.pale,
              padding: '8px 16px',
              minWidth: 'unset',
              width: 'min-content',
              '&:hover': { background: theme.palette.background.pale },
            })}
          >
            <FAIcon
              iconClassName={FA_ICONS.ROTATE_RIGHT}
              color="text.secondary"
            />
          </Button>
        }
      />
      <ScrollContainer
        display="flex"
        flexWrap="wrap"
        gap={1}
        maxHeight="350px"
        mb={3}
      >
        {!isFetching
          ? collection.map((item) => (
              <ContentCard
                key={item.halowaypoint_id}
                image={item.image}
                title={item.title}
                handleClick={handleCardClick(item.halowaypoint_id)}
              />
            ))
          : [...Array(4).keys()].map((value) => (
              <Skeleton
                key={value}
                height={110}
                width={'calc(25% - 8px)'}
                variant="primary"
                sx={{
                  borderRadius: '8px',
                  background: '#00000026',
                }}
              />
            ))}
        {!isFetching && (
          <PublishContentCard
            contentType={type.slice(0, -1)}
            handleClick={() => handleCreate(type)}
          />
        )}
        {!collection.length && !isFetching && (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            width="100%"
            mb={4}
            gap={1}
          >
            <EmptyIcon />
            <Typography
              variant="body2"
              color="text.tertiary"
            >
              Looks like you don't have any content published on the Xbox
            </Typography>
          </Box>
        )}
      </ScrollContainer>
    </>
  )
})
