import { createTheme } from '@mui/material/styles'

// TODO Cut
const BG1 = '#191A1E'
const BG2 = '#202126'
const BG3 = '#25272D'
const BG4 = '#2E3036'
const BG5 = '#36383F'
const BG6 = '#42454E'
const BG7 = '#4D515D'
const BG9 = '#B5B5BE'
const BG10 = '#FAFAFB'

const secondary = '#01A3D2'
const tertiary = '#9298AA'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 440,
      sm: 640,
      smd: 768,
      md: 960,
      lg: 1200,
      xl: 1350,
      xxl: 1536,
    },
  },

  palette: {
    mode: 'dark',

    // Standart colors
    primary: {
      light: '#00C692',
      main: '#00AE70',
      dark: '#00964E',
      active: '#00AE701A',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#36d6f2',
      main: secondary,
      dark: '#0187C4',
      contrastText: '#ffffff',
    },
    background: {
      default: '#191A1E',
      paper: '#25272D',
      dark: '#202126',
      pale: '#D1E9FF0D',
      // TODO REMOVE!!!
      BG1,
      BG2,
      BG3,
      BG4,
      BG5,
      BG6,
      BG7,
      tertiary,
      BG9,
      BG10,
    },
    text: {
      primary: '#FAFAFB',
      secondary: '#CBD1E2',
      tertiary,
      disabled: 'rgba(146, 152, 170, 0.5)',
      hint: '#92929D',
    },
    success: {
      // TODO
      // ...augmentColor({ main: '#72B52B' }),
      contrastText: '#fff',
      main: '#72B52B',
    },
    warning: {
      // TODO
      // ...augmentColor({ main: '#F3982C' }),
      contrastText: '#fff',
      main: '#F3982C',
    },
    error: {
      dark: '#FB3F3F',
      main: '#FC5A5A',
      light: '#FD7575',
      active: '#FC5A5A0D',
      contrastText: '#fff',
    },
    action: {
      active: '#B5B5BE',
      hover: '#343842',
      selected: '#343842',
      disabledOpacity: 0.5,
      disabled: tertiary,
    },
    purple: {
      // TODO
      // ...augmentColor({ main: '#A461D8' }),
      contrastText: '#fff',
      main: '#A461D8',
    },
    green: {
      // TODO
      // ...augmentColor({ main: '#3DD598' }),
      contrastText: '#fff',
      main: '#3DD598',
    },
    red: {
      // TODO
      // ...augmentColor({ main: '#FC5A5A' }),
      contrastText: '#fff',
      main: '#FC5A5A',
    },
    dark: {
      // TODO
      // ...augmentColor({ main: '#0F0F14', secondary: '#24252A' }),
      contrastText: '#FAFAFB',
      main: '#0F0F14',
    },
    white: {
      // TODO
      // ...augmentColor({ main: '#FFF' }),
      contrastText: '#0F0F14',
      main: '#FFF',
    },
    grey: {
      // TODO
      // ...augmentColor({ main: '#42454e', secondary: '#DFE3E9' }),
      contrastText: '#FAFAFB',
      main: '#42454e',
    },
  },

  typography: {
    fontSize: 16,
    fontFamily: ['Poppins', 'Nunito'].join(','),
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 36,
      lineHeight: '48px',
      fontWeight: 600,
    },
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 28,
      lineHeight: '32px',
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 600,
    },
    h4: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 600,
    },
    h5: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
    },
    h6: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 500,
    },
    body1: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 18,
      lineHeight: '32px',
      fontWeight: 400,
    },
    body2: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2bold: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 800,
    },
    button: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 600,
      textTransform: 'none',
    },
    caption: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      lineHeight: '24px',
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    subtitle1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
    },
    overline: {
      fontFamily: "'Nunito', sans-serif",
      fontSize: 10,
      lineHeight: '12px',
      fontWeight: 800,
    },
  },

  shape: {
    borderRadius: 8,
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          backgroundColor: '#191A1E',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'block',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          color: tertiary,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          transition: 'all 0.2s ease',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
          '&.Mui-focused': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          borderRadius: '6px',
          '& input': {
            borderRadius: '6px',
            transition: 'all 0.2s ease',
            '&::placeholder': {
              fontFamily: "'Nunito', sans-serif",
              fontSize: 14,
              lineHeight: '24px',
              fontWeight: 400,
              color: tertiary,
              opacity: 1,
            },
          },
          '& fieldset': {
            border: 'none',
            transition: 'all 0.2s ease',
          },
          '&.Mui-focused': {
            '& fieldset': {
              '&.MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${secondary}`,
              },
            },
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '24px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'initial',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
})

export default theme
