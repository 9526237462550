import { Box, Typography } from '@mui/material'
import DefaultImage1 from 'Assets/img/HomePlaceholderBg1.png'
import DefaultImage2 from 'Assets/img/HomePlaceholderBg2.png'
import DefaultImage3 from 'Assets/img/HomePlaceholderBg3.png'
import { get, has } from 'lodash-es'
import React, { useMemo } from 'react'

const BACKGROUNDS = {
  Featured: DefaultImage1,
  Infection: DefaultImage1,
  'Halo Remakes': DefaultImage2,
  'Mini Games': DefaultImage3,
}

export const ComingSoon = ({ title }) => {
  const image = useMemo(() => {
    if (has(BACKGROUNDS, title)) {
      return get(BACKGROUNDS, title)
    } else {
      return DefaultImage1
    }
  }, [title])

  return (
    <Box
      position="relative"
      width="100%"
      height="192px"
      display="flex"
      justifyContent="flex-end"
      flexDirection="column"
      p={2}
    >
      <img
        loading="lazy"
        src={image}
        alt="Map as background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
          borderRadius: '8px',
          zIndex: -1,
          width: '100%',
          height: '100%',
        }}
      />
      <Typography
        variant="h3"
        style={{ color: 'rgba(255, 255, 255, 0.1)' }}
      >
        Coming soon
      </Typography>
    </Box>
  )
}
