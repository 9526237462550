import Typography from '@mui/material/Typography'
import React from 'react'

import CommFavoriteImage from '../../../Assets/img/backgroundImage.jpg'
import { COMMUNITY_FAVORITE_LIFE_STATES, useCurrentCommunityFavoriteQuery } from '../../../Queries/CommunityFavorites'
import { navigateToCommunityFavoriteVotePage, navigateToLoginModal } from '../../../Scenes/navigators'
import Box from '../../../Theme/Box'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import { NavButton } from './NavButton'

const CommunityVoteButton = ({ isAuthorized, isOpen, onMouseEnter, onMouseLeave, isTablet }) => {
  const { data: communityFavorites, isFetched: isFetchedCommFavorites } = useCurrentCommunityFavoriteQuery()
  const isCommVoteDone = isFetchedCommFavorites && communityFavorites?.state !== COMMUNITY_FAVORITE_LIFE_STATES.VOTE
  const isCommVoteActive =
    !!communityFavorites?.vote_time_left && communityFavorites?.vote_time_left > 0 && !isCommVoteDone

  const handleVoteClick = () => {
    if (!isAuthorized) {
      navigateToLoginModal()
    } else {
      navigateToCommunityFavoriteVotePage()
    }
  }

  const handleMouseEnter = (e) => {
    if (isTablet) return
    onMouseEnter(e, 'Community Favorite')
  }
  const handleMouseLeave = () => {
    if (isTablet) return
    onMouseLeave()
  }

  if (!isCommVoteActive) return
  return (
    <Box
      sx={(theme) => ({
        ...(!isOpen
          ? {
              marginX: 3,
              padding: '8px',
              backgroundColor: theme.palette.background.BG4,
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }
          : {
              paddingX: 1.625,
            }),
        borderRadius: '16px',
      })}
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={handleMouseLeave}
    >
      {!isOpen && (
        <div style={{ position: 'relative' }}>
          <img
            src={CommFavoriteImage}
            alt="background"
            style={{ width: '100%', filter: 'brightness(50%)', borderRadius: '8px' }}
          />
          <Typography
            variant="h6"
            sx={{ position: 'absolute', left: 8, bottom: 16, zIndex: 1 }}
          >
            Community Favorite
          </Typography>
        </div>
      )}
      <NavButton
        isOpen={!isOpen}
        btnText="Vote now!"
        btnColor="primary"
        btnIcon={FA_ICONS.HEART}
        onClick={handleVoteClick}
      />
    </Box>
  )
}

export default CommunityVoteButton
