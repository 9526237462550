import { stringify } from 'query-string'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useAuth } from '../Components/Auth'
import {
  makeArticleIdApiPath,
  makeArticleIdCommentReplyApiPath,
  makeArticleIdCommentsApiPath,
  makeArticleImageIdApiPath,
  makeNewsApiPath,
} from './constants'

export const useBlogNewsQuery = () => {
  const { axios } = useAuth()
  const URL = makeNewsApiPath()
  const queryObject = {
    _sort: '-time_published',
  }
  const queryString = stringify(queryObject, { skipNull: true })

  return useQuery([URL], async () => {
    const { data } = await axios.get(`${URL}?${queryString}`)

    return {
      data: data.data.map((article) => ({
        ...article,
        user: data.relations.users[article.user_id],
      })),
    }
  })
}

export const useBlogArticleIdQuery = (articleId, enabled = true) => {
  const { axios } = useAuth()
  const URL = makeArticleIdApiPath(articleId)
  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)
      return data
    },
    {
      staleTime: Infinity,
    },
  )
}

export const useAddArticleCommentMutation = (articleId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeArticleIdCommentsApiPath(articleId)

  return useMutation((payload) => axios.post(URL, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([URL])
    },
  })
}

export const useAddArticleCommentReplyMutation = (articleId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeArticleIdCommentReplyApiPath(articleId, commentId)

  return useMutation((payload) => axios.post(URL, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([URL])
    },
  })
}

export const useEditArticleCommentMutation = (articleId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeArticleIdCommentReplyApiPath(articleId, commentId)

  return useMutation((payload) => axios.put(URL, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([URL])
    },
  })
}

export const useDeleteArticleCommentMutation = (articleId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeArticleIdCommentReplyApiPath(articleId, commentId)

  return useMutation(() => axios.delete(URL), {
    onSettled: () => {
      queryClient.invalidateQueries([URL])
    },
  })
}

export const useReactOnArticleCommentMutation = (id, commentId, contentType) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeArticleIdCommentReplyApiPath(id, contentType)

  return useMutation(({ type }) => axios.post(`${path}/${commentId}/likes/${type}`), {
    onSuccess: (_) => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!id,
  })
}

export const useDeleteArticleCommentReactionMutation = (id, commentId, contentType) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeArticleIdCommentReplyApiPath(id, contentType)

  return useMutation(({ type }) => axios.delete(`${path}/${commentId}/likes/${type}`), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!id,
  })
}

export const useChangeArticleDataMutation = (articleId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeArticleIdApiPath(articleId)

  return useMutation(
    (payload) =>
      axios.put(URL, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries([URL])
      },
    },
  )
}

export const useDeleteArticleMutation = (articleId) => {
  const { axios } = useAuth()
  const URL = makeArticleIdApiPath(articleId)

  return useMutation(() => axios.delete(URL))
}

export const useDeleteArticleImageMutation = (articleId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const ARTICLE_URL = makeArticleIdApiPath(articleId)

  return useMutation(({ imageId }) => axios.delete(makeArticleImageIdApiPath(articleId, imageId)), {
    onSettled: () => {
      queryClient.invalidateQueries(ARTICLE_URL)
    },
  })
}

export const useCreateArticleMutation = () => {
  const { axios } = useAuth()
  const URL = makeNewsApiPath()

  return useMutation((payload) =>
    axios.post(URL, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  )
}

export const useBlogLastArticleQuery = () => {
  const { axios } = useAuth()
  const URL = makeNewsApiPath()
  const queryObject = {
    _limit: 1,
    _sort: '-time_created',
  }
  return useQuery([URL], async () => {
    const { data } = await axios.get(URL, { params: { ...queryObject } })
    return data.data
  })
}

export const useBlogScheduledArticleQuery = () => {
  const { axios } = useAuth()
  const formattedDate = new Date().toISOString().slice(0, 19)
  const URL = `${makeNewsApiPath()}?time_published=all&_from_time_published=${formattedDate}`
  return useQuery([URL], async () => {
    const { data } = await axios.get(URL)

    return {
      data: data.data.map((article) => ({
        ...article,
        user: data.relations.users[article.user_id],
      })),
    }
  })
}
