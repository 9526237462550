import CollectionCard from 'Components/_common/CollectionCard/CollectiionCard'
import { useDeleteContentMutation } from 'Queries/Common'
import { useCategoryFFAOnly, useCategoryName, usePlayersCountName, usePrimaryGameTypeName } from 'Queries/Dictionaries'
import { navigateToMapPage } from 'Scenes/navigators'
import { checkAwardBudge } from 'utils/common-utils'

import CollectionCardThin from './CollectionCardThin'
import { getMapImageBySize } from './utils'

export const MapCard = ({
  map,
  onDelete,
  imageSize = 'medium',
  isThin = false,
  communityFavItem,
  isShowCommunityIcon,
  ...otherProps
}) => {
  const { mutateAsync: deleteMap, isLoading } = useDeleteContentMutation(map.id)

  const playersCountName = usePlayersCountName(map.players_count_id)
  const gameTypeName = usePrimaryGameTypeName(map.primary_game_type_id)
  const categoryName = useCategoryName(map.categories ? map.categories[0]?.category_id : map.category_id)
  const showGameCategory = useCategoryFFAOnly(map.category_id)

  const hasAward = checkAwardBudge(map)
  const image = getMapImageBySize(map, imageSize)
  const link = navigateToMapPage(map?.id, true)

  const isHaloWaypointUser = !!map.is_soft_published && !map.user_id

  const handleDelete = (event) => {
    event.preventDefault()
    deleteMap().then(() => {
      onDelete && onDelete()
    })
  }

  return !isThin ? (
    <CollectionCard
      playersCountName={playersCountName}
      categoryName={gameTypeName}
      showGameCategory={showGameCategory}
      link={link}
      onDelete={handleDelete}
      isLoading={isLoading}
      collectionItem={map}
      image={image}
      isHaloWaypointUser={isHaloWaypointUser}
      communityFavItem={communityFavItem}
      isShowCommunityIcon={isShowCommunityIcon}
      {...otherProps}
    />
  ) : (
    <CollectionCardThin
      categoryName={categoryName}
      collection={map}
      hasAward={hasAward}
      image={image}
      link={link}
      playersCountName={playersCountName}
      showGameCategory={showGameCategory}
      isHaloWaypointUser={isHaloWaypointUser}
      {...otherProps}
    />
  )
}
