import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

const FAIcon = ({ iconClassName, iconFontSize, boxSize = 24, color = 'inherit', ...rest }) => {
  const resolvedBoxSize = useMemo(() => {
    if (Number(boxSize) && Number(iconFontSize)) {
      return iconFontSize > boxSize ? iconFontSize : boxSize
    }
    return boxSize
  }, [boxSize, iconFontSize])

  return (
    <Box
      key={iconClassName}
      component="span"
      sx={{
        width: resolvedBoxSize,
        height: resolvedBoxSize,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: color,
        '& svg': {
          maxWidth: '100%',
          maxHeight: '100%',
          fontSize: iconFontSize ? iconFontSize : 'inherit',
        },
      }}
      {...rest}
    >
      <i className={iconClassName} />
    </Box>
  )
}

FAIcon.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  iconFontSize: PropTypes.number,
  boxSize: PropTypes.number,
  color: PropTypes.string,
}

export default FAIcon
