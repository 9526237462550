import { ClickAwayListener } from '@mui/base'
import { Box, Button, Skeleton } from '@mui/material'
import { MobileAvatarMenu } from 'Components/Layout/MainHeaderControls/AvatarMenu/ MobileAvatarMenu'
import { useUserQuery } from 'Queries/Users'
import { PROFILE_IMAGE_TYPE } from 'Queries/utils'
import { useCallback, useState } from 'react'
import { Else, If, Then } from 'react-if'
import { navigateToLoginModal } from 'Scenes/navigators'
import Avatar from 'Theme/Avatar'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'
import { userImageSelector } from 'utils/user-images-utils'

export const UserAvatar = ({ id, isSelected }) => {
  const { data: user = {}, isFetched } = useUserQuery(id)
  const [isOpened, setIsOpened] = useState(false)

  const handleClose = useCallback(() => {
    setIsOpened(false)
  }, [])

  return (
    <If condition={id}>
      <Then>
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width="36px"
            height="36px"
            border={isSelected ? '2px solid white' : 'none'}
          >
            <If condition={!isFetched}>
              <Then>
                <Skeleton
                  variant="circular"
                  sx={{ width: '32px', height: '32px' }}
                />
              </Then>
              <Else>
                <Button
                  variant="label"
                  onClick={() => setIsOpened((prev) => !prev)}
                >
                  <Avatar
                    size="small"
                    src={user.user_images && userImageSelector(user.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'small')}
                  />
                </Button>
              </Else>
            </If>
            {isOpened && <MobileAvatarMenu handleMenuClose={handleClose} />}
          </Box>
        </ClickAwayListener>
      </Then>
      <Else>
        <Button
          variant="contained"
          color="secondary"
          onClick={navigateToLoginModal}
        >
          <FAIcon iconClassName={FA_ICONS.USER_PLUS} />
        </Button>
      </Else>
    </If>
  )
}
