export const SRC_FORGE_HUB_DISCORD_BANNER = 'https://discord.com/api/guilds/152117181927260161/widget.png?style=banner2'

export const HREF_FORGE_HUB_DISCORD = 'https://discord.gg/ybGX7sv'
export const HREF_FORGE_HUB_FORUM = 'https://www.forgehub.com/forums/'
export const HREF_FORGE_HUB_YOUTUBE = 'https://youtube.com/c/forgehub'
export const HREF_FORGE_HUB_TWITTER = 'https://twitter.com/forgehub'

// It should be an internal route
export const HREF_FORGE_HUB_WIKI = 'https://www.forgehub.com/wiki/index/'

export const HREF_GOOGLE = 'https://gmail.com'

export const DOWNLOAD_ENTITY_ERROR = 'https://www.halowaypoint.com/404'
