import { Redirect } from 'react-router-dom'
import { ROUTE_EVENTS_PATH } from 'Scenes/constants'

const DeprecatedContestsScene = (props) => {
  const { history } = props
  const newContestsPath = `${ROUTE_EVENTS_PATH}${history.location.pathname}`
  return <Redirect to={newContestsPath} />
}

export default DeprecatedContestsScene
