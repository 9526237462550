import { Checkbox } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

const CustomMuiCheckbox = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checked': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
  checked: {},
}))(Checkbox)

export default CustomMuiCheckbox
