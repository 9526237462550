import { useAuth } from 'Components/Auth'
import { isObject } from 'lodash-es'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import {
  makeCommunityFavoriteApiPath,
  makeCommunityFavoriteCommentsPath,
  makeCommunityFavoriteCommentsReplyPath,
  makeCommunityFavoriteLikeApiPath,
  makeCommunityFavoritesApiPath,
  makeCommunityFavoriteUserVoteApiPath,
  makeCurrentCommunityFavoriteApiPath,
} from './constants'

export const COMMUNITY_FAVORITE_LIFE_STATES = {
  NOMINATION: 'nomination',
  VOTE: 'vote',
  RESULTS: 'results',
}

export const getCommunityFavoritesMaps = (data) =>
  Array.isArray(data?.maps) && isObject(data?.relations?.maps)
    ? data.maps.map((m) => data.relations.maps[m.map_id])
    : []

export const useCommunityFavoritesListQuery = (params, queryKey = '') => {
  const { axios } = useAuth()
  const COM_FAVORITES_URL = makeCommunityFavoritesApiPath()

  return useInfiniteQuery(
    [COM_FAVORITES_URL, queryKey],
    async ({ pageParam = 1 }) => {
      const queryObject = {
        _page: pageParam,
        _limit: 6,
        ...params,
      }

      const { data } = await axios.get(COM_FAVORITES_URL, { params: { ...queryObject } })

      return {
        total: data.total,
        page: data.page,
        data: data.data.map((obj) => ({
          ...obj,
          maps: obj.maps?.map((map) => ({
            ...map,
            map: {
              ...data.relations.maps[map.map_id],
              user: data.relations.users[data.relations.maps[map.map_id].user_id],
            },
          })),
        })),
      }
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        allPages.reduce((acc, currentValue) => +currentValue.data.length + +acc, [0]) < +lastPage.total
          ? +lastPage.page + 1
          : undefined,
    },
    {
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      staleTime: 5000,
    },
  )
}

export const useLastCommunityFavoriteMapsQuery = (params, queryKey = '') => {
  const { axios } = useAuth()
  const COM_FAVORITES_URL = makeCommunityFavoritesApiPath()

  return useQuery(
    [COM_FAVORITES_URL, queryKey],
    async () => {
      const { data } = await axios.get(COM_FAVORITES_URL, { params })

      return data.data[0]?.maps?.map((map) => ({
        ...data.relations.maps[map.map_id],
        user: data.relations.users[data.relations.maps[map.map_id].user_id],
      }))
    },
    {
      retry: false,
      staleTime: 5000,
    },
  )
}

export const useCommunityFavoriteQuery = (comFavoriteId, options = {}) => {
  const { axios } = useAuth()
  const COMMUNITY_FAVORITE_URL = makeCommunityFavoriteApiPath(comFavoriteId)

  return useQuery(
    [COMMUNITY_FAVORITE_URL, `${comFavoriteId}`],
    async () => {
      const { data } = await axios.get(COMMUNITY_FAVORITE_URL)

      return data
    },
    {
      ...options,
      enabled: !!comFavoriteId,
    },
  )
}

export const useCurrentCommunityFavoriteQuery = () => {
  const { axios, id } = useAuth()
  const URL = makeCurrentCommunityFavoriteApiPath()

  return useQuery(
    [URL, id],
    async () => {
      const { data } = await axios.get(URL)

      return data
    },
    {
      staleTime: 5000,
    },
  )
}

export const useCurrentCommunityFavoriteVoteQuery = () => {
  const { axios, id } = useAuth()
  const URL = makeCommunityFavoriteUserVoteApiPath()

  return useQuery(
    [URL, id],
    async () => {
      const { data } = await axios.get(URL)

      return !!data?.length
    },
    {
      enabled: !!id,
    },
  )
}

export const useCurrentCommunityFavoriteVoteMutation = () => {
  const { axios, id } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeCommunityFavoriteUserVoteApiPath()

  return useMutation((ids) => axios.post(URL, { map_ids: ids }), {
    onSuccess: () => {
      queryClient.invalidateQueries([makeCurrentCommunityFavoriteApiPath(), id])
      queryClient.invalidateQueries([makeCommunityFavoriteUserVoteApiPath(), id])
    },
  })
}

export const useCommunityFavoriteLikeMutation = (comFavoriteId, isDelete) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeCommunityFavoriteLikeApiPath(comFavoriteId)
  const COMMUNITY_FAVORITE_URL = makeCommunityFavoriteApiPath(comFavoriteId)
  return useMutation(() => axios[isDelete ? 'delete' : 'post'](path), {
    onMutate: async () => {
      await queryClient.cancelQueries([makeCommunityFavoriteApiPath(comFavoriteId), `${comFavoriteId}`])
      const oldContent = queryClient.getQueryData([makeCommunityFavoriteApiPath(comFavoriteId), `${comFavoriteId}`])
      const newContent = {
        ...oldContent,
        is_like: !isDelete,
        likes_total: +oldContent.likes_total + (isDelete ? -1 : 1),
      }

      queryClient.setQueryData([makeCommunityFavoriteApiPath(comFavoriteId), `${comFavoriteId}`], newContent)
      return newContent
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        [makeCommunityFavoriteApiPath(context.oldContent.comFavoriteId), `${context.oldContent.comFavoriteId}`],
        context.oldContent,
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries([COMMUNITY_FAVORITE_URL])
    },
    enabled: !!comFavoriteId,
  })
}

export const useAddCommunityFavoriteCommentMutation = (comFavoriteId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeCommunityFavoriteCommentsPath(comFavoriteId)
  const COMMUNITY_FAVORITE_URL = makeCommunityFavoriteApiPath(comFavoriteId)
  const COM_FAVORITE_COMMENTS_URL = makeCommunityFavoriteCommentsPath(comFavoriteId)

  return useMutation((payload) => axios.post(path, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([COMMUNITY_FAVORITE_URL])
      queryClient.invalidateQueries([COM_FAVORITE_COMMENTS_URL])
    },
    enabled: !!comFavoriteId,
  })
}

export const useAddCommunityFavoriteReplyMutation = (comFavoriteId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const COMMUNITY_FAVORITE_URL = makeCommunityFavoriteApiPath(comFavoriteId)
  const COM_FAVORITE_COMMENTS_URL = makeCommunityFavoriteCommentsPath(comFavoriteId)
  const path = makeCommunityFavoriteCommentsReplyPath(comFavoriteId, commentId)

  return useMutation((payload) => axios.post(path, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([COMMUNITY_FAVORITE_URL])
      queryClient.invalidateQueries([COM_FAVORITE_COMMENTS_URL])
    },
    enabled: !!comFavoriteId,
  })
}

export const useEditCommunityFavoriteCommentMutation = (comFavoriteId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const COMMUNITY_FAVORITE_URL = makeCommunityFavoriteApiPath(comFavoriteId)
  const COM_FAVORITE_COMMENTS_URL = makeCommunityFavoriteCommentsPath(comFavoriteId)
  const path = makeCommunityFavoriteCommentsReplyPath(comFavoriteId, commentId)

  return useMutation((payload) => axios.put(path, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([COMMUNITY_FAVORITE_URL])
      queryClient.invalidateQueries([COM_FAVORITE_COMMENTS_URL])
    },
    enabled: !!comFavoriteId,
  })
}

export const useDeleteCommunityFavoriteCommentMutation = (comFavoriteId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const COMMUNITY_FAVORITE_URL = makeCommunityFavoriteApiPath(comFavoriteId)
  const COM_FAVORITE_COMMENTS_URL = makeCommunityFavoriteCommentsPath(comFavoriteId)
  const path = makeCommunityFavoriteCommentsReplyPath(comFavoriteId, commentId)

  return useMutation(() => axios.delete(path), {
    onSettled: () => {
      queryClient.invalidateQueries([COMMUNITY_FAVORITE_URL])
      queryClient.invalidateQueries([COM_FAVORITE_COMMENTS_URL])
    },
    enabled: !!comFavoriteId,
  })
}
export const useReactOnCommunityFavoriteCommentMutation = (id, commentId, contentType) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeCommunityFavoriteCommentsReplyPath(id, contentType)

  return useMutation(({ type }) => axios.post(`${path}/${commentId}/likes/${type}`), {
    onSuccess: (_) => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!id,
  })
}

export const useDeleteCommunityFavoriteCommentReactionMutation = (id, commentId, contentType) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeCommunityFavoriteCommentsReplyPath(id, contentType)

  return useMutation(({ type }) => axios.delete(`${path}/${commentId}/likes/${type}`), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!id,
  })
}
