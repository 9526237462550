import CircularProgress from '@mui/material/CircularProgress'
import withStyles from '@mui/styles/withStyles'

const CustomCircularProgress = withStyles((theme) => ({
  root: {},
  circle: {
    borderRadius: theme.shape.borderRadius,
  },
}))(CircularProgress)

export default CustomCircularProgress
