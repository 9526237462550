import { ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import FAIcon from 'Theme/FAIcon'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '280px',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.BG4,
  },
  listItem: {
    padding: theme.spacing(1, 1.5),
    marginBottom: theme.spacing(0.25),
    borderRadius: theme.shape.borderRadius * 0.5,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.BG6,
      color: theme.palette.text.primary,
    },
  },
  logoutListItem: {
    padding: theme.spacing(1, 3.25, 1, 3.25),
    margin: theme.spacing(0.5, -2, -2, -2),
    backgroundColor: theme.palette.background.BG3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  listItemIcon: {
    minWidth: 0,
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.text.secondary,
  },
}))

export const AvatarMenuItem = ({ onClick, icon, title, secondaryAction, darkTheme = false }) => {
  const classes = useStyles()

  return (
    <MenuItem
      onClick={onClick}
      className={clsx(classes.listItem, darkTheme && classes.logoutListItem)}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <FAIcon
          iconFontSize={14}
          iconClassName={icon}
        />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography
            variant="button"
            color="text.tertiary"
          >
            {title}
          </Typography>
        }
      />
      {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
    </MenuItem>
  )
}
