import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles'
import { useConnect3rdAccount } from 'hooks/use3rdAccounts'
import { SOCIAL_ACCOUNTS_NAME_ID } from 'Queries/Auth'
import React from 'react'
import Button from 'Theme/Button'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

const useStyles = makeStyles((theme) => ({
  socialLink: {
    flex: 1,
    marginLeft: theme.spacing(2),

    '&:first-child': {
      marginLeft: 0,
    },
  },
  socialIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    overflow: 'visible',
  },
}))

const SocialLogin = () => {
  const classes = useStyles()
  const { handleAccountConnect, isAccountConnectLoading } = useConnect3rdAccount()

  return (
    <Box sx={{ display: 'flex' }}>
      <Button
        variant="outlined"
        color="inherit"
        className={classes.socialLink}
        onClick={() => handleAccountConnect(SOCIAL_ACCOUNTS_NAME_ID.LIVE)}
      >
        <FAIcon
          iconClassName={FA_ICONS.XBOX}
          iconFontSize={16}
        />
      </Button>
      <Button
        variant="outlined"
        color="inherit"
        className={classes.socialLink}
        onClick={() => handleAccountConnect(SOCIAL_ACCOUNTS_NAME_ID.GOOGLE)}
      >
        <FAIcon
          iconClassName={FA_ICONS.GOOGLE}
          iconFontSize={16}
        />
      </Button>
      <Button
        variant="outlined"
        color="inherit"
        className={classes.socialLink}
        onClick={() => handleAccountConnect(SOCIAL_ACCOUNTS_NAME_ID.DISCORD)}
      >
        <FAIcon
          iconClassName={FA_ICONS.DISCORD}
          iconFontSize={16}
        />
      </Button>
      {/*<Button*/}
      {/*  variant="outlined"*/}
      {/*  color="inherit"*/}
      {/*  className={classes.socialLink}*/}
      {/*  onClick={() => handleAccountConnect(SOCIAL_ACCOUNTS_NAME_ID.TWITTER)}*/}
      {/*>*/}
      {/*  <FAIcon*/}
      {/*    iconClassName={FA_ICONS.TWITTER}*/}
      {/*    iconFontSize={18}*/}
      {/*  />*/}
      {/*</Button>*/}
      <Backdrop
        style={{ zIndex: 2 }}
        open={isAccountConnectLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}

export default SocialLogin
