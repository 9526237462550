import { Chip } from '@mui/material'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import PropTypes from 'prop-types'
import React from 'react'
import { When } from 'react-if'

import Box from '../../../Theme/Box'
import { CardContent } from '../../../Theme/Card'
import { transformTextWithThreeDots } from '../../../utils/text-transformation-utils'
import GameTypeIcon from '../GameTypeIcon'

const useStyles = makeStyles((theme) => ({
  background: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius * 2,
    top: 0,
    left: 0,
  },
  backgroundConteiner: {
    background:
      'linear-gradient(270deg, rgba(32, 33, 38, 0.2) 0%, rgba(32, 33, 38, 0.6446) 44.01%, rgba(32, 33, 38, 0.92) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius * 2,
    zIndex: 1,
  },
  container: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(3),
  },
}))

const EventCardDesktop = ({ title, backgroundSrc, categoryName, prize, playersCountName, ownerName, stepper, renderRestoreEvent }) => {
  const classes = useStyles()

  return (
    <CardContent className={classes.container}>
      <Box
        position="relative"
        flex={1}
        display="flex"
        flexDirection={'row'}
        width="calc(100% - 210px)"
        minHeight="280px"
      >
        <When condition={!!backgroundSrc}>
          <Box>
            <Box className={classes.backgroundConteiner} />
            <img
              loading="lazy"
              className={classes.background}
              alt="Background"
              src={backgroundSrc}
            />
          </Box>
        </When>

        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignSelf="flex-end"
          position="relative"
          zIndex={2}
          paddingBottom={3}
          paddingTop={3}
          px={3}
        >
          <Box
            gap={1}
            paddingTop={1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h2"
              color="text.primary"
              sx={{ wordWrap: 'break-word' }}
            >
              {transformTextWithThreeDots(title, 30)}
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap={1}
              paddingTop={1}
            >
              <When condition={!!categoryName}>
                <GameTypeIcon
                  gameType={categoryName}
                  size="small"
                  color="text.tertiary"
                />
              </When>

              <When condition={playersCountName}>
                <Typography
                  variant="caption"
                  color="text.tertiary"
                >
                  {playersCountName}
                </Typography>
              </When>

              <When condition={ownerName}>
                <Typography
                  variant="body2bold"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="text.tertiary"
                >
                  by {transformTextWithThreeDots(ownerName)}
                </Typography>
              </When>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="flex-end"
            gap={1}
          >
            <Box
              p={1.5}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: 64,
                height: 64,
                background: alpha(theme.palette.background.dark, 0.25),
                backdropFilter: 'blur(8px)',
                borderRadius: theme.spacing(2.5),
              })}
            >
              <Chip
                label={
                  <Typography
                    variant="body2bold"
                    color="text.primary"
                  >
                    {prize}
                  </Typography>
                }
                color="secondary"
                component="strong"
                size="small"
              />
              <Typography
                variant="caption"
                color="text.tertiary"
              >
                Prize pool
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <When condition={!!stepper}>
        <Box
          display="flex"
          flexDirection="column"
        >
          {stepper}
          {renderRestoreEvent()}
        </Box>
      </When>
    </CardContent>
  )
}

EventCardDesktop.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  backgroundSrc: PropTypes.string,
  categoryName: PropTypes.string,
  prize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playersCountName: PropTypes.string,
  ownerName: PropTypes.string,
  stepper: PropTypes.node,
  renderRestoreEvent: PropTypes.func,
}

export { EventCardDesktop }
