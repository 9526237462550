import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Box from 'Theme/Box'

const Timer = ({ seconds }) => {
  const [time, setTime] = useState(seconds)

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1)
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [time])

  const formatTime = (time) => {
    const secondsInDay = 86400
    const secondsInHour = 3600
    const secondsInMinute = 60

    if (time >= secondsInDay * 7) {
      return '0'
    } else if (time >= secondsInDay) {
      const days = Math.floor(time / secondsInDay)
      return `${days} ${formatPlural(days, 'day')}`
    } else if (time >= secondsInHour) {
      const hours = Math.floor(time / secondsInHour)
      const minutes = Math.floor((time % secondsInHour) / secondsInMinute)
      const seconds = Math.floor(time % secondsInMinute)
      return `${hours}:${formatDoubleDigit(minutes)}:${formatDoubleDigit(seconds)}`
    } else {
      const minutes = Math.floor(time / secondsInMinute)
      const seconds = Math.floor(time % secondsInMinute)
      return `${minutes}:${formatDoubleDigit(seconds)}`
    }
  }

  const formatPlural = (count, word) => {
    if (count === 1) {
      return `${word} left`
    } else {
      return `${word}s left`
    }
  }

  const formatDoubleDigit = (number) => number.toString().padStart(2, '0')

  return (
    formatTime(time) !== '0' && (
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.error.main,
          borderRadius: '22px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1px 12px',
        })}
      >
        <Typography
          color="text.primary"
          variant="h6"
        >
          {formatTime(time)}
        </Typography>
      </Box>
    )
  )
}

export default Timer
