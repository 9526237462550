import { useAuth } from 'Components/Auth'
import { getFlatPagesDataFromQueryData, getMappedWeapons, getSortedWeapons } from 'Queries/QueryDataUtils'
import { stringify } from 'query-string'
import { useMemo } from 'react'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import QUERY, {
  makeDownloadEntityPath,
  makeMapsFiltersQueryPath,
  makeMapsOriginalQueryPath,
  makeMapsQueryPath,
  makeMapsUnbookmarkedQueryPath,
  makePresetHomeApiPath,
} from './constants'
import { useContestsQuery } from './Contests'
import { useDictionariesQuery } from './Dictionaries'

export const usePresetHomePage = () => {
  const { axios } = useAuth()
  const PRESET_HOME_PATH = makePresetHomeApiPath()
  const INFECTION_ID = 2
  const MINI_GAME_ID = 4

  const setUserAndCategoryFromRelations = (data, relations) => ({
    ...data,
    data: data.data.map((item) => ({
      ...item,
      user: relations.users[item.user_id],
      categoryName: item.categories ? relations.categories[item.categories[0].category_id].name : '',
      haloWaypointUser:
        item.is_soft_published && item.halowaypoint_user_xuid
          ? relations.halowaypoint_users[item?.halowaypoint_user_xuid]
          : '',
    })),
  })

  return useQuery(
    [PRESET_HOME_PATH],
    async () => {
      const { data } = await axios.get(PRESET_HOME_PATH, {
        params: { _limit: 12, category_ids: `${INFECTION_ID},${MINI_GAME_ID}` },
      })

      return {
        ...data,
        featured: setUserAndCategoryFromRelations(data.featured, data.relations),
        new: setUserAndCategoryFromRelations(data.new, data.relations),
        trending: setUserAndCategoryFromRelations(data.trending, data.relations),
        remake: setUserAndCategoryFromRelations(data.remakes, data.relations),
        infection: setUserAndCategoryFromRelations(data.category[2], data.relations),
        miniGame: setUserAndCategoryFromRelations(data.category[4], data.relations),
        prefab_trending: setUserAndCategoryFromRelations(data.prefab_trending, data.relations),
      }
    },
    {
      retry: false,
      retryOnMount: false,
    },
  )
}

export const useMapsQuery = (params = {}, enabled = true) => {
  const { axios } = useAuth()

  return useInfiniteQuery(
    [QUERY.MAPS, params],
    async ({ pageParam = 1 }) => {
      const { categoryId, type, limit, skip = 0, searchValue, ...filters } = params

      const paramsObject = {
        category_id: categoryId,
        _page: pageParam,
        'title~': searchValue ? `%${searchValue}%` : null,
        _limit: limit || (pageParam === 1 ? 24 : 32),
        _skip: skip,
        state: 'published',
        is_hidden: false,
        ...filters,
      }

      switch (type) {
        case 'trending':
          paramsObject['_sort'] = '-trends_week'
          break
        case 'featured':
          paramsObject['_sort'] = '-id,-time_featured,community_favorite_place'
          paramsObject['is_featured'] = 'true'
          break
        default:
          paramsObject['_sort'] = null
      }

      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${QUERY.MAPS}?${queryString}`)
      const relationUsers = data?.relations?.users || []
      const relationCategories = data?.relations?.categories || []
      const haloWaypointUsers = data?.relations?.halowaypoint_users || []

      return {
        data: data.data.map((map) => ({
          ...map,
          user: relationUsers[map.user_id],
          categoryName: map.categories ? relationCategories[map.categories[0].category_id].name : '',
          haloWaypointUser: haloWaypointUsers[map.halowaypoint_user_xuid],
        })),
        total: data.total,
        page: data.page,
        totalPages: data.pages,
      }
    },
    {
      enabled,
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      refetchOnWindowFocus: false,
      staleTime: 50000,
      getNextPageParam: (lastPage) => (lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined),
    },
  )
}

export const useBookmarkedMapFiltersQuery = (enabled = false, params) => {
  const { axios } = useAuth()
  const paramsObject = {
    is_bookmarked: true,
  }
  const queryString = stringify(paramsObject, { skipNull: true })
  const URL = `${makeMapsFiltersQueryPath()}?${queryString}`

  return useQuery([URL], async () => await axios.get(URL), {
    enabled,
  })
}

export const useGetBookmarksFilterDataFromFilters = (filters = {}) => {
  const { data: dictionaries } = useDictionariesQuery()
  const { data } = useContestsQuery()
  const contests = useMemo(() => getFlatPagesDataFromQueryData(data), [data])
  const keyMapForDictionary = {
    category_id: 'categories',
    contest_id: '',
    game_type_id: 'game_types',
    players_count_id: 'players_counts',
    remake_id: 'remakes',
  }
  const getContestUniqueTag = (contestId) => contests.find((c) => c.id === contestId)?.unique_tag
  const filtersParams = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [
      key.replace('_ids', '_id'),
      Object.keys(values).filter((k) => !!k && k !== 'null' && k),
    ]),
  )
  const filtersData = Object.fromEntries(
    Object.entries(filtersParams).map(([key, values]) => [
      key,
      values.map((v) => ({
        id: v,
        name:
          key === 'contest_id' ? getContestUniqueTag(Number(v)) : dictionaries?.[keyMapForDictionary[key]]?.[v]?.name,
        paramKey: key,
      })),
    ]),
  )

  return [filtersParams, filtersData]
}

export const useBookmarksInfiniteQuery = ({ sort, filters }, enabled) => {
  const { axios, id } = useAuth()
  const URL = makeMapsQueryPath()

  return useInfiniteQuery(
    [URL, id, sort, filters],
    async ({ pageParam = 1 }) => {
      const paramsObject = {
        is_bookmarked: true,
        _page: pageParam,
        _limit: 24,
        _sort: sort,
        ...filters,
      }
      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${URL}?${queryString}`)

      return {
        data: data.data.map((m) => ({
          ...m,
          user: data.relations.users[m.user_id],
        })),
        total: data.total,
      }
    },
    {
      enabled,
      retry: false,
      retryOnMount: false,
      cacheTime: 10000,
      retryDelay: 2000,
      staleTime: 50000,
    },
  )
}

export const useUnbookmarkedQuery = (enabled) => {
  const { axios } = useAuth()
  const URL = makeMapsUnbookmarkedQueryPath()

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(`${URL}?_limit=4`)
      return data.data.map((map) => ({
        ...map,
        user: data.relations.users[map.user_id],
      }))
    },
    {
      enabled,
      retry: false,
      retryOnMount: false,
    },
  )
}

export const useUnbookmarkedMutation = ({ sort, filters }) => {
  const { axios, id } = useAuth()
  const URL = makeMapsUnbookmarkedQueryPath()
  const queryClient = useQueryClient()

  return useMutation((mapIds) => (!!mapIds ? axios.post(URL, { mapIds }) : axios.delete(URL)), {
    onSuccess: () => {
      queryClient.invalidateQueries(URL)
      queryClient.invalidateQueries([makeMapsQueryPath(), id, sort, filters])
    },
  })
}

export const useFeaturedMapsQuery = () => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.MAPS, 'featured'],
    async () => {
      const { data } = await axios.get(`${QUERY.MAPS}?_limit=4&state=published&is_featured=true&is_hidden=false`)
      return data.data.map((map) => ({
        ...map,
        user: data.relations.users[map.user_id],
      }))
    },
    {
      retry: false,
      retryOnMount: false,
    },
  )
}

export const useRemakesQuery = () => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.REMAKES],
    async () => {
      const { data } = await axios.get(QUERY.REMAKES)
      return getMappedWeapons(data.data)
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useWeaponsInfiniteQuery = ({ gameId }) => {
  const { axios } = useAuth()

  return useInfiniteQuery(
    [QUERY.WEAPONS],
    async ({ pageParam = 1 }) => {
      const paramsObject = {
        game_id: gameId,
        weapon_type_id: 1,
        _page: pageParam,
        _limit: 10,
      }
      const queryString = stringify(paramsObject, { skipNull: true })
      const { data } = await axios.get(`${QUERY.WEAPONS}?${queryString}`)

      return data
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 10000,
      retryDelay: 2000,
      staleTime: 5000,
    },
  )
}

export const useSortedWeaponsQuery = (weapons) => {
  const { axios } = useAuth()
  return useQuery(
    [QUERY.WEAPONS, 'sorted-weapons'],
    async () => {
      const { data } = await axios.get(`${QUERY.WEAPONS}?_sort=tier&_limit=999`)
      return getSortedWeapons(data.data, weapons)
    },
    {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}

export const useVehiclesQuery = ({ gameId }) => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.WEAPONS, 'vehicles'],
    async () => {
      const { data } = await axios.get(`${QUERY.WEAPONS}?game_id=${gameId}&weapon_type_id=2`)
      return getMappedWeapons(data.data)
    },
    {
      retry: false,
      retryOnMount: false,
    },
  )
}

export const usePowerUpsQuery = ({ gameId }) => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.WEAPONS, 'powerups'],
    async () => {
      const { data } = await axios.get(`${QUERY.WEAPONS}?game_id=${gameId}&weapon_type_id=3`)
      return getMappedWeapons(data.data)
    },
    {
      retry: false,
      retryOnMount: false,
    },
  )
}

export const useEquipmentsQuery = ({ gameId }) => {
  const { axios } = useAuth()

  return useQuery(
    [QUERY.WEAPONS, 'equipments'],
    async () => {
      const { data } = await axios.get(`${QUERY.WEAPONS}?game_id=${gameId}&weapon_type_id=4`)
      return getMappedWeapons(data.data)
    },
    {
      retry: false,
      retryOnMount: false,
    },
  )
}

export const useDownloadEntity = (entity) => {
  const { axios } = useAuth()

  return useMutation((id) => axios.get(makeDownloadEntityPath(entity, id)))
}

export const useOriginalMapsQuery = (gameId) => {
  const { axios } = useAuth()
  const URL = makeMapsOriginalQueryPath()
  return useQuery([URL, gameId], async () => {
    const paramsObject = {
      game_id: gameId,
    }

    const queryString = stringify(paramsObject, { skipNull: true })
    const { data } = await axios.get(`${URL}?${queryString}`)
    return {
      data: data.data.map((originalMap) => ({
        ...originalMap,
        user: data.relations.users[originalMap.user_id],
      })),
    }
  })
}
