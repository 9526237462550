import Axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import QUERY from 'Queries/constants'
import React, { useCallback, useMemo } from 'react'
import createPersistedState from 'use-persisted-state'

import { AuthContext } from './context'

const { REACT_APP_AUTH_TOKEN_KEY: AUTH_TOKEN_KEY } = process.env

const usePersistedState = createPersistedState(AUTH_TOKEN_KEY)

const { AUTH_REFRESH } = QUERY

const Auth = ({ children }) => {
  const INITIAL_STATE = useMemo(
    () => ({
      token: null,
      expires_in: null,
      token_type: null,
    }),
    [],
  )

  const axios = useMemo(() => Axios.create(), [])

  const [auth, setAuth] = usePersistedState(INITIAL_STATE)

  const forceAuth = useCallback(() => {
    setAuth(INITIAL_STATE)
  }, [setAuth, INITIAL_STATE])

  const getAccessToken = useCallback(() => JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY)), [])

  const reqInterceptor = useCallback(
    (reqConfig) => {
      if (getAccessToken()?.['token']) {
        reqConfig.headers.Authorization = `Bearer ${getAccessToken()['token']}`
      }

      return reqConfig
    },
    [getAccessToken],
  )

  // Use Effect?
  axios.interceptors.request.use(reqInterceptor)

  const handleRefresh = useCallback(
    () =>
      Axios.post(AUTH_REFRESH, {})
        .then((res) => {
          const payload = res.data

          const storedAuthData = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY))

          localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify({ ...storedAuthData, ...payload }))

          return Promise.resolve()
        })
        .catch(forceAuth),
    [forceAuth],
  )

  createAuthRefreshInterceptor(axios, handleRefresh, {
    statusCodes: [401, 403],
  })

  const isAuthorized = !!auth.token

  return (
    <AuthContext.Provider
      value={{
        id: auth.id,
        setAuth,
        forceAuth,
        isAuthorized,
        axios,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default Auth
export * from './hooks'
