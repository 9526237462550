import { CustomStepper, getEvaluationIcon, getFinalIcon, getQualificationIcon, getSubmissionIcon } from 'Components/_common/CustomStepper'
import React from 'react'

import { getContestStepperState } from './utils'

const ContestStepper = ({ contest }) => {
  const activeStep = getContestStepperState(contest.state)
  const stepsListConfig = [
    {
      value: 0,
      title: 'Submission',
      endDate: contest.time_add_maps_end,
      timer: contest.time_add_maps_countdown_sec,
      icon: getSubmissionIcon(activeStep),
    },
    {
      value: 1,
      title: '0: Qualification',
      endDate: contest.time_qualification_end,
      icon: getQualificationIcon(activeStep),
    },
    {
      value: 2,
      title: '1: Evaluation',
      endDate: contest.time_evaluation_end,
      icon: getEvaluationIcon(activeStep),
    },
    {
      value: 3,
      title: '2: Final',
      endDate: contest.time_final_end,
      icon: getFinalIcon(activeStep),
    },
  ]

  return (
    <CustomStepper
      activeStep={activeStep}
      stepsListConfig={stepsListConfig}
    />
  )
}

export default ContestStepper
