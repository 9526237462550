export const getFlatPagesDataFromQueryData = (data) => (data ? data?.pages?.map((page) => page.data).flat() : [])

export const getMappedWeapons = (data) =>
  data.map((weapon) => ({
    ...weapon,
    image: weapon?.weapon_images?.[0].urls.original,
  }))

export const getSortedWeapons = (data, weapons) => {
  const sortedByTier = data.reverse()
  const selectedWeapons = sortedByTier.filter(({ id }) => weapons.includes(id))
  const powerUps = selectedWeapons.filter(({ weapon_type_id }) => weapon_type_id === 3)
  const powerWeapons = selectedWeapons.filter(({ weapon_type_id }) => weapon_type_id === 1)
  const equipments = selectedWeapons.filter(({ weapon_type_id }) => weapon_type_id === 4)
  const vehicles = selectedWeapons.filter(({ weapon_type_id }) => weapon_type_id === 2)

  return {
    powerUps,
    powerWeapons,
    equipments,
    vehicles,
  }
}
