import { Box, Typography } from '@mui/material'
import MobileMainHeader from 'Components/_common/MobileMainHeader'
import SignUpForm from 'Components/Auth/SignUpForm'
import { navigateToRootPage } from 'Scenes/navigators'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

const AuthScene = () => (
  <>
    <MobileMainHeader>
      <Box sx={{ height: '56px', display: 'flex', alignItems: 'center', padding: '0 24px 0 16px', gap: '24px' }}>
        <FAIcon
          iconClassName={FA_ICONS.ARROW_LEFT}
          color="text.tertiary"
          onClick={() => navigateToRootPage()}
        />
        <Typography variant="h5">Sign Up</Typography>
      </Box>
    </MobileMainHeader>

    <SignUpForm />
  </>
)

export default AuthScene
