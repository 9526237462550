import { Box, Tabs, Typography } from '@mui/material'
import CustomMuiTab from 'Theme/Tab'

import { PUBLISH_ITEMS } from './utils'

export const PublishModalHeader = ({ isConectedToXbox, contentTab, handleSelectTab }) =>
  isConectedToXbox ? (
    <Box
      pl={3}
      pt={1}
      bgcolor="#202126"
    >
      <Tabs
        value={contentTab}
        onChange={handleSelectTab}
        variant="scrollable"
        indicatorColor="secondary"
        textColor="inherit"
        scrollButtons={false}
        sx={{
          '& .MuiTabs-indicator': {
            height: '4px',
            borderRadius: '5px 5px 0 0',
          },
        }}
      >
        {Object.values(PUBLISH_ITEMS).map((item) => (
          <CustomMuiTab
            key={item}
            label={item}
            style={{ minWidth: 0 }}
            value={item}
            sx={(theme) => ({
              ...theme.typography.h5,
              padding: theme.spacing(3),
            })}
          />
        ))}
      </Tabs>
    </Box>
  ) : (
    <Box
      p={4}
      pb={0}
    >
      <Typography variant="h4">Publish Content</Typography>
    </Box>
  )
