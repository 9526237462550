import { AppBar, Box, useMediaQuery } from '@mui/material'
import { ReactComponent as LogoWithText } from 'Assets/img/LogoForgehub.svg'
import { LeaveUserButton } from 'Components/Layout/MainLayout/LeaveUserButton'
import { useShowLeaveUser } from 'hooks/useShowLeaveUser'
import { Else, If, Then, When } from 'react-if'
import { navigateToRootPage, navigateToSearchPage } from 'Scenes/navigators'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'
import { BREAKPOINT_MIN_SM } from 'Theme/stylesUtil'

const MobileMainHeader = ({ children, subheader, rightBlock }) => {
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)
  const showLeaveButton = useShowLeaveUser()

  return (
    <When condition={!isBiggerThanSMScreen}>
      <AppBar>
        <Box backgroundColor="background.paper">
          <If condition={children}>
            <Then>{children}</Then>
            <Else>
              <Box
                width="100%"
                height="50px"
                backgroundColor="background.paper"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pl={2}
              >
                <LogoWithText onClick={() => navigateToRootPage()} />
                <Box
                  display="flex"
                  alignItems="center"
                  gap="14px"
                >
                  {showLeaveButton && <LeaveUserButton />}

                  <If condition={rightBlock}>
                    <Then>{rightBlock}</Then>
                    <Else>
                      <FAIcon
                        iconClassName={FA_ICONS.MAGNIFYING_GLASS}
                        color="text.tertiary"
                        iconFontSize={18}
                        boxSize={48}
                        onClick={() => navigateToSearchPage()}
                      />
                    </Else>
                  </If>
                </Box>
              </Box>
            </Else>
          </If>
          {subheader}
        </Box>
      </AppBar>
    </When>
  )
}

export default MobileMainHeader
