import { Tab, Tabs } from '@mui/material'

export const SliderTabsIndicator = ({ selectedItem, items }) => (
  <Tabs
    sx={(theme) => ({
      width: '100%',
      height: '3px',
      minHeight: 'unset',
      '& .MuiTabs-indicator': {
        width: '64px !important',
        background: theme.palette.secondary.main,
        height: '3px',
        borderRadius: '3px',
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
      },
    })}
    value={selectedItem}
  >
    {items.map((item) => (
      <Tab
        key={item.id}
        value={item.id}
        sx={{
          height: 0,
          padding: 0,
          flexGrow: 1,
        }}
      />
    ))}
  </Tabs>
)
