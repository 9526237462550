import Chip from '@mui/material/Chip'
import withStyles from '@mui/styles/withStyles'

const Styles = withStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0.5),
    color: theme.palette.text.tertiary,
    borderRadius: '22px',
  },
  sizeSmall: {
    padding: theme.spacing(0.5, 1),
  },
  label: {
    ...theme.typography.caption,
    paddingRight: 0,
    paddingLeft: 0,
  },
  colorSecondary: {
    backgorundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,

    '&$label': {
      color: theme.palette.primary.contrastText,
    },
  },
  colorError: {
    color: theme.palette.white.main,
  },
  icon: {
    marginLeft: 0,
    marginRight: theme.spacing(1),

    '& ~ *:last-child': {
      marginRight: theme.spacing(0.5),
    },
  },
}))

export default Styles(Chip)
