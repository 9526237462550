import Box from '@mui/material/Box'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

const Styles = withStyles((theme) => ({
  background: {
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    zIndex: '0',
    maxHeight: '720px',

    '&:after': {
      content: "''",
      display: 'block',
      background: `
        linear-gradient(
          180deg,
          rgba(25, 26, 30, 0.2) 78%,
          rgba(25, 26, 30, 0.78) 92%,
          #191A1E 100%)
        `,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
    },
    [theme.breakpoints.up('sm')]: {
      '&:before': {
        content: "''",
        display: 'block',
        backdropFilter: 'blur(12px)',
        height: '12%',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
      },
    },

    '& > *': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  homePage: {
    '&:after': {
      background: `
        linear-gradient(270deg, #17181C 0%, rgba(24, 25, 29, 0.3) 51.78%, #191A1E 100.42%),
        linear-gradient(0deg, #191A1E 29.59%, rgba(25, 26, 30, 0.3) 71.74%)
        `,
    },
  },
}))

const PageBackground = ({ classes, imageElement, height = 350, styleName, ...otherProps }) => (
  <Box
    className={`${classes.background} ${classes[styleName] || ''}`}
    height={height}
    {...otherProps}
  >
    {imageElement}
  </Box>
)

export default Styles(PageBackground)
