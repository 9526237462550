import { useAuth } from 'Components/Auth'
import AvatarMenu from 'Components/Layout/MainHeaderControls/AvatarMenu/AvatarMenu'
import { HeaderSearchField } from 'Components/SearchComponents/SearchField/HeaderSearchField'
import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import Box from 'Theme/Box'
import Button from 'Theme/Button'

import { ROUTE_SEARCH_PATH } from '../../../Scenes/constants'
import { navigateToCreateNewAccountPage, navigateToLoginModal, navigateToSearchPage } from '../../../Scenes/navigators'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import ButtonSignIn from '../../_common/Buttons/ButtonSignIn'
import { SearchContext } from '../MainLayout/SearchContext'

const MainHeaderControls = ({ isOpaque }) => {
  const { isAuthorized } = useAuth()
  const location = useLocation()
  const { search, setSearch } = useContext(SearchContext)

  const isSearchPage = location.pathname.includes(ROUTE_SEARCH_PATH)
  const showSearch = !isSearchPage

  const handleChange = (valeue) => {
    setSearch(valeue)
  }

  return (
    <>
      <Box width={215}>
        {showSearch && (
          <HeaderSearchField
            placeholder="Search"
            value={search}
            onChange={handleChange}
            onKeyDown={(e) => e.key === 'Enter' && !isSearchPage && navigateToSearchPage({ value: search })}
            style={{ backdropFilter: 'blur(20px)' }}
          />
        )}
      </Box>

      <Box ml={4}>
        {isAuthorized ? (
          <AvatarMenu />
        ) : (
          <Box
            display="flex"
            gap={3}
          >
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigateToCreateNewAccountPage()}
            >
              <FAIcon iconClassName={FA_ICONS.USER_PLUS} />
            </Button>

            <ButtonSignIn
              text="Sign In"
              onClick={() => navigateToLoginModal()}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default MainHeaderControls
