import { Box, Button } from '@mui/material'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

export const WrongIdButtons = ({ handleCreate, handleCreateWithUrl, disableCreation }) => (
  <Box
    display="flex"
    gap={2}
  >
    <Button
      variant="contained"
      sx={(theme) => ({
        background: 'rgba(0, 174, 112, 0.1)',
        color: theme.palette.primary.main,
        '&:hover': {
          background: 'rgba(0, 174, 112, 0.3)',
        },
      })}
      onClick={handleCreateWithUrl}
      disabled={disableCreation}
    >
      Try again
      <FAIcon iconClassName={FA_ICONS.ROTATE_RIGHT} />
    </Button>
    <Button
      variant="contained"
      color={'error'}
      onClick={handleCreate}
      disabled={disableCreation}
    >
      Continue without Transferring Data
      <FAIcon iconClassName={FA_ICONS.ARROW_RIGHT} />
    </Button>
  </Box>
)
