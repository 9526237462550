import Tab from '@mui/material/Tab'

const getCustomTabStyles = (theme) => ({
  ...theme.typography.body2,
  minWidth: '120px',
  minHeight: '48px',
  padding: '0px 16px',
  textTransform: 'capitalize',

  [theme.breakpoints.down('sm')]: { minWidth: '51px' },

  '& .MuiTab-iconWrapper': {
    display: 'flex',
    marginBottom: 0,
  },
})

const CustomMuiTab = (props) => (
  <Tab
    sx={getCustomTabStyles}
    {...props}
  />
)

export default CustomMuiTab
