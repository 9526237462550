import { Fade, Menu } from '@mui/material'

const CustomMuiMenu = ({ transformOrigin, anchorOrigin, PaperProps, ...props }) => (
  <Menu
    TransitionComponent={Fade}
    transformOrigin={transformOrigin || { horizontal: 'right', vertical: 'top' }}
    anchorOrigin={anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
    PaperProps={
      PaperProps || {
        sx: {
          mt: 1,
        },
      }
    }
    {...props}
  />
)

export default CustomMuiMenu
