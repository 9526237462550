export const AWARD_BUDGES = {
  FEATURED: 'is_featured',
  RECOMMENDED: 'is_recommended',
  MATCHMAKING: 'is_matchmaking',
  LIKED: 'is_liked',
  FAVORITE: 'is_favorite',
  TRENDING: 'is_trending',
  CONTEST_WINNER: 'is_contest_winner',
}

export const checkAwardBudge = (content) => !!Object.values(AWARD_BUDGES).find((award) => content[award])
