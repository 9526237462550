import CardMedia from '@mui/material/CardMedia'
import withStyles from '@mui/styles/withStyles'

const StyledCardMedia = withStyles(() => ({
  root: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: '0',

    '&:after': {
      content: "''",
      display: 'block',
      background: `
       linear-gradient(180deg, rgba(32, 33, 38, 0) 0%, rgba(32, 33, 38, 0.5) 70%, rgba(32, 33, 38, 0.9) 100%)
        `,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },

    '& > img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
}))(CardMedia)

export default StyledCardMedia
