import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'

const MainSidebarListItem = ({
  title,
  to,
  icon: Icon,
  selected,
  isOpen,
  onMouseEnter,
  onMouseLeave,
  isTablet = false,
}) => {
  const theme = useTheme()
  const handleMouseEnter = (e) => {
    if (isTablet) return
    onMouseEnter(e, title)
  }
  const handleMouseLeave = () => {
    if (isTablet) return
    onMouseLeave()
  }
  return (
    <>
      <Link
        to={to}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          component={ListItem}
          sx={{ backgroundColor: 'unset', height: theme.spacing(6) }}
        >
          {selected && (
            <Box
              position="absolute"
              borderRadius="0px 100px 100px 0px"
              width={theme.spacing(0.375)}
              height={theme.spacing(3)}
              bgcolor={theme.palette.secondary.main}
              left={0}
            />
          )}
          <Box
            component={ListItemIcon}
            paddingLeft={1}
            paddingRight={2}
            sx={{ minWidth: 'unset', color: selected ? theme.palette.text.primary : theme.palette.text.secondary }}
          >
            {typeof Icon === 'function' ? <Icon /> : Icon}
          </Box>
          <Box
            component={ListItemText}
            display={isOpen ? 'none' : 'block'}
            disableTypography
            primary={
              <Typography
                variant="h6"
                color={selected ? 'text.primary' : 'text.secondary'}
                sx={{
                  '&:hover': {
                    color: theme.palette.text.primary,
                  },
                }}
              >
                {title}
              </Typography>
            }
          />
        </Box>
      </Link>
    </>
  )
}

export default MainSidebarListItem
