import { Box, Button, Chip, Typography } from '@mui/material'
import CustomCircularProgress from 'Theme/CircularProgress'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

export const OwnerControls = ({ onDelete, isLoading }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
  >
    <Chip
      size="small"
      label={<Typography variant="overline">Draft</Typography>}
    />
    <Button
      variant="outlined"
      color="inherit"
      onClick={onDelete}
      disabled={isLoading}
      sx={{
        background: 'rgba(255, 255, 255, 0.05)',
        backdropFilter: 'blur(8px)',
        border: 'none',
        padding: '8px 6px',
        height: '40px',
        color: '#CBD1E2',
      }}
    >
      {isLoading ? (
        <CustomCircularProgress
          size="16px"
          color="inherit"
        />
      ) : (
        <FAIcon iconClassName={FA_ICONS.TRASH} />
      )}
    </Button>
  </Box>
)
