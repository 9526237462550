import { Box, Typography } from '@mui/material'
import { useAuth } from 'Components/Auth'
import { useUserQuery } from 'Queries/Users'
import { useEffect } from 'react'
import Avatar from 'Theme/Avatar'

import { INITIAL_LOCAL_STORAGE_DATA, usePersistedLoggedFromId } from '../../../hooks/usePersistedData'
import { PROFILE_IMAGE_TYPE } from '../../../Queries/utils'
import { userImageSelector } from '../../../utils/user-images-utils'
import { LeaveUserButton } from './LeaveUserButton'

export const LeaveUser = ({ height }) => {
  const { id: userId } = useAuth()
  const { data: user = {} } = useUserQuery(userId)
  const [loggedFromId, setLoggedFromId] = usePersistedLoggedFromId()
  const { data: loggedFromUser, isFetched } = useUserQuery(loggedFromId)

  useEffect(() => {
    if (isFetched && !loggedFromUser) setLoggedFromId(INITIAL_LOCAL_STORAGE_DATA)
  }, [isFetched, loggedFromUser, setLoggedFromId])

  return (
    <Box
      height={height}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="#01A3D266"
      px="14px"
    >
      <Box
        display="flex"
        gap="12px"
      >
        <Avatar
          size="small"
          src={userImageSelector(user?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'small')}
        />
        <Typography
          sx={{
            fontSize: '15px',
          }}
        >
          Currently impersonating <b>{user?.login}</b>. Click 'Leave User' to return to Admin account:
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Avatar
            size="small"
            src={userImageSelector(loggedFromUser?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'small')}
          />
          <b>{loggedFromUser?.login}</b>
        </Box>
      </Box>
      <LeaveUserButton />
    </Box>
  )
}
