import { Chip } from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { When } from 'react-if'

import Avatar from '../../../Theme/Avatar'
import Box from '../../../Theme/Box'
import { transformTextWithThreeDots } from '../../../utils/text-transformation-utils'
import GameTypeIcon from '../GameTypeIcon'

const EventCardMobile = ({
  title,
  backgroundSrc,
  prize,
  playersCountName,
  categoryName,
  ownerName,
  ownerAvatarSrc,
  mobileStepper,
}) => (
    <Box
      sx={{
        width: '100%',
        height: '200px',
        background: `linear-gradient(270deg,
                  rgba(32, 33, 38, 0.2) 0%,
                  rgba(32, 33, 38, 0.6446) 44.01%,
                  rgba(32, 33, 38, 0.92) 100%
                ),
                url(${backgroundSrc})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '12px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: !!mobileStepper ? 'space-between' : 'flex-end' }}>
        <When condition={!!mobileStepper}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box
              height="12px"
              width="12px"
              borderRadius="50%"
              backgroundColor="primary.main"
              position="relative"
              top="2px"
            />
            {mobileStepper}
          </Box>
        </When>
        <Chip
          label={
            <Typography
              variant="body2bold"
              color="text.primary"
            >
              {prize}
            </Typography>
          }
          color="secondary"
          component="strong"
          size="small"
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Avatar
            size="small"
            src={ownerAvatarSrc}
          />
          <Box sx={{ height: '32px', display: 'grid' }}>
            <Typography
              variant="h6"
              color="text.primary"
            >
              {transformTextWithThreeDots(title, 15)}
            </Typography>
            <Typography
              variant="body2bold"
              color="text.tertiary"
              lineHeight="16px"
            >
              {`by ${transformTextWithThreeDots(ownerName)}`}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Typography
            variant="caption"
            color="text.tertiary"
          >
            {playersCountName}
          </Typography>
          <GameTypeIcon
            gameType={categoryName}
            size="small"
            color="text.tertiary"
          />
        </Box>
      </Box>
    </Box>
  )

EventCardMobile.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundSrc: PropTypes.string,
  prize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  playersCountName: PropTypes.string,
  categoryName: PropTypes.string,
  ownerName: PropTypes.string,
  ownerAvatarSrc: PropTypes.string,
  mobileStepper: PropTypes.node,
}

export { EventCardMobile }
