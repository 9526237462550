import { DesktopModalFooter } from './DesktopModalFooter'
import { MobileModalFooter } from './MobileModalFooter'

export const ModalFooter = ({
  handleClose,
  disableCreation,
  handleCreate,
  isLoading,
  isWrongWaypointId,
  handleCreateWithUrl,
  waypointLink,
  isMobileView,
  footerPadding,
}) =>
  !isMobileView ? (
    <DesktopModalFooter
      disableCreation={disableCreation}
      handleClose={handleClose}
      handleCreate={handleCreate}
      isLoading={isLoading}
      isWrongWaypointId={isWrongWaypointId}
      handleCreateWithUrl={handleCreateWithUrl}
      waypointLink={waypointLink}
      sx={{
        paddingLeft: footerPadding,
        paddingRight: footerPadding,
      }}
    />
  ) : (
    <MobileModalFooter
      disableCreation={disableCreation}
      handleClose={handleClose}
      handleCreate={handleCreate}
      isLoading={isLoading}
      isWrongWaypointId={isWrongWaypointId}
      handleCreateWithUrl={handleCreateWithUrl}
      waypointLink={waypointLink}
      sx={{
        paddingLeft: footerPadding,
        paddingRight: footerPadding,
      }}
    />
  )
