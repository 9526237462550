import { useTheme } from '@mui/material/styles'
import Button from 'Theme/Button'

import FAIcon from '../../Theme/FAIcon'
import { FA_ICONS } from '../../Theme/font-awesome-icon-constants'
import AccauntCardContainer from './AccauntCardContainer'

const ConnectedAccountCard = ({ id, iconClass, name, value, connected, onConnect, onDisconnect, bgColor }) => {
  const theme = useTheme()

  return (
    <AccauntCardContainer
      bgColor={bgColor}
      name={name}
      value={value}
      icon={
        <FAIcon
          iconFontSize={32}
          iconClassName={iconClass}
          color={theme.palette.text.tertiary}
        />
      }
      control={
        connected ? (
          <Button
            variant="contained"
            color="inherit"
            sx={{ gap: '16px' }}
            onClick={() => onDisconnect && onDisconnect(id)}
          >
            <FAIcon
              iconFontSize={16}
              iconClassName={FA_ICONS.CLOSE}
            />
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onConnect(id)}
          >
            Connect
          </Button>
        )
      }
    />
  )
}

export default ConnectedAccountCard
