import { Box, Typography } from '@mui/material'
import FAIcon from 'Theme/FAIcon'
import { FA_KIT_ICONS } from 'Theme/font-awesome-icon-constants'

export const PublishContentCard = ({ handleClick, sx, contentType }) => (
  <Box
    sx={(theme) => ({
      height: '110px',
      width: 'calc(25% - 8px)',
      borderRadius: '8px',
      overflow: 'hidden',
      position: 'relative',
      cursor: 'pointer',
      background: '#D1E9FF0D',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
      ...sx,
    })}
    onClick={handleClick}
  >
    <FAIcon
      color="text.tertiary"
      iconFontSize={14}
      iconClassName={contentType === 'map' ? FA_KIT_ICONS.SUBMIT_MAP_PLUS : FA_KIT_ICONS.SUBMIT_PREFAB_PLUS}
    />
    <Typography
      color="text.secondary"
      sx={{
        textTransform: 'capitalize',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
      }}
    >
      {contentType}
    </Typography>
  </Box>
)
