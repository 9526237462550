import { Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConditionalWrap from 'conditional-wrap'
import React, { useRef, useState } from 'react'
import { Else, If, Then } from 'react-if'
import { Link } from 'react-router-dom'

import { isDeviceHasTouch } from '../../../Theme/stylesUtil'
import { ComingSoon } from './ComingSoon'
import RowCollectionMobile from './RowCollectionMobile'
import RowCollectionsDesktop from './RowCollectionsDesktop'

const ScrollRow = ({ title, link, type = 'map', collection = [], isFetching, showMoreBtn = false }) => {
  const [isDragging, setIsDragging] = useState(false)

  const isDataFetching = !collection?.length && isFetching
  const wrapperContainerRef = useRef(null)

  return (
    <Box
      paddingTop={2}
      paddingBottom={5}
      position="relative"
    >
      <Box
        paddingBottom={2.5}
        width="fit-content"
      >
        <ConditionalWrap
          condition={!!link}
          wrap={(children) => <Link to={link}>{children}</Link>}
        >
          <Typography
            variant="h3"
            color="text.primary"
          >
            {title}
          </Typography>
        </ConditionalWrap>
      </Box>
      <Box
        ref={wrapperContainerRef}
        display="flex"
        position="relative"
        sx={{ cursor: isDragging ? 'grab' : 'initial' }}
      >
        {isDataFetching ? (
          <Box
            sx={(theme) => ({
              gap: theme.spacing(3),
              display: 'flex',
            })}
          >
            {[...Array(8).keys()].map((key) => (
              <Skeleton
                key={key}
                variant="rectangular"
                height={192}
                width={285}
              />
            ))}
          </Box>
        ) : (
          <If condition={isDeviceHasTouch}>
            <Then>
              <RowCollectionMobile
                collection={collection}
                type={type}
                moreLink={link}
                showMoreBtn={showMoreBtn}
              />
            </Then>
            <Else>
              <RowCollectionsDesktop
                collection={collection}
                wrapperContainerRef={wrapperContainerRef}
                isDragging={isDragging}
                handleDrag={setIsDragging}
                type={type}
                moreLink={link}
                showMoreBtn={showMoreBtn}
              />
            </Else>
          </If>
        )}

        {!collection?.length && !isFetching && <ComingSoon title={title} />}
      </Box>
    </Box>
  )
}

export default ScrollRow
