import { useMediaQuery } from '@mui/material'
import { NotificationContext } from 'Components/Notification/context'
import { HALO_INFINITE_GAME_ID } from 'constants'
import { useConnected3rdAccounts } from 'hooks/use3rdAccounts'
import { useCreateContentByWaypointMutation } from 'Queries/Common'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CONTENT_TYPES } from 'Scenes/constants'
import CustomModal from 'Theme/Modal'
import { BREAKPOINT_MIN_SM } from 'Theme/stylesUtil'

import { ModalBody } from './ModalBody/ModalBody'
import { ModalFooter } from './ModalFooter/ModalFooter'
import { PublishModalHeader } from './PublishModalHeader'
import { PUBLISH_ITEMS, testWaypointLink } from './utils'

const PublishContentModal = ({ dialogOpened, handleClose }) => {
  const { notification } = useContext(NotificationContext)
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)
  const [waypointLink, setWaypointLink] = useState('')
  const [contentTab, setContentTab] = useState(isBiggerThanSMScreen ? PUBLISH_ITEMS.MAPS : PUBLISH_ITEMS.URL)
  const { isConnectedAccountsLoading, isXboxLiveConnected } = useConnected3rdAccounts()
  const { mutate: createContent, error, isLoading, isSuccess } = useCreateContentByWaypointMutation()

  const isIvalidLink = !testWaypointLink(waypointLink)
  const disableCreation = isIvalidLink || !waypointLink || isLoading
  const isWrongWaypointId = error?.response?.data?.name === 'No halowaypoint data'
  const showFooter = (contentTab === PUBLISH_ITEMS.URL || !isXboxLiveConnected) && !isConnectedAccountsLoading
  const footerPadding = `${isXboxLiveConnected ? 24 : 32}px`

  const handleSuccess = useCallback(() => {
    setWaypointLink('')
    handleClose()
  }, [handleClose])

  const handleChange = (event) => {
    setWaypointLink(event.target.value)
  }

  const handleCreateWithURL = (link) => {
    if (testWaypointLink(link)) {
      const splitedLink = link.split('/')
      const type = splitedLink[splitedLink.length - 2]

      createContent({ payload: { url: link }, type })
    } else {
      notification.error('Invalid halowaypoint url')
    }
  }

  const handleCreate = (type) => {
    createContent({ payload: { game_id: HALO_INFINITE_GAME_ID }, type: type ?? CONTENT_TYPES.MAPS })
  }

  const handleSelectTab = (_, value) => {
    setContentTab(value)
  }

  useEffect(() => {
    error?.message && notification.error(error.message)
  }, [error, notification])

  useEffect(() => {
    isSuccess && handleSuccess()
  }, [handleSuccess, isSuccess])

  return (
    <CustomModal
      isLoading={isLoading}
      open={dialogOpened}
      header={
        <PublishModalHeader
          contentTab={contentTab}
          handleSelectTab={handleSelectTab}
          isConectedToXbox={isBiggerThanSMScreen && isXboxLiveConnected}
        />
      }
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '1000px',
        },
        '& .MuiDialogContent-root': {
          padding: 0,
        },
      }}
      onClose={handleClose}
      footer={
        showFooter || !isBiggerThanSMScreen ? (
          <ModalFooter
            disableCreation={disableCreation}
            handleClose={handleClose}
            handleCreate={handleCreate}
            isLoading={isLoading}
            isWrongWaypointId={isWrongWaypointId}
            handleCreateWithUrl={handleCreateWithURL}
            waypointLink={waypointLink}
            isMobileView={!isBiggerThanSMScreen}
            sx={{
              paddingLeft: footerPadding,
              paddingRight: footerPadding,
            }}
          />
        ) : (
          <></>
        )
      }
    >
      <ModalBody
        handleChange={handleChange}
        isConectedToXbox={isXboxLiveConnected}
        isIvalidLink={isIvalidLink}
        waypointLink={waypointLink}
        contentTab={contentTab}
        isAccountsLoading={isConnectedAccountsLoading}
        handleCreateWithURL={handleCreateWithURL}
        handleCreate={handleCreate}
      />
    </CustomModal>
  )
}

export default React.memo(PublishContentModal)
