import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SocialLogin from 'Components/Auth/SocialLogin'
import { NotificationContext } from 'Components/Notification/context'
import historyInstance from 'history-instance'
import { useCheckRegister } from 'Queries/Auth'
import { parse } from 'query-string'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { When } from 'react-if'
import { useHistory, useLocation } from 'react-router-dom'
import Button from 'Theme/Button'
import { BREAKPOINT_MIN_SM } from 'Theme/stylesUtil'
import TextField from 'Theme/TextField'
import * as yup from 'yup'

import BackgroundImage from '../../../Assets/img/LoginModalImage.jpg'
import { ROUTE_CREATE_NEW_ACCOUNT_PATH } from '../../../Scenes/constants'
import {
  navigateToCreateNewAccountPage,
  navigateToEmptyHash,
  navigateToResetPasswordModal,
  navigateToRootPage,
} from '../../../Scenes/navigators'
import FAIcon from '../../../Theme/FAIcon'
import { FA_ICONS } from '../../../Theme/font-awesome-icon-constants'
import ButtonSignIn from '../../_common/Buttons/ButtonSignIn'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'default',
    height: '100%',
    borderRadius: theme.shape.borderRadius * 2,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
  loginIcon: {
    color: theme.palette.text.primary,
    overflow: 'visible',
    width: theme.spacing(2),
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  textField: {
    '& input::placeholder': {
      color: theme.palette.text.tertiary,
      ...theme.typography.body2,
    },
  },
  adornmentIcon: {
    overflow: 'visible',
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  control: {
    color: theme.palette.text.primary,
  },
  signUpControl: {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    bottom: theme.spacing(3),
  },
}))

const LoginForm = ({ mutate }) => {
  const classes = useStyles()
  const location = useLocation()
  const { email, code } = parse(location.search)
  const history = useHistory()
  const { mutate: check } = useCheckRegister()
  const { notification } = useContext(NotificationContext)
  const isBiggerThanSMScreen = useMediaQuery(BREAKPOINT_MIN_SM)

  useEffect(() => {
    if (!!email && !!code) {
      check(
        { email, code },
        {
          onSuccess: () => {
            notification.success('Email successfully validated')
          },
        },
      )
    }
  }, [email, code, check, notification])

  const schema = useMemo(
    () =>
      yup.object().shape({
        login: yup.string('As a string please').required('Please enter your username'),
        password: yup.string('As a string please').min(3, 'Mor symbols pls').required('Please enter your password'),
      }),
    [],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: yupResolver(schema),
  })

  const setLoginFormDetails = (e) => {
    e.preventDefault()
    let password = e.password
    let login = e.login

    if (!login || !password) {
      login = document.getElementById('loginInput')?.value
      password = document.getElementById('passwordInput')?.value
    }

    handleSubmit(onSubmit({ ...e, login, password }))
  }

  const onSubmit = useCallback(
    (data) => {
      mutate(
        { login: data.login, password: data.password },
        {
          onSuccess: ({ data }) => {
            notification.success(`You have signed as ${data.login}`)
            if (historyInstance.location.pathname === ROUTE_CREATE_NEW_ACCOUNT_PATH) {
              navigateToEmptyHash()
              navigateToRootPage()
            } else {
              history.goBack()
            }
          },
          onError: ({ response }) => {
            if (response?.data?.code === 1006) {
              notification.error(
                "If it's your first login after site update, please use 'restore password' form to login with email and password",
              )
            } else {
              notification.error(response?.data?.description)
            }
          },
        },
      )
    },
    [mutate, notification, history],
  )

  const handleCreateNewAccountClick = () => {
    navigateToEmptyHash()
    navigateToCreateNewAccountPage()
  }

  return (
    <>
      <When condition={isBiggerThanSMScreen}>
        <Box
          position="relative"
          width={400}
          height={400}
        >
          <Box
            position="relative"
            className={classes.background}
          />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            className={classes.signUpControl}
            onClick={handleCreateNewAccountClick}
          >
            <Typography
              variant="inherit"
              className={classes.control}
              sx={{ mr: 2 }}
            >
              Create New Account
            </Typography>
            <FAIcon iconClassName={FA_ICONS.USER_PLUS} />
          </Button>
        </Box>
      </When>

      <Box sx={{ width: isBiggerThanSMScreen ? '50%' : '100%' }}>
        <form
          className={classes.form}
          onSubmit={setLoginFormDetails}
        >
          <Typography
            variant="h4"
            color="text.primary"
          >
            Sign in
          </Typography>

          <SocialLogin />

          <Box className={classes.inputGroup}>
            <Controller
              name="login"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  id="loginInput"
                  error={!!errors.login}
                  placeholder="Email or Username"
                  variant="outlined"
                  helperText={
                    errors.login?.message ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: 'none' }}
                      >
                        {errors.login.message}
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    ...field,
                  }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  error={!!errors.password}
                  placeholder="Password"
                  id="passwordInput"
                  variant="outlined"
                  type="password"
                  helperText={
                    errors.password?.message ? (
                      <Typography
                        variant="body2bold"
                        color="error"
                        style={{ textTransform: 'none' }}
                      >
                        {errors.password.message}
                      </Typography>
                    ) : null
                  }
                  InputProps={{
                    className: classes.textField,
                    ...field,
                  }}
                />
              )}
            />
            <When condition={!isBiggerThanSMScreen}>
              <Typography variant="body2">
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => navigateToResetPasswordModal()}
                  style={{ padding: '8px 14px' }}
                >
                  Forgot password?
                </Button>
              </Typography>
            </When>
          </Box>

          <Box
            sx={
              isBiggerThanSMScreen
                ? {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    marginTop: '40px',
                  }
                : {
                    width: '100%',
                    height: '56px',
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    zIndex: 'drawer',
                    backgroundColor: 'background.paper',
                    padding: '0 16px 0 24px',
                  }
            }
          >
            <When condition={isBiggerThanSMScreen}>
              <Typography variant="body2">
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => navigateToResetPasswordModal()}
                  style={{ padding: '8px 14px' }}
                >
                  Forgot password?
                </Button>
              </Typography>
            </When>
            <When condition={!isBiggerThanSMScreen}>
              <Button
                color="secondary"
                onClick={handleCreateNewAccountClick}
              >
                <Typography
                  variant="inherit"
                  sx={{ mr: 2 }}
                >
                  Create New Account
                </Typography>
                <FAIcon iconClassName={FA_ICONS.USER_PLUS} />
              </Button>
            </When>

            <ButtonSignIn type="submit" />
          </Box>
        </form>
      </Box>
    </>
  )
}

export default LoginForm
