import CardContent from '@mui/material/CardContent'
import withStyles from '@mui/styles/withStyles'

const StyledCardContent = withStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2, 3, 2, 3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}))(CardContent)

export default StyledCardContent
